import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { addManyElementsData, createOrUpdateElementData, returnSingleFormElementData, updateElementLoadingStatus, useReturnSingleFormElementData, updateElementDisabledStatus } from '../../../FormElements/formsDataSlice'
import { addNewForm } from '../../../FormElements/formsSlice'
import GenerateForm from '../../../FormElements/GenerateForm'
import AppLogo from "../../../../assets/svg/miraj-logo.svg"
import Api from '../../../Api/Api'
import FormValidator from '../../../FormElements/FormValidator'
import { useParams } from 'react-router-dom'
import PasswordSetApp from "./PasswordSetApp"
import { resetReCaptcha } from '../../../FormElements/Elements/Recaptcha'
import { verifyStrongPassword } from '../../../FormElements/Elements/FormElementTextField'
import { FormAlertTypes } from '../../../FormElements/Types'

interface Props {
    resetPasswordForm?: boolean
}

export default function SetPasswordForm(props: Props) {

    const dispatch = useDispatch()
    const params = useParams()

    const formDetails = {
        name: "set_password_from",
        elemPrefix: "set_password_from_"
    }

    const elemPrefix = formDetails.elemPrefix

    /**
     * Update button loading
     */
    const updateButtonLoadingStatus = (loading: boolean) => {
        dispatch(
            updateElementLoadingStatus({
                name: elemPrefix + "submit",
                loading
            })
        )
    }

    /**
     * Update button loading
     */
    const updateButtonDisabledStatus = (status: boolean) => {
        dispatch(
            updateElementDisabledStatus({
                name: elemPrefix + "submit",
                disabled: status
            })
        )
    }

    /**
     * Get single form element data value
     */
    const getSingleFormElementValue = (name: string) => returnSingleFormElementData(elemPrefix + name).value

    /**
     * Update alert
     */
    const updateAlert = (value: string, severity: FormAlertTypes, hidden: boolean) => dispatch(createOrUpdateElementData({
        name: elemPrefix + "alert",
        value: value,
        severity,
        hidden
    }))
    
    /**
     * Submit form
     */
    const HandleSubmitForm = () => {
        updateButtonLoadingStatus(true)
        // setTimeout(() => updateButtonLoadingStatus(false), 1000)

        const email: string = getSingleFormElementValue("email")
        const otp: string = getSingleFormElementValue("otp")
        const password: string = getSingleFormElementValue("password")
        const token: string = params.token ? params.token : ""

        const recaptcha = getSingleFormElementValue("recaptcha")

        /**
         * Form Validator
         */
        //        const formValidator = new FormValidator([elemPrefix + "email", elemPrefix + "password", elemPrefix + "otp"])
        let requiredFields: Array<string> = [elemPrefix + "email", elemPrefix + "password"]

        if (props.resetPasswordForm){
            requiredFields.push(elemPrefix + "otp")
        }

        const formValidator = new FormValidator(requiredFields)

        updateAlert("", "info", true)

        if (verifyStrongPassword(password).valid){
            if (formValidator.isValid().valid){

                const passwordSetApp = new PasswordSetApp()

                /**
                 * Send request to API
                 */
                passwordSetApp.sendRequest({
                    method: "POST",
                    data: {
                        email,
                        password,
                        password_set_unique_id: token,
                        otp,
                        recaptcha
                    },
                    url: props.resetPasswordForm ? passwordSetApp.appResetPasswordPath : passwordSetApp.appBaseEndPointPath,
                    withCredentials: false
                })
                    .then(response => {
                        
                        const { status, statusText, data } = response

                        switch(status){

                            case 200:
                                dispatch(
                                    createOrUpdateElementData({
                                        name: elemPrefix + "alert",
                                        value: data.message! ? data.message : statusText,
                                        severity: "success"
                                    })
                                )
                                setTimeout(() => window.location.replace("/sign-in"), 1500)
                                break

                        }

                        updateButtonLoadingStatus(false)
                        updateButtonDisabledStatus(true)

                    })
                    .catch(error => {
                        updateButtonLoadingStatus(false)
                        
                        const response = error.response
                        Api.log(response)

                        resetReCaptcha(dispatch, elemPrefix + "recaptcha")
                        
                        switch(response.status){

                            default:
                                dispatch(
                                    createOrUpdateElementData({
                                        name: elemPrefix + "alert",
                                        value: response.data! ? response.data.message! ? response.data.message : "" : response.statusText,
                                        severity: "error"
                                    })
                                )
                        }
                    
                    })
            }else{
                updateButtonLoadingStatus(false)
                formValidator.showRequiredFields(dispatch)
            }
        }else{
            updateButtonLoadingStatus(false)
            updateAlert("Password is not strong enough!", "error", false)
        }


    }

    /**
     * Use Effect
     */
    useEffect(() => {

        /**
         * Add new form
         */
        dispatch(
            addNewForm({
                name: formDetails.name,
                elements: [
                    {
                        type: "TextField",
                        fieldType: "text",
                        xs: 12,
                        id: elemPrefix + "email",
                        label: "Email",
                        fullWidth: true
                    },
                    {
                        type: "TextField",
                        fieldType: "password",
                        xs: 12,
                        id: elemPrefix + "password",
                        label: "Password",
                        fullWidth: true,
                        enableStrongPassword: true
                    },
                    {
                        type: "TwoFactorAuth",
                        fieldType: "number",
                        xs: 12,
                        id: elemPrefix + "otp",
                        label: "Enter 6-digit code from Google Authenticator to change the password",
                        submitFormOnPressingEnter: true,
                        fullWidth: true,
                    },
                    {
                        type: "Alert",
                        xs: 12,
                        label: "",
                        id: elemPrefix + "alert"
                    },
                    {
                        type: "ReCaptcha",
                        xs: 12,
                        label: "",
                        id: elemPrefix + "recaptcha"
                    },
                    {
                        type: "Button",
                        xs: 12,
                        id: elemPrefix + "submit",
                        label: props.resetPasswordForm ? "Reset Password" : "Save password",
                        fullWidth: true,
                        onClick: () => {},
                        isSubmit: true
                    },
                    {
                        type: "Link",
                        xs: 6,
                        label: "Request a new link",
                        link: props.resetPasswordForm ? "/reset-password" :  "/set-password",
                        id: elemPrefix + "request_new_link"
                    },
                    {
                        type: "Link",
                        xs: 6,
                        label: "Sign in to account",
                        link: "/sign-in",
                        id: elemPrefix + "sign_in_link"
                    },
                ]
            })
        )

        /**
         * Add new form elements data
         */
        dispatch(
            addManyElementsData([
                {
                    name: elemPrefix + "email",
                    value: ""
                },
                {
                    name: elemPrefix + "password",
                    value: ""
                },
                {
                    name: elemPrefix + "otp",
                    value: "",
                    hidden: props.resetPasswordForm ? false : true
                },
                {
                    name: elemPrefix + "alert",
                    value: "Test",
                    severity: "info",
                    hidden: true
                },
                {
                    name: elemPrefix + "submit",
                    value: ""
                }
            ])
        )
    }, [])

    return(
        <div className="login-form-container reset-password-form">
            <div className="content-of-login-form-container">

                <div className="app-logo-of-login-form-container">
                    <div className="content-of-app-logo-of-login-form-container">
                        <a href="/">
                            <img src={AppLogo} />
                        </a>
                    </div>
                </div>

                <div className="title-box-of-login-form-container">
                    <div className="content-of-title-box-of-login-form-container">
                        <h3>{props.resetPasswordForm ? "Reset Password" : "Password Set"}</h3>
                    </div>
                </div>

                <div className="actual-form-of-login-form-container">
                    <GenerateForm name={formDetails.name} handleSubmitForm={HandleSubmitForm} />
                </div>

            </div>
        </div>
    )
}