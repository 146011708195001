import { CSSProperties } from 'react'

export interface IBlockEditorStyles {
    italic?: boolean,
    underline?: boolean,
    strike?: boolean,
    textColor?: string,
    backgroundColor?: string
}

export interface IBlockContentItem {
    styles: IBlockEditorStyles,
    text: string,
    type: string
}


export const returnStyles = (styles: IBlockEditorStyles, props?: any) => {

    let elemStyles: CSSProperties = {}

    if (styles.italic){
        elemStyles["fontStyle"] = "italic"
    }

    if (styles.underline){
        elemStyles["textDecoration"] = "underline"
    }

    if (styles.strike){
        elemStyles["textDecoration"] = "line-thorugh"
    }

    if (styles.textColor){
        elemStyles["color"] = styles.textColor
    }

    if (styles.backgroundColor){
        elemStyles["backgroundColor"] = styles.backgroundColor
    }

    if (props){
        if (props.textAlignment){
            elemStyles["textAlign"] = props.textAlignment
        }
    }

    return elemStyles

}