import { Block } from '@blocknote/core'
import React, { useEffect } from 'react'
import HeadingBlock from './Blocks/HeadingBlock'
import ParagraphBlock from './Blocks/ParagraphBlock'
import NumberedListItemBlock from './Blocks/NumberedListItemBlock'
import TableBlock from './Blocks/TableBlock'
import ImageBlock from './Blocks/ImageBlock'
import FileBlock from './Blocks/FileBlock'
import VideoBlock from './Blocks/VideoBlock'

interface Props {
    block: Block
}

export default function RenderBlockAsHtml(props: Props) {

    const { block } = props

    const { id, type, children } = block

    useEffect(() => {
        // console.log("block", block)
    }, [block])

    /**
     * Render block
     */
    const renderBlock = (blockType: string, blockData: Block) => {
        switch(blockType){

            case "heading":
                return <HeadingBlock {...blockData} />

            case "paragraph":
                return <ParagraphBlock {...blockData} />

            case "numberedListItem":
                return <NumberedListItemBlock {...blockData} />

            case "bulletListItem":
                return <NumberedListItemBlock {...blockData} />

            case "checkListItem":
                return <NumberedListItemBlock {...blockData} />

            case "table":
                return <TableBlock {...blockData} />

            case "image":
                return <ImageBlock {...blockData} />

            case "file":
                return <FileBlock {...blockData} />

            case "video":
                return <VideoBlock {...blockData} />

            default:
                return <></>
        }
    }


    if (children && children.length > 0){
        return(
            <>
                {renderBlock(type, block)}
                <ul className="children-items-block-edtor">
                    {children.map((child, index) => <li key={index}>{renderBlock(child.type, child)}</li>)}
                </ul>
            </>
        )
    }else{
        return renderBlock(type, block)
    }

}