import React, { Dispatch, useEffect, useState } from 'react'
import PostsApp, { SinglePostInterface } from '../../posts/PostsApp'
import { ISopDetailsSingleItem, addManyPosts, createOrUpdatePost, deletePost, resetPosts, updateItemsOrderStatus, updatePostDetailsModal, updatePostLoadingStatus, updatePostsFormModalStatus, updatePostsPagination, updateSopActiveSection, updateSopActiveSectionDetails, updateSopModal, updateSopModalOpenStatus, useReturnListOfPOsts, useReturnPostPagination, useReturnPostsLoadingStatus, useReturnSopActiveSection, useReturnSopActiveSectionDetails, useReturnUpdateItemsOrderStatus } from './sopSlice'
import CategoriesApp, { SingleCategoryInterface } from '../../categories/CategoriesApp'
import { createOrUpdateElementData } from '../../../FormElements/formsDataSlice'
import ItemsDisplayer from './ItemsDisplayer/ItemsDisplayer'
import { ItemsDisplayerInterface } from './ItemsDisplayer/interfaces'
import { ItemDisplayerItemsType, SingleItemDataDisplayerType } from './ItemsDisplayer/types'
import { AnyAction } from '@reduxjs/toolkit'


export default function SopPostsList(){

    const categoriesApp = new CategoriesApp()
    const postsApp = new PostsApp()

    const elemPrefix = "posts_form_modal_elem_"

    const handleEditItem = (item: SingleItemDataDisplayerType, dispatch: Dispatch<AnyAction>) => {
        
        const updateElementData = (name: string, value: any) => dispatch(createOrUpdateElementData({ name: elemPrefix + name, value }))

        const post = (item as SinglePostInterface)
        const { id, title, body, thumbnail, section_slug, category_slug, section_id, category_id, slug } = post

        updateElementData("id", id)
        updateElementData("post_id", id)
        updateElementData("title", title)
        updateElementData("body", body)
        updateElementData("thumbnail", thumbnail)
        updateElementData("update", true)
        updateElementData("section_slug", section_slug)
        updateElementData("category_slug", category_slug)
        updateElementData("section_id", section_id)
        updateElementData("category_id", category_id)
        updateElementData("slug", slug)

        setTimeout(() => dispatch(updatePostsFormModalStatus(true)), 500)

    }

    const config: ItemsDisplayerInterface = {
        sopSectionName: "sop-posts",
        elemPrefix: elemPrefix,
        items: useReturnListOfPOsts(),
        pagination: useReturnPostPagination(),
        loadingItemsStatus: useReturnPostsLoadingStatus(),
        noItemsMessage: {
            title: "Nothing found...",
            message: "No posts have been found :("
        },
        loadAllItemsMessage: "Load all posts",
        addNewItemText: "Adda a new post",
        optionsMenu: [
            {
                type: "select"
            },
            {
                type: "load-all"
            },
            {
                type: "update-order"
            }
        ],
        selectedItemsMenuOptions: [
            {
                type: "move"
            },
            {
                type: "delete"
            }
        ],
        listOfItems: categoriesApp.listOfPosts,
        updateItemsLoadingStatus: updatePostLoadingStatus,
        resetItems: (payload) => resetPosts([]),
        handleAddNew: (dispatch, activeSopSectionDetails) => {
            dispatch(createOrUpdateElementData({
                name: elemPrefix + "section_id",
                value: (activeSopSectionDetails as SingleCategoryInterface).section_id
            }))
            dispatch(createOrUpdateElementData({
                name: elemPrefix + "category_id",
                value: (activeSopSectionDetails as SingleCategoryInterface).id
            }))
            dispatch(updatePostsFormModalStatus(true))
        },
        updateItemsOrder: (items: ItemDisplayerItemsType) => {
            return postsApp.updatePostsOrder((items as Array<SinglePostInterface>))
        },
        addManyItems: (payload) => addManyPosts((payload as Array<SinglePostInterface>)),
        createOrUpdateItem: (payload) => createOrUpdatePost((payload as SinglePostInterface)),
        updateItemsPagination: updatePostsPagination,
        handleEditItem: handleEditItem,
        deleteInstance: postsApp.deleteInstance,
        deleteItem: deletePost,
        itemButtons: [
            {
                type: "view",
            },
            {
                type: "edit",
            },
            {
                type: "delete",
            }
        ],
        deleteMany: (records) => {
            return postsApp.deleteManyPosts((records as Array<any>))
        },
        moveManyItems: (records, section_id, category_id) =>{
            return postsApp.moveManyPosts((records as any), section_id, category_id)
        },
        onTitleClick(item, dispatch) {
            const post = (item as SinglePostInterface)
            window.open(`/posts/${post.section_slug}/${post.category_slug}/${post.slug}`, '_blank')?.focus()
        },
    }

    return <ItemsDisplayer {...config} />
}