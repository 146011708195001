import { FormControl, FormHelperText, IconButton, TextField } from '@mui/material'
import React, { useState } from 'react'
import { FormElementData, FormTextFielTypes, FormTextFieldElement } from '../Interfaces'
import LoadingFieldElement from './LoadingFieldElement'
import InputAdornment from '@mui/material/InputAdornment';
import GenerateIcon from '../GenerateIcon';

import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';

import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'

interface Props extends FormTextFieldElement{
    data: FormElementData,
    handleOnChange: (name: string, value: any) => void,
    handleSubmitForm?: () => void
}

interface IStrongPasswordResponse {
    lowercase: boolean,
    uppercase: boolean,
    numbers: boolean,
    special_character: boolean,
    length: boolean,
    valid: boolean
}

export const verifyStrongPassword = (password: string) => {

    const chars = Array.from(password)

    let response: IStrongPasswordResponse = {
        lowercase: false,
        uppercase: false,
        numbers: false,
        special_character: false,
        length: false,
        valid: false
    }

    if (password.length < 8){
        response.length = false
    }else{
        response.length = true
    }

    if (chars.some(char => /[A-Z]/.test(char))){
        response.uppercase = true
    }else{
        response.uppercase = false
    }

    if (chars.some(char => /[a-z]/.test(char))){
        response.lowercase = true
    }else{
        response.lowercase = false
    }

    if (chars.some(char => !isNaN((char as any)) && char !== ' ')){
        response.numbers = true
    }else{
        response.numbers = false
    }

    if (chars.some(char => /[!@#$%^&*(),.?":{}|<>-]/.test(char))){
        response.special_character = true
    }else{
        response.special_character = false
    }

    if (response.lowercase && response.uppercase && response.numbers && response.special_character && response.length){
        response.valid = true
    }else{
        response.valid = false
    }

    return response

}

export default function FormElementTextField(props: Props) {
    const data = props.data

    const [strongPasswordResponse, setStrongPasswordResponse] = useState<IStrongPasswordResponse>({
        lowercase: false,
        uppercase: false,
        numbers: false,
        special_character: false,
        length: false,
        valid: false
    })

    const [fieldType, setFieldType] = useState<FormTextFielTypes>(props.fieldType ? props.fieldType : "text")

    const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const target = event.target

        if (fieldType == "password"){
            setStrongPasswordResponse(verifyStrongPassword(target.value))
            console.log("response: ", strongPasswordResponse)
        }

        props.handleOnChange(target.name, target.value)
    }

    const handleOnKeyUp = (event: React.KeyboardEvent<HTMLDivElement>) => {
        if (props.submitFormOnPressingEnter){
            if (props.handleSubmitForm){
                if (event.key === "Enter"){
                    props.handleSubmitForm()
                }
            }
        }
    }

    let inputProps = {}

    if (props.fieldType === "password"){
        inputProps = {
            endAdornment: <InputAdornment position="end"><IconButton onMouseUp={() => setFieldType("password")} onMouseDown={() => setFieldType("text")}><GenerateIcon icon={fieldType === "password" ? "view" : "hide"} /></IconButton></InputAdornment>
        }
    }

    if (props.fieldType === "phone"){
        return(
            <FormControl fullWidth={props.fullWidth}>
                {data.loading ? <LoadingFieldElement label={props.label} /> : <PhoneInput defaultCountry="SA" placeholder={props.label} value={data.value} onChange={(value) => props.handleOnChange(props.id, value)} />}
                {data.displayHelperText && (
                    <FormHelperText error={data.helperTextType === "error" ? true : false}>
                        {data.helperText}
                    </FormHelperText>
                )}
            </FormControl>
        )
    }

    /**
     * Verify strong password
     */
    if (props.fieldType === "password" && props.enableStrongPassword){
        
    }

    /**
     * Render password verify
     */
    const renderPasswordVerify = () => {

        interface IPasswordVerifyItem {
            title: string,
            checked: boolean
        }

        if (props.fieldType !== "password"){
            return <></>
        }

        if (!props.enableStrongPassword){
            return <></>
        }

        let items: Array<IPasswordVerifyItem> = [
            {
                title: "Includes lowercase",
                checked: strongPasswordResponse.lowercase
            },
            {
                title: "Includes uppercase",
                checked: strongPasswordResponse.uppercase
            },
            {
                title: "Includes numbers",
                checked: strongPasswordResponse.numbers
            },
            {
                title: "Includes symbols",
                checked: strongPasswordResponse.special_character
            },
            {
                title: "Minimum length is 8 characters",
                checked: strongPasswordResponse.length
            }
        ]

        return(
            <div className="verify-strong-password-container">
                <div className="content-of-verify-strong-password-container">
                    {items.map((item, index) => {

                        return(
                            <div key={index} className={item.checked ? "item-of-verify-strong-password-container checked" : "item-of-verify-strong-password-container"}>
                                <div className="content-of-item-of-verify-strong-password-container">

                                    <div className="icon-of-item-of-verify-strong-password-container">
                                        <div className="content-of-icon-of-item-of-verify-strong-password-container">
                                            {item.checked ? <CheckIcon /> : <CloseIcon />}
                                        </div>
                                    </div>

                                    <div className="text-of-item-of-verify-strong-password-container">
                                        <div className="content-of-text-of-item-of-verify-strong-password-container">
                                            <p>{item.title}</p>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        )
                    })}
                </div>  
            </div>
        )
    }

    return(
        <FormControl fullWidth={props.fullWidth}>
            {data.loading ? <LoadingFieldElement label={props.label} /> : <TextField onKeyUp={handleOnKeyUp} error={data.helperTextType === "error" ? true : false} name={props.id} id={props.id} type={fieldType} label={props.label} variant="outlined" fullWidth={props.fullWidth} value={data.value} multiline={props.multiline} maxRows={props.maxRows} minRows={props.minRows} onChange={handleOnChange} disabled={data.disabled} InputProps={inputProps} />}
            {data.displayHelperText && (
                <FormHelperText error={data.helperTextType === "error" ? true : false}>
                    {data.helperText}
                </FormHelperText>
            )}
            {data.loading ? "" : renderPasswordVerify()}
        </FormControl>
    )
}