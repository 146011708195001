import React, { useEffect, useState } from 'react'
import { SidebarCategoryInterface, SidebarPostInterface } from './PostSidebar'
import CategoriesApp from '../../apps/categories/CategoriesApp'
import { SinglePostInterface } from '../../apps/posts/PostsApp'
import { Skeleton } from '@mui/material'

import { Link } from "react-router-dom";
import { addPostsToCategory, returnSingleLoadedCategoryDetails, useReturnCategoriesList } from '../../apps/sop/components/postsSlice'
import { useDispatch } from 'react-redux'

interface Props extends SidebarCategoryInterface{
    currentPostSlug: string
}

interface SidebarPostProps extends SidebarPostInterface {
    currentPostSlug: string
}

function SingleSidebarPost(props: SidebarPostProps){

    const baseClassName: string = "single-sidebar-category-box"
    const [categorySidebarContainerClassName, setCategorySidebarContainerClassName] = useState<string>(baseClassName)

    // useEffect(() => {
    //     console.log("props.isActive", props.isActive, "id:", props.title)
    //     setCategorySidebarContainerClassName(props.isActive ? baseClassName + " active" : baseClassName)
    // }, [props.isActive])
    useEffect(() => {
        setCategorySidebarContainerClassName(props.slug === props.currentPostSlug ? baseClassName + " active" : baseClassName)
    }, [props])

    return(
        <div className={categorySidebarContainerClassName}>
            <div className="content-of-single-sidebar-category-box">
                <Link to={`/posts/${props.section_slug}/${props.category_slug}/${props.slug}`}>
                    <h4>{props.title}</h4>
                </Link>
                {/* <h4 onClick={() => window.location.replace(`/posts/${props.section_slug}/${props.category_slug}/${props.slug}`)}>{props.title}</h4> */}
            </div>
        </div>
    )
}

export default function SidebarCategory(props: Props) {

    const dispatch = useDispatch()

    const [loading, setLoading] = useState<boolean>(true)

    const [category, setCategory] = useState<SidebarCategoryInterface>(props)
    
    const categoriesApp = new CategoriesApp()

    const baseContainerClassName: string = "sidebar-single-post-category"
    const [containerClassName, setContainerClassName] = useState<string>(baseContainerClassName)

    // const categories = useReturnCategoriesList()

    // const posts = returnSingleLoadedCategoryDetails(categories, category.slug).posts
    const posts = category.posts

    const { currentPostSlug } = props

    /**
     * Load posts
     */
    const loadPosts = () => {

        setLoading(true)

        categoriesApp.listOfPosts(category.slug, 1, "", true)
            .then(response => {

                const { data } = response

                if (data && data.length > 0) {

                    let updateCategory = {
                        ...category
                    }
                    let updatedPosts: Array<SidebarPostInterface> = []
                    
                    data.map((post: SinglePostInterface, index: number) => {
                        updatedPosts.push({
                            ...post,
                            isActive: post.slug == props.currentPostSlug ? true : false
                        })
                    })

                    updateCategory = {
                        ...updateCategory,
                        posts: updatedPosts
                    }


                    setCategory(updateCategory)
                    
                    // setLoading(false)

                }

                setLoading(false)

            })

    }
    // const loadPosts = () => {

    //     setLoading(true)

    //     categoriesApp.listOfPosts(category.slug, 1, "", true)
    //         .then(response => {

    //             const { data } = response

    //             if (data && data.length > 0) {

    //                 let updatedPosts: Array<SidebarPostInterface> = []
                    
    //                 data.map((post: SinglePostInterface, index: number) => {
    //                     updatedPosts.push({
    //                         ...post,
    //                         isActive: post.slug == props.currentPostSlug ? true : false
    //                     })
    //                 })

    //                 dispatch(addPostsToCategory({ categorySlug: category.slug, posts: updatedPosts }))

    //             }

    //             setLoading(false)

    //         })

    // }

    /**
     * Update active status
     */
    const updateActiveStatus = (status: boolean) => {
        setCategory({
            ...category,
            isActive: status
        })
    }

    /**
     * Update state
     */
    // useEffect(() => {
    //     setCategory(props)
    // }, [props])

    /**
     * Load posts
     */
    // useEffect(() => {
    //     if (posts.length == 0){
    //         loadPosts()
    //     }
    // }, [props.isActive])
    useEffect(() => {
        if (posts.length == 0){
            loadPosts()
        }else{
            setLoading(false)
        }
    }, [posts])

    useEffect(() => {
        setContainerClassName(category.isActive ? baseContainerClassName + " active" : baseContainerClassName)
    }, [category.isActive])

    /**
     * 
     * Render posts
     */
    const renderPosts = () => {

        if (loading){
            return(
                <>
                    <Skeleton variant="text" width={100} style={{ marginBottom: 10 }} />
                    <Skeleton variant="text" width={100} style={{ marginBottom: 10 }} />
                    <Skeleton variant="text" width={100} style={{ marginBottom: 10 }} />
                    <Skeleton variant="text" width={100} style={{ marginBottom: 10 }} />
                </>
            )
        }

        if (posts.length > 0){
            return posts.map((post, index) => {
                // console.log("currentPostSlug", currentPostSlug, "post.slug", post.slug)
                return <SingleSidebarPost currentPostSlug={currentPostSlug} {...post} key={index} />
            })
        }else{
            return(
                <div className="single-sidebar-category-box">
                    <div className="content-of-single-sidebar-category-box">
                        <h4>Nothing yet...</h4>
                    </div>
                </div>
            )
        }

    }

    return(
        <div className={containerClassName}>
            <div className="content-of-sidebar-single-post-category">

                <div className="title-box-of-sidebar-single-post-category">
                    <div className="content-of-title-box-of-sidebar-single-post-category">
                        <h4 onClick={() => updateActiveStatus(category.isActive ? false : true)}>{category.title}</h4>
                    </div>
                </div>

                <div className="posts-box-of-sidebar-single-post-category">
                    <div className="content-of-posts-box-of-sidebar-single-post-category">
                        {renderPosts()}
                    </div>
                </div>

            </div>
        </div>
    )
}