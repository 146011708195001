import { AxiosRequestConfig } from "../../Api/Interfaces";
import { DataDisplayerSingleSearchParam } from "../../DataDisplayer/Interfaces";
import BaseApp from "../BaseApp";

export interface NextPrevPostInterface {
    title: string,
    slug: string,
    section_slug: string,
    category_slug: string
}

export interface SinglePostInterface {
    id: number,
    title: string,
    slug: string,
    thumbnail: string,
    is_active: boolean,
    date_created: string,
    created_by: number,
    category_id: number,
    section_id: number,
    section_name: string,
    category_name: string,
    section_slug: string,
    category_slug: string,
    body: string,
    next_post?: NextPrevPostInterface,
    last_updated_on: string,
    updated_by: number,
    updated_by_name: string,
    created_by_name: string,
    order_num: number,
    // prev_post?: NextPrevPostInterface
}

export const emptySinglePost: SinglePostInterface = {
    id: 0,
    title: "",
    slug: "",
    thumbnail: "",
    is_active: false,
    date_created: "",
    created_by: 0,
    category_id: 0,
    section_id: 0,
    section_name: "",
    category_name: "",
    section_slug: "",
    category_slug: "",
    body: "",
    last_updated_on: "",
    updated_by: 0,
    updated_by_name: "",
    created_by_name: "",
    order_num: 0
}

/**
 * Companies App
 */
export default class PostsApp extends BaseApp {

    /**
     * App Name
     */
    appName: string = "posts"

    /**
     * App base end point path
     */
    appBaseEndPointPath: string = "posts"

    /**
     * Required fields
     */
    requiredFields = [
        "title",
        "section_id"
    ]

    /**
     * Get single instance details by Slug
     */
    getSingleInstanceDetailsBySlug = (slug: string) => this.sendRequest({
        method: "GET",
        url: this.appBaseEndPointPath + `/post-by-slug/${slug}/details`,
        withCredentials: true
    })

    /**
     * Get single instance details by Slug
     */
    updatePostsOrder = (posts: Array<SinglePostInterface>) => this.sendRequest({
        method: "PUT",
        data: {
            posts: posts
        },
        url: this.appBaseEndPointPath + `/order/all-posts`,
        withCredentials: true
    })

    formatLink = (link: string, isSocial?: boolean, isLink?: boolean) => {
        let newLink: string = link.toLowerCase()
        let replace: Array<string> = ["http://", "https://", "www." , "?share=copy"]

        if (isSocial){
            replace.push("vimeo.com", "vimeo.com/")
        }

        replace.map(toReplace => {
            newLink = newLink.replace(toReplace, "")
        })

        if (isLink){
            return link
        }else{
            return newLink
        }
    }

    /**
     * Return vimeo embeded video
     */
    returnVimeoEmbededVideoLink = (link: string) => {
        let newLink: string = this.formatLink(link, true) 
        let splittedLink: Array<string> = newLink.split("/")
        splittedLink = splittedLink.filter(Boolean)

        newLink = `https://player.vimeo.com/video/${splittedLink[0] ? splittedLink[0] : ""}?h=${splittedLink[1] ? splittedLink[1] : ""}`

        return newLink
    }

    /**
     * Delete Many
     */
    deleteManyPosts = (records: Array<SinglePostInterface>) => this.sendRequest({
        method: "DELETE",
        data: {
            records: records
        },
        url: this.appBaseEndPointPath + `/delete-many`,
        withCredentials: true
    })

    /**
     * Move many posts
     */
    moveManyPosts = (records: Array<SinglePostInterface>, section_id: number, category_id: number) => this.sendRequest({
        method: "POST",
        data: {
            records: records,
            section_id,
            category_id
        },
        url: this.appBaseEndPointPath + `/move-many`,
        withCredentials: true
    })

}