import React, { useEffect, useState } from 'react'
import PostsApp, { SinglePostInterface, emptySinglePost } from '../../apps/posts/PostsApp';
import { usePDF, Margin, Options, Resolution } from 'react-to-pdf';
import PrintedPost from './PrintedPost';

import { useLocation, useParams } from 'react-router-dom'
import PagePreloader from '../../PagePreloader/PagePreloader';

export default function SavePostAsPdf() {

    const params = useParams()
    const postSlug = params.post_slug ? params.post_slug : ""

    const postsApp = new PostsApp()

    const [post, setPost] = useState<SinglePostInterface>(emptySinglePost)
    const [loading, setLoading] = useState<boolean>(true)

    const { toPDF, targetRef } = usePDF({
        filename: `${post.title} - Miraj SOP.pdf`,
        page: {
            margin: Margin.MEDIUM,
            format: "A4",
            orientation: "portrait"
        },
        resolution: Resolution.MEDIUM
    });

    const [isSaved, setIsSaved] = useState<boolean>(false)

    const loadPost = () => {

        setLoading(true)

        postsApp.getSingleInstanceDetailsBySlug(postSlug)
            .then(response => {

                const data: SinglePostInterface = response.data

                if (data){
                    setPost(data)
                    setLoading(false)
                }

            })

    }

    useEffect(() => {
        loadPost()
    }, [])

    useEffect(() => {
        if (!loading){
            setTimeout(() => {
                toPDF()
                setTimeout(() => setIsSaved(true), 2000)
            }, 1000)
        }
    }, [loading])

    useEffect(() => {
        if (isSaved){
            window.close()
        }
    }, [isSaved])

    return(
        <>
            <PagePreloader forceLoadingStatus={true} />
            {loading ? "" :  <div ref={targetRef}><PrintedPost {...post} /></div>}
        </>
    )
}