import { env } from "process"
import ApplicationLogoSVG from "../assets/svg/miraj-logo.svg"
import { ReactComponent as ApplicationLogo } from "../assets/svg/miraj-logo.svg"
import { ReactComponent as NoThumbnail } from "../assets/svg/no-thumb.svg"
import NoThumbnailImg from "../assets/svg/no-thumb.svg"

/**
 * Application
 */
export default class Application {

    /**
     * Is in production
     */
    isInProduction: boolean = true

    /**
     * Application link
     */
    appLink: string = "https://sop.stormtimeout.com/"
    localLink: string = "http://localhost:3000/"

    apiLocalLink: string = "http://localhost:8000/api/"
    apiProductionLink: string = "https://sop.stormtimeout.com/api/"

    apiBaseLinkPath: string = this.isInProduction ? this.apiProductionLink : this.apiLocalLink

    /**
     * Return app link
     */
    returnAppLink = (addLink?: string) => {
        let link: string = ""

        if (this.isInProduction){
            link = this.appLink
        }else{
            link = this.localLink
        }

        if (addLink){
            link += addLink
        }

        return link
    }

    /**
     * Return api link
     */
    returnApiLink = (addLink?: string) => {

        let link: string = this.apiBaseLinkPath

        if (addLink){
            link += addLink
        }

        return link

    }

    /**
     * Application logo
     */
    returnAppLogo = () => ApplicationLogoSVG

    /**
     * Application logo as svg
     */
    returnAppLogoAsSVG = () => <ApplicationLogo />

    /**
     * Retrun no thumbnail
     */
    returnNoThumbnailAsSvg = () => <NoThumbnail />

    /**
     * Return no thumbnail
     */
    returnNoThumbAsImage = () => NoThumbnailImg

}