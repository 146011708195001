import React, { useEffect, useState } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import PostsApp, { SinglePostInterface, emptySinglePost } from '../../apps/posts/PostsApp'
import GenerateMessageBox, { MessageBoxPropsInterface } from '../../FormElements/GenerateMessageBox'
import BaseStructuredPage from '../components/BaseStructuredPage'
import RenderPostContent from './RenderPostContent'
import SectionDetailsBox from '../../apps/sections/SectionDetailsBox'
import { BreadcrumbInterface } from '../components/Breadcrumbs'
import PostSidebar from './PostSidebar'
import { IconButton, Skeleton, Tooltip } from '@mui/material'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import GenerateIcon from '../../FormElements/GenerateIcon'
import ArrowOutwardIcon from '@mui/icons-material/ArrowOutward';
import updatePageTitle from '../../Router/components/updatePageTitle'
import Application from '../../Application'

import ScrollToTop from '../components/ScrollToTop'
import DateAndTime from '../../DateAndTime/DateAndTime'

import { Link } from "react-router-dom";
import { returnSingleLoadedCategoryDetails, returnSingleLoadedPostDetails, useReturnCategoriesList } from '../../apps/sop/components/postsSlice'

export default function SinglePostPage() {

    const dateAndTime = new DateAndTime()

    const application = new Application()

    const postsApp = new PostsApp()

    const params = useParams()
    const postSlug = params.post_slug ? params.post_slug : ""

    const [loading, setLoading] = useState<boolean>(true)

    const [post, setPost] = useState<SinglePostInterface>(emptySinglePost)

    const [reloadPost, setReloadPost] = useState<boolean>(false)

    const categories = useReturnCategoriesList()
    const currentCategory = returnSingleLoadedCategoryDetails(categories, post.category_slug)

    interface Message extends MessageBoxPropsInterface {
        shown?: boolean
    }
    const [message, setMessage] = useState<Message>({
        shown: false,
        title: "",
        message: "",
        type: "info"
    })

    const [copyButtonTitle, setCopyButtonTitle] = useState<string>("Copy link")

    const resetMessage = () => setMessage({ shown: false, title: "", message: "", type: "info" })

    /**
     * Load post details
     */
    const loadPostDetails = () => {
        setLoading(true)
        resetMessage()

        console.log("load from database")
        postsApp.getSingleInstanceDetailsBySlug(postSlug)
            .then(response => {

                const { data } = response

                const post: SinglePostInterface = data
                setPost(post)

                setLoading(false)
                resetMessage()

            })
            .catch(error => {
                const { response } = error
                setMessage({
                    shown: true,
                    title: "Error",
                    message: response.data! ? response.data.message! ? response.data.message : "" : response.statusText,
                    type: "error"
                })
            })
    }

    /**
     * load post details 
     */
    useEffect(() => {
        loadPostDetails()
    }, [postSlug])

    useEffect(() => {
        setShowMobileContent(false)
    }, [postSlug])
    // useEffect(() => {
    //     if (reloadPost){
    //         loadPostDetails()
    //     }
    // }, [reloadPost])

    // useEffect(() => {
    //     /**
    //      * Get post details from state
    //      */
    //     const findPost = returnSingleLoadedPostDetails(currentCategory.posts, postSlug)

    //     if (findPost){
    //         setPost(findPost)
    //         setReloadPost(false)
    //     }else{
    //         setReloadPost(true)
    //     }

    // }, [postSlug])

        /**
     * Breadcrumbs
     */
        const breadcrumbs: Array<BreadcrumbInterface> = [
            {
                title: "Miraj SOP",
                link: "/",
                index: 0,
                total: 0
            },
            {
                title: post.section_name,
                link: `/posts/${post.section_slug}`,
                index: 0,
                total: 0
            },
            {
                title: post.category_name,
                link: `/posts/${post.section_slug}/${post.category_slug}`,
                index: 0,
                total: 0
            },
            {
                title: post.title,
                index: 0,
                total: 0
            },
        ]

    /**
     * Loading sidebar
     */
    const renderLoadingSidebar = () => {
        return(
            <div>
                <Skeleton variant="text" width={150} />
                <div style={{height: 20}}></div>
                <Skeleton variant="text" width={100} />
                    <Skeleton variant="text" width={100} style={{marginLeft: 15}} />
                    <Skeleton variant="text" width={100} style={{marginLeft: 15}} />
                    <Skeleton variant="text" width={100} style={{marginLeft: 15}} />
                    <Skeleton variant="text" width={100} style={{marginLeft: 15}} />

                <Skeleton variant="text" width={100} />
                <Skeleton variant="text" width={100} />
                <Skeleton variant="text" width={100} />
                <Skeleton variant="text" width={100} />
            </div>
        )
    }

    /**
     * Loading content
     */
    const renderLoadingContent = () => {
        return(
            <div>
                <Skeleton variant="rectangular" width="100%" height={500} />
                <div style={{ height: 15  }}></div>
                <Skeleton variant="text" width="100%" />
                <Skeleton variant="text" width="90%" />
                <Skeleton variant="text" width="80%" />
                <Skeleton variant="text" width="100%" />
                <Skeleton variant="text" width="70%" />
                <Skeleton variant="text" width="60%" />
            </div>
        )
    }

    const renderNextPost = () => {
        if (post.next_post?.title){
            return(
                <div className="next-post-button-container" onClick={() => window.scrollTo({top: 0, behavior: 'smooth'})}>
                    <Link to={`/posts/${post.next_post.section_slug}/${post.next_post.category_slug}/${post.next_post.slug}`} >
                     {/* <a href={`/posts/${post.next_post.section_slug}/${post.next_post.category_slug}/${post.next_post.slug}`}> */}
                        <div className="single-category-box">
                            <div className="content-of-single-category-box">

                                <div className="icon-box-of-single-category-box">
                                    <div className="content-of-icon-box-of-single-category-box">
                                    <ArrowForwardIcon />
                                    </div>
                                </div>

                                <div className="title-box-of-single-category-box">
                                    <div className="content-of-title-box-of-single-category-box">
                                        <h3>{post.next_post.title}</h3>
                                    </div>
                                </div>

                                {/* <div className="right-icon-box-of-single-category-box">
                                    <div className="content-of-right-icon-box-of-single-category-box">
                                        <ArrowForwardIcon />
                                    </div>
                                </div> */}

                            </div>
                        </div>
                    </Link>
                    {/* </a> */}
                </div>
            )
        }

        return <ScrollToTop />
    }

    const [showMobileContent, setShowMobileContent] = useState<boolean>(false)

    /**
     * Update title
     */
    useEffect(() => {
        updatePageTitle(post.title ? post.title : "Loading..")
    }, [post.title])

    /**
     * Save page as pdf
     */

    const savePageAsPdf = () => {
        window.open(`/save-as-pdf/posts/${post.slug}`, '_blank')
    }

    /**
     * Siplay message
     */
    if (message.shown){
        return(
            <BaseStructuredPage>
                <div className="content-section-wrapper">
                    <GenerateMessageBox {...message} />    
                </div>
            </BaseStructuredPage>
        )
    }

    /**
     * Additional data
     */
    let postDate: string = ""

    if (post.date_created){
        postDate = post.date_created
    }

    if (post.last_updated_on){
        postDate = post.last_updated_on
    }

    if (postDate){
        postDate = dateAndTime.formatDateAndTime(postDate)
    }else{
        postDate = "-"
    }

    /**
     * Handle copy link
     */
    const handleCopyLink = () => {
        navigator.clipboard.writeText(application.returnAppLink(`posts/${post.section_slug}/${post.category_slug}/${post.slug}`))
        setCopyButtonTitle("Copied!")
        setTimeout(() => {
            setCopyButtonTitle("Copy Link")
        }, 1000)
    }

    return(
        <BaseStructuredPage>
            <div className="content-section-wrapper">

                <div className="show-mobile-page-post-content-container" onClick={() => setShowMobileContent(true)}>
                    <div className="content-of-show-mobile-page-post-content-container">
                        <p>Content</p>
                        <ArrowOutwardIcon />
                    </div>
                </div>

                <div className="single-post-page-content">

                    <div className={showMobileContent ? "mobile-post-sidebar-section-of-the-post-container active" : "mobile-post-sidebar-section-of-the-post-container"}>

                        <div className="wrapper-of-mobile-post-sidebar-section-of-the-post">
                            <div className="content-of-wrapper-of-mobile-post-sidebar-section-of-the-post">
                                <div className="mobile-post-sidebar-section-of-the-post">
                                    <div className="content-of-mobile-post-sidebar-section-of-the-post">

                                        <div className="close-icon-box-of-mobile-post-sidebar-section-of-the-post">
                                            <div className="content-of-close-icon-box-of-mobile-post-sidebar-section-of-the-post">
                                                <IconButton onClick={() => setShowMobileContent(false)}>
                                                    <GenerateIcon icon="close" />
                                                </IconButton>
                                            </div>
                                        </div>

                                        <div className="sidebar-of-mobile-post-sidebar-section-of-the-post">
                                            {loading ? renderLoadingSidebar() : <PostSidebar sectionSlug={post.section_slug} currentCategorySlug={post.category_slug} currentPostSlug={post.slug} />}
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div className="mask-of-mobile-post-sidebar-section-of-the-post"></div>
                        </div>
                    </div>

                    <div className="post-sidebar-section-of-the-post">
                        <div className="content-of-post-sidebar-section">
                            {loading ? renderLoadingSidebar() : <PostSidebar sectionSlug={post.section_slug} currentCategorySlug={post.category_slug} currentPostSlug={post.slug} />}
                        </div>
                    </div>
                   
                    <div className="post-html-content-section">
                        <div className="content-of-post-html-content-section">
                            <SectionDetailsBox 
                                loading={loading} 
                                title={post.title} 
                                breadcrumbs={breadcrumbs} 
                                titleButtons={[
                                    {
                                        title: "Copy link",
                                        icon: "link",
                                        customButton: () => {
                                            return (
                                                <Tooltip title={copyButtonTitle}>
                                                    <IconButton className="section-title-button" onClick={() => handleCopyLink()}>
                                                        <GenerateIcon icon="link" />
                                                    </IconButton>
                                                </Tooltip>
                                            )
                                        },
                                        onClick: () => {}
                                    },
                                    {
                                        title: "Download as pdf",
                                        icon: "download",
                                        onClick: () => savePageAsPdf()
                                    }
                                ]} 
                                additionalInfo={[
                                    {
                                        title: post.created_by_name ? post.created_by_name : "-",
                                        icon: "user",
                                        tooltip: "Created by"
                                    },
                                    {
                                        title: postDate,
                                        icon: "date",
                                        tooltip: post.last_updated_on ? "Last updated on" : "Created on"
                                    },
                                    // {
                                    //     title: post.updated_by_name ? post.updated_by_name : "-",
                                    //     icon: "user",
                                    //     tooltip: "Updated by"
                                    // },
                                    // {
                                    //     title: post.last_updated_on ? dateAndTime.formatDateAndTime(post.last_updated_on) : "-",
                                    //     icon: "date",
                                    //     tooltip: "Last updated on"
                                    // },
                                ]}
                            />
                            <div className="post-content-container single-post-page-post-blocks-content">
                                {!loading ? <RenderPostContent body={post.body} /> : renderLoadingContent()}
                            </div>

                            {renderNextPost()}

                            {/* Mobile buttons */}
                            <div className="mobile-post-bottom-buttons">
                                <div className="content-of-mobile-post-bottom-buttons">
                                    
                                    <div className="next-post-button-container" onClick={() => handleCopyLink()}>
                                        <div className="single-category-box">
                                            <div className="content-of-single-category-box">

                                                <div className="icon-box-of-single-category-box">
                                                    <div className="content-of-icon-box-of-single-category-box">
                                                        <GenerateIcon icon="link" />
                                                    </div>
                                                </div>

                                                <div className="title-box-of-single-category-box">
                                                    <div className="content-of-title-box-of-single-category-box">
                                                        <h3>Copy Link</h3>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>

                                    <div className="next-post-button-container" onClick={() => savePageAsPdf()}>
                                        <div className="single-category-box">
                                            <div className="content-of-single-category-box">

                                                <div className="icon-box-of-single-category-box">
                                                    <div className="content-of-icon-box-of-single-category-box">
                                                        <GenerateIcon icon="download" />
                                                    </div>
                                                </div>

                                                <div className="title-box-of-single-category-box">
                                                    <div className="content-of-title-box-of-single-category-box">
                                                        <h3>Download as PDF</h3>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </BaseStructuredPage>
    )
}