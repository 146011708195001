import React, { useEffect } from 'react'
import { addManyElementsData, createOrUpdateElementData, returnSingleFormElementData, updateElementDisabledStatus, updateElementLoadingStatus } from '../../FormElements/formsDataSlice'
import { addNewForm } from '../../FormElements/formsSlice'
import User from '../../Api/User/User'
import FormValidator from '../../FormElements/FormValidator'
import { returnCurrentUser } from '../../Api/User/userSlice'
import { useDispatch } from 'react-redux'
import GenerateForm from '../../FormElements/GenerateForm'
import Application from '../../Application'
import GenerateMessageBox from '../../FormElements/GenerateMessageBox'
import { Alert } from '@mui/material'
import { FormAlertTypes } from '../../FormElements/Types'
import { verifyStrongPassword } from '../../FormElements/Elements/FormElementTextField'

export default function UpdatePasswordPage() {

    const dispatch = useDispatch()

    const currentUser = returnCurrentUser()

    const application = new Application()

    const formDetails = {
        name: "profile_password",
        elemPrefix: "profile_password_"
    }

    const elemPrefix = formDetails.elemPrefix

    /**
     * Update button loading
     */
    const updateButtonLoadingStatus = (loading: boolean) => {
        dispatch(
            updateElementLoadingStatus({
                name: elemPrefix + "submit",
                loading
            })
        )
    }

    /**
     * Update button loading
     */
    const updateButtonDisabledStatus = (status: boolean) => {
        dispatch(
            updateElementDisabledStatus({
                name: elemPrefix + "submit",
                disabled: status
            })
        )
    }

    /**
     * Get single form element data value
     */
    const getSingleFormElementValue = (name: string) => returnSingleFormElementData(elemPrefix + name).value

    /**
     * Update alert
     */
    const updateAlert = (value: string, severity: FormAlertTypes, hidden: boolean) => dispatch(createOrUpdateElementData({
        name: elemPrefix + "alert",
        value: value,
        severity,
        hidden
    }))

    /**
     * Handle submit
     */
    const handleSubmit = () => {
        updateButtonLoadingStatus(true)
        // setTimeout(() => updateButtonLoadingStatus(false), 1000)

        const password: string = getSingleFormElementValue("password")
        const otp: string = getSingleFormElementValue("otp")

        const requiredFiels: Array<string> = [
            elemPrefix + "password",
            elemPrefix + "otp",
        ]

        /**
         * Form Validator
         */
        const formValidator = new FormValidator(requiredFiels)

        if (verifyStrongPassword(password).valid){
            if (formValidator.isValid().valid){

                const userApp = new User()
                userApp.updateCurrentUserPassword(password, otp)
                    .then(response => {
                            
                        const { status, statusText, data } = response

                        switch(status){

                            case 200:
                                dispatch(
                                    createOrUpdateElementData({
                                        name: elemPrefix + "alert",
                                        value: data.message! ? data.message : statusText,
                                        severity: "success"
                                    })
                                )

                                dispatch(
                                    createOrUpdateElementData({
                                        name: elemPrefix + "password",
                                        value: "",
                                    })
                                )

                                window.location.replace("/logout")
                                break

                        }

                        updateButtonLoadingStatus(false)
                        updateButtonDisabledStatus(true)

                    })
                    .catch(error => {
                        updateButtonLoadingStatus(false)
                        
                        const response = error.response
                        console.log(response)
                        
                        switch(response.status){

                            default:
                                dispatch(
                                    createOrUpdateElementData({
                                        name: elemPrefix + "alert",
                                        value: response.data! ? response.data.message! ? response.data.message : "" : response.statusText,
                                        severity: "error"
                                    })
                                )
                        }
                    
                    })

            }else{
                updateButtonLoadingStatus(false)
                formValidator.showRequiredFields(dispatch)
            }
        }else{
            updateButtonLoadingStatus(false)
            updateAlert("Password is not strong enough!", "error", false)
        }

    }

    /**
     * Use effect
     */
    useEffect(() => {

        /**
         * Add new form
         */
         dispatch(
            addNewForm({
                name: formDetails.name,
                elements: [
                    {
                        type: "Alert",
                        xs: 12,
                        label: "",
                        id: elemPrefix + "info"
                    },
                    {
                        type: "TextField",
                        fieldType: "password",
                        xs: 12,
                        id: elemPrefix + "password",
                        label: "New Password",
                        fullWidth: true,
                        enableStrongPassword: true
                    },
                    {
                        type: "TwoFactorAuth",
                        fieldType: "number",
                        xs: 12,
                        id: elemPrefix + "otp",
                        label: "Enter 6-digit code from Google Authenticator to change the password",
                        submitFormOnPressingEnter: true,
                        fullWidth: true,
                    },
                    {
                        type: "Alert",
                        xs: 12,
                        label: "",
                        id: elemPrefix + "alert"
                    },
                    {
                        type: "Button",
                        xs: 12,
                        id: elemPrefix + "submit",
                        label: "Update Password",
                        fullWidth: true,
                        onClick: () => {},
                        isSubmit: true
                    }
                ]
            })
        )

        /**
         * Add new form elements data
         */
        dispatch(
            addManyElementsData([
                {
                    name: elemPrefix + "info",
                    value: "In order to keep your account safe, monthly password update is required",
                    severity: "info",
                    hidden: false
                },
                {
                    name: elemPrefix + "password",
                    value: ""
                },
                {
                    name: elemPrefix + "otp",
                    value: ""
                },
                {
                    name: elemPrefix + "alert",
                    value: "Test",
                    severity: "info",
                    hidden: true
                },
                {
                    name: elemPrefix + "submit",
                    value: ""
                }
            ])
        )

    }, [])

    return(
        <div className="update-password-page-container">
            <div className="sign-in-page-container">
                <div className="content-of-sign-in-page-container">

                <div className="login-form-container update-password-form">
                    <div className="content-of-login-form-container">

                        <div className="app-logo-of-login-form-container">
                            <div className="content-of-app-logo-of-login-form-container">
                                <a href="/">
                                    <img src={application.returnAppLogo()} />
                                </a>
                            </div>
                        </div>

                        <div className="actual-form-of-login-form-container">
                            <GenerateForm name={formDetails.name} handleSubmitForm={handleSubmit} />
                        </div>

                    </div>
                </div>

                </div>
            </div>
        </div>
    )
}