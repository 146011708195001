import { AxiosRequestConfig } from "../../Api/Interfaces";
import { DataDisplayerSingleSearchParam } from "../../DataDisplayer/Interfaces";
import BaseApp from "../BaseApp";


/**
 * Companies App
 */
export default class UploadedPdfsApp extends BaseApp {

    /**
     * App Name
     */
    appName: string = "pdfs"

    /**
     * App base end point path
     */
    appBaseEndPointPath: string = "uploader/pdf"

    /**
     * Required fields
     */
    requiredFields = [
        "name"
    ]

}