import React, { useEffect } from 'react'
import { updateSectionFormModalStatus, useReturnSopSectionFormModalStatus } from './sopSlice'

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useDispatch } from 'react-redux';
import SectionForm from './SectionForm';
import { returnSingleFormElementData, useReturnFormElementData, useReturnSingleFormElementData } from '../../../FormElements/formsDataSlice';

export default function SectionFormModal() {

    const open = useReturnSopSectionFormModalStatus()
    const dispatch = useDispatch()

    const handleClose = () => {
        dispatch(updateSectionFormModalStatus(false))

        const resetFormElem = document.getElementById("reset_sop_section_modal_form")

        if (resetFormElem){
            resetFormElem.click()
        }
    }

    const loading = useReturnSingleFormElementData("section_modal_form_elem_submit").loading

    useEffect(() => {
        console.log("loading", loading)
    }, [loading])

    return(
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            maxWidth="sm"
            fullWidth
      >
            <SectionForm handleClose={handleClose} />
        <DialogActions>
            <Button onClick={handleClose}>
                Cancel
            </Button>
            <Button disabled={loading} onClick={() => {
                const elem = document.getElementById("section_modal_form_elem_submit")

                if (elem){
                    elem.click()
                }
            }} autoFocus>
                Submit
            </Button>
        </DialogActions>
      </Dialog>
    )
}