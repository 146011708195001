import React from 'react'
import { useReturnCurrentUser } from '../../Api/User/userSlice'
import { Avatar } from '@mui/material'
import ChangeThemeModeButton from './ChangeThemeModeButton'

interface UserProfileAvatarMenuItemInterface {
    title: string,
    link: string,
    additioanlCalssName?: string
}

export default function UserProfileAvatar() {

    const user = useReturnCurrentUser()

    const menuList: Array<UserProfileAvatarMenuItemInterface> = []

    menuList.push({
        title: "My Profile",
        link: "/profile/details"
    })

    if (user.is_admin){
        menuList.push({
            title: "Admin Panel",
            link: "/panel/sop"
        })
    }

    menuList.push({
        title: "Logout",
        link: "/logout",
        additioanlCalssName: "red-link"
    })

    return(
        <div className="container-for-user-avatar-menu-box">

            <div className="user-avatar-menu-box">
                <div className="content-of-user-avatar-menu-box">
                    <Avatar style={{ width: 50, height: 50 }} src={user.thumbnail} >{user.first_name.charAt(0)}</Avatar>
                </div>
            </div>

            <div className="user-avatar-pop-up-box">
                <div className="content-of-user-avatar-pop-up-box">
                    {menuList.map((item: UserProfileAvatarMenuItemInterface, index: number) => {

                        let itemClassName: string = "single-user-avatar-profile-menu-item"

                        if (item.additioanlCalssName){
                            itemClassName += ` ${item.additioanlCalssName}`
                        }

                        return(
                            <a href={item.link}>
                                <div className={itemClassName}>
                                    <div className="content-of-single-user-avatar-profile-menu-item">
                                        <h3>{item.title}</h3>
                                    </div>
                                </div>
                            </a>
                        )
                    })}

                    <div className="change-mode-container-for-user-avatar-pop-up-box">
                        <div className="content-of-change-mode-container-for-user-avatar-pop-up-box">
                            <ChangeThemeModeButton />
                        </div>
                    </div>
                </div>
            </div>

            

        </div>
    )
}