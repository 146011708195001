import MirajWarehouseBaseApp from "../MirajWarehouseBaseApp";

/**
 * Companies App
 */
export default class UsersApp extends MirajWarehouseBaseApp {

    /**
     * App Name
     */
    appName: string = "users"

    /**
     * App base end point path
     */
    appBaseEndPointPath: string = "users"

    /**
     * Required fields
     */
    requiredFields = [
        "first_name",
        "last_name",
        "email",
        // "user_group_id"
    ]

    /**
     * Reset two factor auth
     */
    resetTwoFactorAuth = async(password: string) => {
        return this.sendRequest({
            method: "POST",
            data: {
                password
            },
            url: this.appBaseEndPointPath + "/two-factor-auth/reset",
            withCredentials: true
        })
    }

    /**
     * Reset monthly password update status
     */
    resetMonthlyPasswordUpdateStatus = async() => {
        return this.sendRequest({
            method: "POST",
            data: {
                
            },
            url: this.appBaseEndPointPath + "/toggle-monthly-password-update-status",
            withCredentials: true
        })
    }

}