import Application from "../Application"

const application = new Application()

export default class Api{

    static isInProduction: boolean = true

    /**
     * Return base url
     * 
     */
    static returnBaseUrl = () => application.apiBaseLinkPath
    /**
     * Log response
     */
    static log = (...args: any) => {
        if (!Api.isInProduction){
            console.log(...args)
        }
    }

}