import React, { useEffect, useState } from 'react'
import { useCreateBlockNote } from "@blocknote/react";
import { BlockNoteView } from "@blocknote/mantine";
import RenderEditorContentAsHtml from '../../FormElements/BlockEditor/RenderEditorContentAsHtml';
import { Block } from '@blocknote/core';

interface Props {
    body: string
}

export default function RenderPostContent(props: Props) {

    // const [html, setHTML] = useState<string>("");


    const body = JSON.parse(props.body)

    const editor = useCreateBlockNote({
        initialContent: body,
    });


    // const onChange = async () => {
    
        
    //     const content = editor.document

    //     try{
    //         const html = await editor.blocksToHTMLLossy(editor.document);
    //         setHTML(html);
    //     }catch(error){
    //         console.log(error)
    //     }
    // };

    // useEffect(() => {
    //     onChange()
    // }, [body])

    return(
        <div className="rendered-html-content">
             {/* <div dangerouslySetInnerHTML={{ __html: html }} /> */}
             <RenderEditorContentAsHtml blocks={editor.document} />
        </div>
    )
}