import React, { useEffect, useState } from 'react'
import CategoriesApp, { SingleCategoryInterface } from '../../apps/categories/CategoriesApp'
import { SinglePostInterface } from '../../apps/posts/PostsApp'
import SectionsApp from '../../apps/sections/SectionsApp'
import SidebarCategory from './SidebarCategory'
import { ICategoryWithPosts, addManyCategories, useReturnCategoriesList } from '../../apps/sop/components/postsSlice'
import { useDispatch } from 'react-redux'
import { Skeleton } from '@mui/material'

interface Props {
    sectionSlug: string,
    currentCategorySlug: string,
    currentPostSlug: string
}

export interface SidebarPostInterface extends SinglePostInterface{
    isActive: boolean
}

export interface SidebarCategoryInterface extends SingleCategoryInterface{
    isActive: boolean,
    posts: Array<SidebarPostInterface>
}

export default function PostSidebar(props: Props) {

    const dispatch = useDispatch()

    const [reloadContent, setReloadContent] = useState<boolean>(false)

    const { sectionSlug, currentCategorySlug, currentPostSlug } = props

    const [sidebar, setSidebar] = useState<Array<SidebarCategoryInterface>>([])
    const [loading, setLoading] = useState<boolean>(true)

    const sectionsApp = new SectionsApp()
    const categoriesApp = new CategoriesApp()

    // const categories = useReturnCategoriesList()
    // const [categories, setCategories] = useState<Array<ICategoryWithPosts>>([])

    /**
     * Load posts
     */
    const loadCategories = () => {

        setLoading(true)
        sectionsApp.listOfCategories(sectionSlug, 1, "" , true)
            .then(response => {

                const { data } = response

                if (data && data.length > 0){

                    let categoriesList: Array<SidebarCategoryInterface> = []

                    data.map((category: SidebarCategoryInterface) => {
                        let singleCategory: SidebarCategoryInterface = {
                            ...category,
                            posts: [],
                            isActive: currentCategorySlug == category.slug ? true : false
                        }
                        categoriesList.push(singleCategory)
                    })

                    // dispatch(addManyCategories(categoriesList))
                    setSidebar(categoriesList)

                }

                setLoading(false)
                setReloadContent(false)

            })

    }

    /**
     * Load categories from state
     */
    // const loadCategoriesFromState = () => {
    //     let categoriesList: Array<SidebarCategoryInterface> = []
    //     categories.map((category) => categoriesList.push({
    //         ...(category as any),
    //         isActive: category.slug == currentCategorySlug ? true : false,
    //     }))
    //     setSidebar(categoriesList)
    //     setLoading(false)
    //     setReloadContent(false)
    // }

    /**
     * Use Effect
     */
    // useEffect(() => {
    //     loadCategories()
    // }, [sectionSlug, currentCategorySlug])
    // useEffect(() => {
    //     // if (categories.length > 0){
    //     //     loadCategoriesFromState()
    //     // }else{
    //     //     loadCategories()
    //     // }
    //     loadCategories()
    //     // loadCategoriesFromState()
    // }, [categories])

    // useEffect(() => {
    //     if (reloadContent){
    //         loadCategories()
    //         loadCategoriesFromState()
    //     }
    // }, [reloadContent])

    useEffect(() => {
        // setReloadContent(true)
        loadCategories()
    }, [])

    /**
     * Render side bar
     */
    const renderSidebar = () => {

        if (loading){
            return (
                <>
                    <Skeleton variant='rectangular' width="100%" height={5} style={{ marginBottom: 5 }} />
                    <Skeleton variant='rectangular' width="100%" height={5} style={{ marginBottom: 5 }} />
                    <Skeleton variant='rectangular' width="100%" height={5} style={{ marginBottom: 5 }} />
                    <Skeleton variant='rectangular' width="100%" height={5} style={{ marginBottom: 5 }} />
                    <Skeleton variant='rectangular' width="100%" height={5} style={{ marginBottom: 5 }} />
                </>
            )
        }else{
            if (sidebar.length > 0){
                return sidebar.map((item: SidebarCategoryInterface, index: number) => <SidebarCategory {...item} currentPostSlug={currentPostSlug} key={index} />)
            }
        }

        return <></>
    }

    return(
        <div className="post-sidebar-section">
            <div className="content-of-post-sidebar-section">

                <div className="header-of-post-sidebar-section">
                    <div className="content-of-header-of-post-sidebar-section">
                        <h3>Content</h3>
                    </div>
                </div>

                <div className="items-of-post-sidebar-section">
                    <div className="content-of-items-of-post-sidebar-section">
                        {renderSidebar()}
                    </div>
                </div>

            </div>
        </div>
    )
}