import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { addManyElementsData, createOrUpdateElementData, returnSingleFormElementData, updateElementLoadingStatus, useReturnSingleFormElementData, updateElementDisabledStatus } from '../../../FormElements/formsDataSlice'
import { addNewForm } from '../../../FormElements/formsSlice'
import GenerateForm from '../../../FormElements/GenerateForm'
import AppLogo from "../../../../assets/svg/miraj-logo.svg"
import Api from '../../../Api/Api'
import FormValidator from '../../../FormElements/FormValidator'
import { useParams } from 'react-router-dom'
import PasswordSetApp from "./PasswordSetApp"

import { resetReCaptcha } from "../../../FormElements/Elements/Recaptcha"

interface Props {
    resetPasswordForm?: boolean,
    unblockAccount?: boolean
}

export default function RequestPasswordSetForm(props: Props) {

    const dispatch = useDispatch()
    const params = useParams()

    const formDetails = {
        name: "request_set_password_from",
        elemPrefix: "request_set_password_from_"
    }

    const elemPrefix = formDetails.elemPrefix

    /**
     * Update button loading
     */
    const updateButtonLoadingStatus = (loading: boolean) => {
        dispatch(
            updateElementLoadingStatus({
                name: elemPrefix + "submit",
                loading
            })
        )
    }

    /**
     * Update button loading
     */
    const updateButtonDisabledStatus = (status: boolean) => {
        dispatch(
            updateElementDisabledStatus({
                name: elemPrefix + "submit",
                disabled: status
            })
        )
    }

    /**
     * Get single form element data value
     */
    const getSingleFormElementValue = (name: string) => returnSingleFormElementData(elemPrefix + name).value

    
    /**
     * Submit form
     */
    const HandleSubmitForm = () => {
        updateButtonLoadingStatus(true)
        // setTimeout(() => updateButtonLoadingStatus(false), 1000)

        const email: string = getSingleFormElementValue("email")
        const recaptcha: string = getSingleFormElementValue("recaptcha")

        /**
         * Form Validator
         */
        const formValidator = new FormValidator([elemPrefix + "email", elemPrefix + "password"])

        if (formValidator.isValid().valid){

            const passwordSetApp = new PasswordSetApp()

            /**
             * Send request to API
             */
            passwordSetApp.sendRequest({
                method: "POST",
                data: {
                    email,
                    unblock_account: props.unblockAccount,
                    recaptcha
                },
                url: props.resetPasswordForm ? passwordSetApp.appResetPasswordPath + "/new" : passwordSetApp.appBaseEndPointPath + "/new",
                withCredentials: false
            })
                .then(response => {
                    
                    const { status, statusText, data } = response

                    switch(status){

                        case 200:
                            dispatch(
                                createOrUpdateElementData({
                                    name: elemPrefix + "alert",
                                    value: data.message! ? data.message : statusText,
                                    severity: "success"
                                })
                            )
                            // setTimeout(() => window.location.replace("/sign-in"), 1500)
                            break

                    }

                    updateButtonLoadingStatus(false)
                    updateButtonDisabledStatus(true)

                })
                .catch(error => {
                    updateButtonLoadingStatus(false)
                    
                    const response = error.response
                    Api.log(response)

                    resetReCaptcha(dispatch, elemPrefix + "recaptcha")
                    
                    switch(response.status){

                        default:
                            dispatch(
                                createOrUpdateElementData({
                                    name: elemPrefix + "alert",
                                    value: response.data! ? response.data.message! ? response.data.message : "" : response.statusText,
                                    severity: "error"
                                })
                            )
                    }
                
                })
        }else{
            updateButtonLoadingStatus(false)
            formValidator.showRequiredFields(dispatch)
        }



    }

    /**
     * Use Effect
     */
    useEffect(() => {

        /**
         * Add new form
         */
        dispatch(
            addNewForm({
                name: formDetails.name,
                elements: [
                    {
                        type: "TextField",
                        fieldType: "text",
                        xs: 12,
                        id: elemPrefix + "email",
                        label: "Email",
                        submitFormOnPressingEnter: true,
                        fullWidth: true
                    },
                    {
                        type: "Alert",
                        xs: 12,
                        label: "",
                        id: elemPrefix + "alert"
                    },
                    {
                        type: "ReCaptcha",
                        xs: 12,
                        label: "",
                        id: elemPrefix + "recaptcha"
                    },
                    {
                        type: "Button",
                        xs: 12,
                        id: elemPrefix + "submit",
                        label: "Submit",
                        fullWidth: true,
                        onClick: () => {},
                        isSubmit: true
                    },
                    // {
                    //     type: "Link",
                    //     xs: 6,
                    //     label: "Request a new link",
                    //     link: "/set-password",
                    //     id: elemPrefix + "request_new_link"
                    // },
                    // {
                    //     type: "Link",
                    //     xs: 6,
                    //     label: "Sign in to account",
                    //     link: "/sign-in",
                    //     id: elemPrefix + "sign_in_link"
                    // },
                ]
            })
        )

        /**
         * Add new form elements data
         */
        dispatch(
            addManyElementsData([
                {
                    name: elemPrefix + "email",
                    value: "",
                },
                {
                    name: elemPrefix + "alert",
                    value: "Test",
                    severity: "info",
                    hidden: true
                },
                {
                    name: elemPrefix + "submit",
                    value: ""
                },
                {
                    name: elemPrefix + "recaptcha",
                    value: ""
                }
            ])
        )
    }, [])

    return(
        <div className="login-form-container" style={{ minWidth: 400 }}>
            <div className="content-of-login-form-container">

                <div className="app-logo-of-login-form-container">
                    <div className="content-of-app-logo-of-login-form-container">
                        <a href="/">
                            <img src={AppLogo} />
                        </a>
                    </div>
                </div>

                <div className="title-box-of-login-form-container">
                    <div className="content-of-title-box-of-login-form-container">
                        <h3>{props.unblockAccount ? "Unblock account" : props.resetPasswordForm ? "Request Password Reset Link" : "Request Password Set Link"}</h3>
                    </div>
                </div>

                <div className="actual-form-of-login-form-container">
                    <GenerateForm name={formDetails.name} handleSubmitForm={HandleSubmitForm} />
                </div>

            </div>
        </div>
    )
}