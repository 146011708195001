import React, { useEffect, useState } from 'react'
import CategoriesApp, { SingleCategoryInterface } from '../../apps/categories/CategoriesApp'
import SectionsApp from '../../apps/sections/SectionsApp'
import GenerateMessageBox, { MessageBoxPropsInterface } from '../../FormElements/GenerateMessageBox'
import { DataDisplayerPagination } from '../../DataDisplayer/Interfaces'
import PostsApp, { SinglePostInterface } from '../../apps/posts/PostsApp'
import SinglePostBox from './SinglePostBox'
import LoadMoreButton from './LoadMoreButton'
import { Skeleton } from '@mui/material'
import { returnSingleLoadedCategoryDetails, useReturnCategoriesList, addPostsToCategory } from '../../apps/sop/components/postsSlice'
import { useDispatch } from 'react-redux'

interface Props {
    categorySlug: string,
    search?: string,
    allPosts?: boolean
}

export default function PostsList(props: Props) {

    const dispatch = useDispatch()

    const { categorySlug } = props

    // const [posts, setPosts] = useState<Array<SinglePostInterface>>([])
    const categories = useReturnCategoriesList()
    const posts = returnSingleLoadedCategoryDetails(categories, categorySlug).posts

    const [loading, setLoading] = useState<boolean>(true)

    const [pagination, setPagination] = useState<DataDisplayerPagination>({
        count: 0,
        current_page: 0,
        total_pages: 0
    })

    const categoriesApp = new CategoriesApp()
    const postsApp = new PostsApp()

    interface Message extends MessageBoxPropsInterface {
        shown?: boolean
    }
    const [message, setMessage] = useState<Message>({
        shown: false,
        title: "",
        message: "",
        type: "info"
    })

    const resetMessage = () => setMessage({ shown: false, title: "", message: "", type: "info" })

    /**
     * Load posts
     */
    const loadPosts = (page: number = 1, search: string = "", loadMoreItems: boolean = false) => {
        setLoading(true)
        resetMessage()

        if (props.search){
            search = props.search
        }

        let request = categoriesApp.listOfPosts(categorySlug, page, search, false)

        if (props.allPosts){
            request = postsApp.listOfInstances(page, search, false)
        }

        request
            .then(response => {

                const { status, data } = response

                switch(status){

                    case 200:

                        if (!loadMoreItems){
                            // setPosts(data.results)
                            dispatch(addPostsToCategory({ categorySlug: categorySlug, posts: data.results }))
                        }else{

                            let items = posts

                            if (data.results.length > 0){
                                data.results.map((item: SinglePostInterface) => {
                                    items.push(item)
                                })
                            }

                            // setPosts(items)
                            dispatch(addPostsToCategory({ categorySlug: categorySlug, posts: items }))

                        }

                        setPagination({
                            count: data.count ? data.count : 0,
                            current_page: page,
                            total_pages: data.total_pages ? data.total_pages : 0
                        })
                        break

                }

                setLoading(false)

                if (posts.length == 0){
                    setMessage({
                        shown: true,
                        title: "Nothing...",
                        message: "No posts have been found :(",
                        type: "info"
                    })
                }

            })
            .catch(error => {
                const { response } = error
                setMessage({
                    shown: true,
                    title: "Error",
                    message: response.data! ? response.data.message! ? response.data.message : "" : response.statusText,
                    type: "error"
                })
            })
    }

    /**
     * Load
     */
    useEffect(() => {
        loadPosts()
    }, [categorySlug])

    /**
     * render posts
     */
    const renderPosts = () => {
        if (loading){
            return(
                <>
                    <Skeleton variant="rectangular" width="100%" height={100} style={{ marginBottom: 10 }} />
                    <Skeleton variant="rectangular" width="100%" height={100} style={{ marginBottom: 10 }} />
                    <Skeleton variant="rectangular" width="100%" height={100} style={{ marginBottom: 10 }} />
                    <Skeleton variant="rectangular" width="100%" height={100} style={{ marginBottom: 10 }} />
                    <Skeleton variant="rectangular" width="100%" height={100} style={{ marginBottom: 10 }} />
                </>
            )
        }else{
            if (posts.length > 0){
                return posts.map((post: SinglePostInterface, index: number) => <SinglePostBox displayDetails={props.allPosts} {...post} key={index} />)
            }else{
                return <GenerateMessageBox type="info" title="Nothing found..." message={props.search ? "No posts have been found :(" : "No posts have been added yet..."} />
            }
        }

        return <></>
    }

    /**
     * Load more items
     */
    const loadMoreItems = () => {
        loadPosts(pagination.current_page + 1, "", true)
    }

    return(
        <div className="section-categories-list-container">
            <div className="content-of-section-categories-list-container">
                {renderPosts()}
                <LoadMoreButton title="Load more" loading={loading} onClick={loadMoreItems} show={pagination.total_pages !== pagination.current_page ? false : true} />
            </div>
        </div>
    )
}