import { Block } from '@blocknote/core'
import React from 'react'
import { IBlockEditorStyles, returnStyles } from '../BlockEditor'

export default function NumberedListItemBlock(props: Block) {

    const blockProps = props.props
    const content: any = props.content

    // console.log("NumberedListItemBlock", props)

    let value: string = ""
    let styles: IBlockEditorStyles = {}

    if (content){
        if (content[0]){
            if ("text" in content[0]){
                value = content[0].text
            }
            if ("styles" in content[0]){
                styles = content[0].styles
            }
        }
    }

    return <ul className="numbered-list-ul"><li><p className='bn-inline-content' style={returnStyles(styles, blockProps)}>{value}</p></li></ul>
}