import React from 'react'
import { SingleCategoryInterface } from '../../apps/categories/CategoriesApp'
import { Grid } from '@mui/material'
import ClassIcon from '@mui/icons-material/Class';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

interface Props extends SingleCategoryInterface {

}

export default function SingleCategoryBox(props: Props) {

    const { title, section_slug, slug } = props

    return(
        <div className="wrapper-of-single-category-box">
            <a href={`/posts/${section_slug}/${slug}`}>
                <div className="single-category-box">
                    <div className="content-of-single-category-box">

                        {/* <div className="icon-box-of-single-category-box">
                            <div className="content-of-icon-box-of-single-category-box">
                                <ClassIcon />
                            </div>
                        </div> */}

                        <div className="title-box-of-single-category-box">
                            <div className="content-of-title-box-of-single-category-box">
                                <h3>{title}</h3>
                            </div>
                        </div>

                        <div className="right-icon-box-of-single-category-box">
                            <div className="content-of-right-icon-box-of-single-category-box">
                                <ArrowForwardIcon />
                            </div>
                        </div>

                    </div>
                </div>
            </a>
        </div>
    )
}