import React from 'react'
import { ISopDetailsSingleItem, emptySopPostDetailsModal, updatePostDetailsModal, useReturnSopPostDetailsModal } from './sopSlice'

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useDispatch } from 'react-redux';

function SinglePostDetailItem(props: ISopDetailsSingleItem){

    const { title, value } = props

    return(
        <div className="single-post-detail-item-box">
            <div className="content-of-single-post-detail-item-box">
                <h4>{title}</h4>
                <h3>{value}</h3>
            </div>
        </div>
    )
}

export default function PostDetailsModal() {

    const dispatch = useDispatch()
    const modal = useReturnSopPostDetailsModal()

    const { open, title, items } = modal

    const handleClose = () => {
        dispatch(updatePostDetailsModal({
            ...modal,
            open: false
        }))
    }
    
    return(
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            maxWidth="sm"
            fullWidth
      >
        <DialogTitle id="alert-dialog-title">
             {title}
        </DialogTitle>
        <DialogContent>
            <div className="post-details-modal-items-container">
                <div className="content-of-post-details-modal-items-container">
                    {items.map((item, index) => <SinglePostDetailItem {...item} key={index} />)}
                </div>
            </div>
        </DialogContent>
        <DialogActions>
            <Button onClick={handleClose}>
                Close
            </Button>
        </DialogActions>
      </Dialog>
    )
}