import React from 'react'
import GenerateIcon from "../../FormElements/GenerateIcon"

export interface BreadcrumbInterface {
    title: string,
    link?: string,
    index: number,
    total: number
}

interface Props {
    breadcrumbs: Array<BreadcrumbInterface>
}

export function Breadcrumb(props: BreadcrumbInterface) {

    const { title, link, index, total } = props

    return(
        <div className="single-breadcrumb-item-box">
            <div className="content-of-single-breadcrumb-item-box">
                <a className={!link ? "no-link" : ""} href={link}>
                    {title}
                </a>
                {total !== (index + 1) && (
                    <div className="breadcrumb-separator-icon">
                        <GenerateIcon icon="forward" />
                    </div>
                )}
            </div>
        </div>
    )
}

export default function Breadcrumbs(props: Props) {

    const { breadcrumbs } = props

    return(
        <div className="breadcrumbs-container">
            <div className="content-of-breadcrumbs-container">
                {breadcrumbs.length > 0 && breadcrumbs.map((breadcrumb: BreadcrumbInterface, index: number) => <Breadcrumb {...breadcrumb} index={index} total={breadcrumbs.length} key={index} />)}
            </div>
        </div>
    )
}