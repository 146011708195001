import { Block } from '@blocknote/core'
import React from 'react'
import { IBlockEditorStyles, returnStyles } from '../BlockEditor'

// export default function ParagraphBlock(props: Block) {

//     const blockProps = props.props
//     const content: any = props.content

//     let type: string = ""
//     let href: string = ""

//     let value: string = ""
//     let styles: IBlockEditorStyles = {}

//     if (content){
//         if (content[0]){
//             if ("text" in content[0]){
//                 value = content[0].text
//             }
//             if ("styles" in content[0]){
//                 styles = content[0].styles
//             }
//             if ("type" in content[0]){
//                 type = content[0].type
//             }
//             if ("href" in content[0]){
//                 href = content[0].href
//             }
//             if ("content" in content[0]){
//                 if (content[0].content[0]){
//                     if ("text" in content[0].content[0]){
//                         value = content[0].content[0].text
//                     }
//                 }
//             }
//         }
//     }

//     if (content){
//         if (content.length > 0){
//             content.map((item: any) => {
//                 console.log("item:", item)
//             })
//         }
//     }

//     if (type == "link"){
//         return <a target='_blank' href={href}>{value}</a>
//     }

//     if (!value){
//         return <p style={{ height: 1 }}></p>
//     }

//     return <p className="bn-inline-content" style={returnStyles(styles, blockProps)}>{value}</p>
// }
export default function ParagraphBlock(props: Block) {

    const blockProps = props.props
    const content: any = props.content

    if (content){
        if (content.length > 0){
            console.log("content", content)
            return (
                <p className="bn-inline-content">
                    {
                        content.map((item: any) => {
                            console.log("item:", item)
            
                            let type: string = ""
                            let href: string = ""
                        
                            let value: string = ""
                            let styles: IBlockEditorStyles = {}
            
                            if (item.text){
                                value = item.text
                            }
                            if (item.styles){
                                styles = item.styles
                            }
                            if (item.type){
                                type = item.type
                            }
                            if (item.href){
                                href = item.href
                            }
                            if (item.content){
                                if (item.content[0]){
                                    if (item.content[0].text){
                                        value = item.content[0].text
                                    }
                                }
                            }
            
                            if (type == "link"){
                                return <a target='_blank' href={href} style={styles}>{value}</a>
                            }
                        
                            if (!value){
                                return ""
                            }

                            if (type == "text"){

                                if (styles.italic){
                                    return <em>{value}</em>
                                }

                                return value
                            }
                        
                            // return <p style={returnStyles(styles, blockProps)}>{value}</p>
            
                        })
                    }
                </p>
            )
        }
    }

    return <p style={{ height: 1 }}></p>
}