import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { useSelector } from "react-redux"
import { RootState, store } from "../../../../app/store"
import { emptySectionDetails, SingleSectionInterface } from "../../sections/SectionsApp"
import { DataDisplayerPagination } from '../../../DataDisplayer/Interfaces'
import { SingleCategoryInterface } from "../../categories/CategoriesApp"
import { SinglePostInterface } from "../../posts/PostsApp"
import { SingleItemDataDisplayerType } from "./ItemsDisplayer/types"


interface SopModalButtonInterface {
    title: string,
    onClick: () => void,
    closeModal?: boolean
}

interface SopModalInterface {
    shown: boolean,
    title: string,
    message: string,
    buttons: Array<SopModalButtonInterface>
}

export interface ISopDetailsSingleItem {
    title: string,
    value: string
}

export interface ISopPostDetailsModal {
    open: boolean,
    title: string,
    items: Array<ISopDetailsSingleItem>
}
export const emptySopPostDetailsModal: ISopPostDetailsModal = {
    open: false,
    title: "",
    items: []
}

interface InitialSOPStateInterface {
    sections: Array<SingleSectionInterface>,
    loadingSections: boolean,
    sectionsPagination: DataDisplayerPagination,
    loadingCategories: boolean,
    categories: Array<SingleCategoryInterface>,
    categoriesPagination: DataDisplayerPagination,
    categoryFormModalStatus: boolean,
    sectionFormModalStatus: boolean,
    posts: Array<SinglePostInterface>,
    postsLoading: boolean,
    postsPagination: DataDisplayerPagination,
    postsFormModalStatus: boolean,
    modal: SopModalInterface,
    activeSopSection: string,
    activeSopSectionDetails: SingleSectionInterface,
    cashedSopSectionDetails: SingleSectionInterface,
    postDetailsModal: ISopPostDetailsModal,
    updateItemsOrderStatus: boolean,
    enableBoxListPreviewType: boolean,
    selectedItems: Array<SingleItemDataDisplayerType>,
    enableSelectItems: boolean
}

const initialState: InitialSOPStateInterface = {
    sections: [],
    loadingSections: true,
    sectionsPagination: {
        total_pages: 0,
        current_page: 0,
        count: 0
    },
    loadingCategories: true,
    categories: [],
    categoriesPagination: {
        total_pages: 0,
        current_page: 0,
        count: 0
    },
    categoryFormModalStatus: false,
    modal: {
        shown: false,
        title: "",
        message: "",
        buttons: []
    },
    sectionFormModalStatus: false,
    posts: [],
    postsLoading: true,
    postsPagination: {
        total_pages: 0,
        current_page: 0,
        count: 0
    },
    postsFormModalStatus: false,
    activeSopSection: "miraj-sop",
    activeSopSectionDetails: emptySectionDetails,
    cashedSopSectionDetails: emptySectionDetails,
    postDetailsModal: emptySopPostDetailsModal,
    updateItemsOrderStatus: false,
    enableBoxListPreviewType: false,
    selectedItems: [],
    enableSelectItems: false
}

const sopSlice = createSlice({
    name: "sopSlice",
    initialState,
    reducers: {
        createOrUpdateSection: (state: InitialSOPStateInterface, action: PayloadAction<SingleSectionInterface>) => {
            const payload = action.payload
            
            let sections = state.sections
            
            if (sections.some(e => e.id === payload.id)){
                const findIndex = sections.map((x) => x.id ).indexOf(payload.id)

                if (findIndex === 0 || findIndex > 0){
                    if (sections[findIndex]){
                        sections[findIndex] = payload
                    }
                }
            }else{
                sections.push(payload)
            }

            state.sections = sections
        },
        resetSections: (state: InitialSOPStateInterface, action: PayloadAction<[]>) => {
            state.sections = []
        },
        deleteSection: (state: InitialSOPStateInterface, action: PayloadAction<{ id:number }>) => {
            const payload = action.payload
            
            let sections = state.sections
            
            if (sections.some(e => e.id === payload.id)){
                sections = sections.filter(x=>x.id !== payload.id)
            }

            state.sections = sections
        },
        addManySections: (state: InitialSOPStateInterface, action: PayloadAction<Array<SingleSectionInterface>>) => {
            const payload = action.payload
            
            let sections = state.sections
           
            if (payload.length > 0){
                payload.map((item: SingleSectionInterface) => {
                    if (!sections.some(e => e.id === item.id)){
                        sections.push(item)
                    }
                })
            }

            state.sections = sections
        },
        updateSectionLoadingStatus: (state: InitialSOPStateInterface, action: PayloadAction<boolean>) => {
            const payload = action.payload
            state.loadingSections = payload
        },
        updateSectionPagination: (state: InitialSOPStateInterface, action: PayloadAction<DataDisplayerPagination>) => {
            const paylaod = action.payload
            state.sectionsPagination = paylaod
        },
        updateSectionFormModalStatus: (state: InitialSOPStateInterface, action: PayloadAction<boolean>) => {
            const paylaod = action.payload
            state.sectionFormModalStatus = paylaod
        },
        updateSopModalOpenStatus: (state: InitialSOPStateInterface, action: PayloadAction<boolean>) => {
            const paylaod = action.payload
            state.modal.shown = paylaod
        },
        updateSopModal: (state: InitialSOPStateInterface, action: PayloadAction<SopModalInterface>) => {
            const payload = action.payload
            state.modal = payload
        },
        updateSopActiveSection: (state: InitialSOPStateInterface, action: PayloadAction<string>) => {
            const payload = action.payload
            state.activeSopSection = payload
        },
        updateSopActiveSectionDetails: (state: InitialSOPStateInterface, action: PayloadAction<SingleSectionInterface>) => {
            const payload = action.payload
            state.activeSopSectionDetails = payload
        },
        updateSopCashedSectionDetails: (state: InitialSOPStateInterface, action: PayloadAction<SingleSectionInterface>) => {
            const payload = action.payload
            state.cashedSopSectionDetails = payload
        },
        createOrUpdateCategory: (state: InitialSOPStateInterface, action: PayloadAction<SingleCategoryInterface>) => {
            const payload = action.payload
            
            let categories = state.categories
            
            if (categories.some(e => e.id === payload.id)){
                const findIndex = categories.map((x) => x.id ).indexOf(payload.id)

                if (findIndex === 0 || findIndex > 0){
                    if (categories[findIndex]){
                        categories[findIndex] = payload
                    }
                }
            }else{
                categories.push(payload)
            }

            state.categories = categories
        },
        resetCategories: (state: InitialSOPStateInterface, action: PayloadAction<[]>) => {
            state.categories = []
        },
        deleteCategory: (state: InitialSOPStateInterface, action: PayloadAction<{ id:number }>) => {
            const payload = action.payload
            
            let categories = state.categories
            
            if (categories.some(e => e.id === payload.id)){
                categories = categories.filter(x=>x.id !== payload.id)
            }

            state.categories = categories
        },
        addManyCategories: (state: InitialSOPStateInterface, action: PayloadAction<Array<SingleCategoryInterface>>) => {
            const payload = action.payload
            
            let categories = state.categories
           
            if (payload.length > 0){
                payload.map((item: SingleCategoryInterface) => {
                    if (!categories.some(e => e.id === item.id)){
                        categories.push(item)
                    }
                })
            }

            state.categories = categories
        },
        updateCategoryLoadingStatus: (state: InitialSOPStateInterface, action: PayloadAction<boolean>) => {
            const payload = action.payload
            state.loadingCategories = payload
        },
        updateCategoryPagination: (state: InitialSOPStateInterface, action: PayloadAction<DataDisplayerPagination>) => {
            const paylaod = action.payload
            state.categoriesPagination = paylaod
        },
        updateCategoryFormModalStatus: (state: InitialSOPStateInterface, action: PayloadAction<boolean>) => {
            const paylaod = action.payload
            state.categoryFormModalStatus = paylaod
        },
        createOrUpdatePost: (state: InitialSOPStateInterface, action: PayloadAction<SinglePostInterface>) => {
            const payload = action.payload
            
            let posts = state.posts
            
            if (posts.some(e => e.id === payload.id)){
                const findIndex = posts.map((x) => x.id ).indexOf(payload.id)

                if (findIndex === 0 || findIndex > 0){
                    if (posts[findIndex]){
                        posts[findIndex] = payload
                    }
                }
            }else{
                posts.push(payload)
            }

            state.posts = posts
        },
        addManyPosts: (state: InitialSOPStateInterface, action: PayloadAction<Array<SinglePostInterface>>) => {
            const payload = action.payload
            
            let posts = state.posts
           
            if (payload.length > 0){
                payload.map((item: SinglePostInterface) => {
                    if (!posts.some(e => e.id === item.id)){
                        posts.push(item)
                    }
                })
            }

            state.posts = posts
        },
        resetPosts: (state: InitialSOPStateInterface, action: PayloadAction<[]>) => {
            state.posts = []
        },
        deletePost: (state: InitialSOPStateInterface, action: PayloadAction<{ id:number }>) => {
            const payload = action.payload
            
            let posts = state.posts
            
            if (posts.some(e => e.id === payload.id)){
                posts = posts.filter(x=>x.id !== payload.id)
            }

            state.posts = posts
        },
        updatePostLoadingStatus: (state: InitialSOPStateInterface, action: PayloadAction<boolean>) => {
            const payload = action.payload
            state.postsLoading = payload
        },
        updatePostsPagination: (state: InitialSOPStateInterface, action: PayloadAction<DataDisplayerPagination>) => {
            const paylaod = action.payload
            state.postsPagination = paylaod
        },
        updatePostsFormModalStatus: (state: InitialSOPStateInterface, action: PayloadAction<boolean>) => {
            const paylaod = action.payload
            state.postsFormModalStatus = paylaod
        },
        updatePostDetailsModal: (state: InitialSOPStateInterface, action: PayloadAction<ISopPostDetailsModal>) => {
            const paylaod = action.payload
            state.postDetailsModal = paylaod
        },
        updateItemsOrderStatus: (state: InitialSOPStateInterface, action: PayloadAction<boolean>) => {
            const paylaod = action.payload
            state.updateItemsOrderStatus = paylaod
        },
        updateEnableBoxListPreviewType: (state: InitialSOPStateInterface, action: PayloadAction<boolean>) => {
            const paylaod = action.payload
            state.enableBoxListPreviewType = paylaod
        },
        addManySelectedItems: (state: InitialSOPStateInterface, action: PayloadAction<Array<SingleItemDataDisplayerType>>) => {
            const payload = action.payload
            
            let items = state.selectedItems
           
            if (payload.length > 0){
                payload.map((item: SingleItemDataDisplayerType) => {
                    if (!items.some(e => e.id === item.id)){
                        items.push(item)
                    }
                })
            }

            state.selectedItems = items
        },
        createOrUpdateSelectedItem: (state: InitialSOPStateInterface, action: PayloadAction<SingleItemDataDisplayerType>) => {
            const payload = action.payload
            
            let items = state.selectedItems
            
            if (items.some(e => e.id === payload.id)){
                const findIndex = items.map((x) => x.id ).indexOf(payload.id)

                if (findIndex === 0 || findIndex > 0){
                    if (items[findIndex]){
                        items[findIndex] = payload
                    }
                }
            }else{
                items.push(payload)
            }

            state.selectedItems = items
        },
        resetSelectedItems: (state: InitialSOPStateInterface, action: PayloadAction<[]>) => {
            state.selectedItems = []
        },
        deleteSelectedItem: (state: InitialSOPStateInterface, action: PayloadAction<{ id:number }>) => {
            const payload = action.payload
            
            let items = state.selectedItems
            
            if (items.some(e => e.id === payload.id)){
                items = items.filter(x=>x.id !== payload.id)
            }

            state.selectedItems = items
        },
        updateEnableSelectItemsStatus: (state: InitialSOPStateInterface, action: PayloadAction<boolean>) => {
            state.enableSelectItems = action.payload
        },
    }
})

/**
 * Return single section
 */

export const returnSingleSOPSection = (id: number) => {
    const sections = store.getState().sop.sections

    if (sections.length > 0){
        sections.map(section => {
            if (section.id == id){
                return section
            }
        })
    }

    return emptySectionDetails
}

/**
 * Return all the sections
 */
export const returnListOfSOPSections = (filter: boolean = false) => {
    const sections = store.getState().sop.sections
    return sections
}

export const useReturnListOfSOPSections = () => useSelector((state: RootState) => {
    return state.sop.sections
})

/**
 * Categories
 */
export const useReturnListOfCategories = () => useSelector((state: RootState) => {
    return state.sop.categories
})

export const useReturnCategoriesLoadingStatus = () => useSelector((state: RootState) => {
    return state.sop.loadingCategories
})

export const useReturnCategoriesPagination = () => useSelector((state: RootState) => {
    return state.sop.categoriesPagination
})

export const useReturnCategoryFormModalStatus = () => useSelector((state: RootState) => {
    return state.sop.categoryFormModalStatus
})

/**
 * Posts
 */
export const useReturnListOfPOsts = () => useSelector((state: RootState) => {
    return state.sop.posts
})

export const useReturnPostsLoadingStatus = () => useSelector((state: RootState) => {
    return state.sop.postsLoading
})

export const useReturnPostPagination = () => useSelector((state: RootState) => {
    return state.sop.postsPagination
})

export const useReturnPostFormModalStatus = () => useSelector((state: RootState) => {
    return state.sop.postsFormModalStatus
})

/**
 * Return sections loading status
 */
export const returnSopSectionsLoadingStatus = () => {
    return store.getState().sop.loadingSections
}

export const useReturnSopSectionsLoadingStatus = () => useSelector((state: RootState) => {
    return state.sop.loadingSections
})

export const useReturnSopSectionsPagination = () => useSelector((state: RootState) => {
    return state.sop.sectionsPagination
})

export const useReturnSopSectionFormModalStatus = () => useSelector((state: RootState) => {
    return state.sop.sectionFormModalStatus
})

/**
 * Modal details
 */
export const useReturnSopModalDetails = () => useSelector((state: RootState) => {
    return state.sop.modal
})

/**
 * Sop Active Section
 */
export const useReturnSopActiveSection = () => useSelector((state: RootState) => {
    return state.sop.activeSopSection
})

/**
 * Sop Active Details
 */
export const useReturnSopActiveSectionDetails = () => useSelector((state: RootState) => {
    return state.sop.activeSopSectionDetails
})

export const useReturnSopCashedSectionDetails = () => useSelector((state: RootState) => {
    return state.sop.cashedSopSectionDetails
})

/**
 * Post details modal
 */
export const useReturnSopPostDetailsModal = () => useSelector((state: RootState) => {
    return state.sop.postDetailsModal
})

export const useReturnUpdateItemsOrderStatus = () => useSelector((state: RootState) => {
    return state.sop.updateItemsOrderStatus
})

export const useReturnEnableBoxListPreviewType = () => useSelector((state: RootState) => {
    return state.sop.enableBoxListPreviewType
})

/**
 * Return selected items
 */
export const useReturnSelectedItems = () => useSelector((state: RootState) => {
    return state.sop.selectedItems
})

export const useReturnEnableSelectItem = () => useSelector((state: RootState) => {
    return state.sop.enableSelectItems
})

export const { createOrUpdateSection, updateSectionLoadingStatus, addManySections, updateSectionPagination, updateSectionFormModalStatus, updateSopModalOpenStatus, updateSopModal, deleteSection, updateSopActiveSection, updateSopActiveSectionDetails, createOrUpdateCategory, deleteCategory, addManyCategories, updateCategoryLoadingStatus, updateCategoryPagination, resetCategories, updateCategoryFormModalStatus, updateSopCashedSectionDetails, createOrUpdatePost, resetPosts, deletePost, updatePostLoadingStatus, updatePostsPagination, updatePostsFormModalStatus, addManyPosts, updatePostDetailsModal, updateItemsOrderStatus, resetSections, updateEnableBoxListPreviewType, addManySelectedItems, createOrUpdateSelectedItem, resetSelectedItems, deleteSelectedItem, updateEnableSelectItemsStatus } = sopSlice.actions
export default sopSlice.reducer