import { IconButton } from '@mui/material'
import React from 'react'
import Logo from "../../../assets/svg/miraj-logo.svg"
import GenerateIcon from '../../FormElements/GenerateIcon'
import AdminMenu from './AdminMenu'
import UserInfoBox from './UserInfoBox'

export const hideAdminSidebar = (show: boolean = false) => {
    window.localStorage.setItem("admin_sidebar", show ? "shown" : "hidden")
    const adminPanelPageContainer = document.getElementById("admin-panel-main-page-container")

    if (adminPanelPageContainer){
        if (show){
            adminPanelPageContainer.classList.remove("hide-sidebar")
        }else{
            adminPanelPageContainer.classList.add("hide-sidebar")
        }
    }
}

export default function Sidebar() {
    return(
        <>
            <div className="admin-panel-sidebar">
                <div className="content-of-admin-panel-sidebar">
                    
                    <div className="app-logo-of-admin-panel-sidebar">
                        <div className="content-of-app-logo-of-admin-panel-sidebar">
                            <a href="/">
                               <img src={Logo} />
                            </a>

                            <div className="hide-sidebar-box">
                                <div className="content-of-hide-sidebar-box">
                                    <IconButton onClick={() => hideAdminSidebar(window.innerWidth <= 1600 ? true : false)}>
                                        <GenerateIcon icon="menu" />
                                    </IconButton>
                                </div>
                            </div>
                        </div>
                    </div>  

                    <div style={{
                        padding: "35px 15px 0"
                    }}>
                        <UserInfoBox />
                    </div>
                    <AdminMenu />

                </div>
            </div>
            <div className="mask-of-admin-panel-sidebar" onClick={() => hideAdminSidebar(window.innerWidth <= 1600 ? true : false)}></div>
        </>
    )
}