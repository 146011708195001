import { Block, BlockSchema, BlockSchemaFromSpecs, InlineContentSchema, StyleSchema, DefaultInlineContentSchema, DefaultStyleSchema } from '@blocknote/core'
import React, { useEffect, useState } from 'react'
import RenderBlockAsHtml from './RenderBlockAsHtml'
import { IBlockContentItem } from './BlockEditor'

interface Props {
    blocks: Block[]
}

export default function RenderEditorContentAsHtml(props: Props) {



    const [blocks, setBlocks] = useState<Array<Block>>(props.blocks)
    const [number, setNumber] = useState<number>(0)

    // useEffect(() => {
    //     console.log("blocks", blocks)
    // }, [blocks])
    // useEffect(() => {
    //     let blocksList: Array<Block> = []

    //     if (props.blocks.length > 0){
    //         props.blocks.map((singleBlock) => {
    
    //             let blockData: Block = singleBlock
    //             const type: string = singleBlock.type
    
    //             let blockContent = singleBlock.content
    
    //             if (type == "numberedListItem"){
    //                 setNumber(number + 1)
    
    //                 let listItems: Array<IBlockContentItem> = []
    //                 let blockContent =  (singleBlock.content as any)
    
    //                 if (blockContent[0]){
    //                     if ("text" in blockContent[0]){
    //                         blockContent[0].text = `${number}. ${blockContent[0].text}`
    //                     }
    //                 }
                    
    //             }else{
    //                 setNumber(0)
    //             }
    
    //             blockData.content = blockContent
    
    //             blocksList.push(blockData)
    
    //         })

    //         setBlocks(blocksList)
    //     }
    // }, [])

    return(
        <div className="note-block-rendered-blocks-container">
            {blocks.length > 0 ? blocks.map((block, index) => {
                return <RenderBlockAsHtml key={index} block={block} />
            }) : <></>}
        </div>
    )
}