import React, { useEffect } from "react"
import Footer from "./Footer"
import Header from "./Header"

interface Props {
    disableHeader?: boolean,
    disableFooter?: boolean
}

export default function BaseStructuredPage(props: React.PropsWithChildren<Props>){


    return(
        <div className="warehouse-main-page-container">
            <div className="warehouse-main-page-wrapper">

                {!props.disableHeader ? <Header /> : ""}

                <div className="main-page-content-container">
                    <div className="content-of-main-page-content-container">{props.children}</div>
                </div>

            </div>

            {!props.disableFooter ? <Footer /> : ""}
        </div>
    )
}