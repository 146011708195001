import React from 'react'
import { SingleCategoryInterface } from '../../apps/categories/CategoriesApp'
import { Grid } from '@mui/material'
import ClassIcon from '@mui/icons-material/Class';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { SinglePostInterface } from '../../apps/posts/PostsApp';
import { Link } from 'react-router-dom';

interface Props extends SinglePostInterface {
    displayDetails?: boolean
}

export default function SinglePostBox(props: Props) {

    const { title, slug, section_slug, category_slug, displayDetails, category_name, section_name } = props

    interface PostDetailInterface {
        title: string,
        value: string
    }

    const postDetailsList: Array<PostDetailInterface> = [
        {
            title: "Title:",
            value: title
        },
        {
            title: "Section:",
            value: section_name
        },
        {
            title: "Category:",
            value: category_name
        }
    ]

    return(
        <div className="wrapper-of-single-category-box">
            <Link to={`/posts/${section_slug}/${category_slug}/${slug}`}>
            {/* <a href={`/posts/${section_slug}/${category_slug}/${slug}`}> */}
                <div className="single-category-box">
                    <div className="content-of-single-category-box">

                        {/* <div className="icon-box-of-single-category-box">
                            <div className="content-of-icon-box-of-single-category-box">
                                <ClassIcon />
                            </div>
                        </div> */}

                        {!displayDetails ? (
                             <div className="title-box-of-single-category-box">
                                <div className="content-of-title-box-of-single-category-box">
                                    <h3>{title}</h3>
                                </div>
                            </div>
                        ): (
                            <div className="detailed-post-items-container">
                                <div className="content-of-detailed-post-items-container">

                                    {postDetailsList.map((item, index) => (
                                        <div className="single-post-detail-box" key={index}>
                                            <div className="content-of-single-post-detail-box">
                                                <h3>{item.title}</h3>
                                                <h4>{item.value}</h4>
                                            </div>
                                        </div>
                                    ))}

                                </div>
                            </div>
                        )}

                        <div className="right-icon-box-of-single-category-box">
                            <div className="content-of-right-icon-box-of-single-category-box">
                                <ArrowForwardIcon />
                            </div>
                        </div>

                    </div>
                </div>
            </Link>
            {/* </a> */}
        </div>
    )
}