import { Block } from '@blocknote/core'
import React from 'react'

export default function ImageBlock(props: Block) {

    const blockProps = props.props
    const content: any = props.content

    let caption: string = ""
    let url: string = ""

    if ("url" in blockProps){
        url = blockProps.url
    }
    if ("caption" in blockProps){
        caption = blockProps.caption
    }

    return <img src={url} alt={caption} />
}