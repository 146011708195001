import React from 'react'
import { useLocation, useParams } from 'react-router-dom'
import BaseStructuredPage from '../components/BaseStructuredPage'
import PostsApp from '../../apps/posts/PostsApp'
import PostsList from '../Posts/PostsList'

export default function Search() {

    const params = useParams()
    const search = params.search ? params.search : ""

    const postsApp = new PostsApp()

    return(
        <BaseStructuredPage>
            <div className="search-miraj-page-container">
                <div className="content-of-search-miraj-page-container content-section-wrapper">

                    <h1>Search Results:</h1>

                    <div className="posts-of-search-miraj-page-container">
                        <PostsList categorySlug="" search={search} allPosts={true} />
                    </div>

                </div>
            </div>
        </BaseStructuredPage>
    )
}