import React from 'react'
import { updateSopModal, updateSopModalOpenStatus, useReturnSopModalDetails } from './sopSlice'
import { useDispatch } from 'react-redux'

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

export default function SopModal() {

    const modal = useReturnSopModalDetails()
    const dispatch = useDispatch()

    const handleClose = () => {
        dispatch(updateSopModalOpenStatus(false))
        setTimeout(() =>   dispatch(updateSopModal({
          title: "",
          message: "",
          shown: false,
          buttons: []
      })), 1000)
    }

    const { shown, title, message, buttons } = modal

    return(
        <Dialog
            open={shown}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            maxWidth="md"
            fullWidth
      >
        <DialogTitle id="alert-dialog-title">
            {title}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {message}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
            {buttons.length > 0 ? buttons.map((button, index) => <Button key={index} onClick={() => button.closeModal ? handleClose() : button.onClick()}>{button.title}</Button>) : ""}
        </DialogActions>
      </Dialog>
    )
}