import { adminPanelRouts } from "../AdminPanel/RegisterAdminRouts";
import HomePage from "../Pages/Home/HomePage";
import NotFoundPage from "../Pages/NotFound/NotFoundPage";
import ProfilePage from "../Pages/Profile/ProfilePage";
import SignInPage from "../Pages/SignIn/SignInPage";
import SetPasswordPage from "../Pages/SetPassword/SetPasswordPage"
import RequestPasswordSetPage from "../Pages/SetPassword/RequestPasswordSetPage"
import LogoutPage from "../Pages/Logout/LogoutPage"
import RedirectToProfile from "../Pages/Profile/RedirectToProfile"
import UnblockAccountPage from "../Pages/SetPassword/UnblockAccountPage"
import BlockAccountPage from "../Pages/Profile/BlockAccountPage"
import SingleSectionPage from "../Pages/Posts/SingleSectionPage";
import SingleCategoryPage from "../Pages/Posts/SingleCategoryPage";
import SinglePostPage from "../Pages/Posts/SinglePostPage";

import { Pages } from "./Types";
import Search from "../Pages/Search/Search";
import SavePostAsPdf from "../Pages/Posts/SavePostAsPdf";
import ResetTwoFactorAuthPage from "../Pages/Profile/ResetTwoFactorAuthPage";

/**
 * Register Pages
 */
let registeredAdminPages: Pages = [
    {
        name: "home-page",
        title: "Home",
        path: "/",
        component: HomePage,
        isUserAuthorizedContent: true
    },
    {
        name: "sign-in-page",
        title: "Sign In",
        path: "/sign-in",
        component: SignInPage,
        isUserAuthorizedContent: false
    },
    {
        name: "profile-page",
        title: "Profile",
        path: "/profile/:section_name",
        component: ProfilePage,
        isUserAuthorizedContent: true
    },
    {
        name: "profile-page-redirect",
        title: "Profile",
        path: "/profile",
        component: RedirectToProfile,
        isUserAuthorizedContent: true
    },
    {
        name: "set-password-page",
        title: "Set Password",
        path: "/set-password/:token",
        component: SetPasswordPage,
        isUserAuthorizedContent: false
    },
    {
        name: "request-set-password-page",
        title: "Request Set Password",
        path: "/set-password",
        component: RequestPasswordSetPage,
        isUserAuthorizedContent: false
    },
    {
        name: "unblock-account",
        title: "Unblock Account",
        path: "/unblock-account",
        component: UnblockAccountPage,
        isUserAuthorizedContent: false
    },
    {
        name: "reset-password-page",
        title: "Reset Password",
        path: "/reset-password/:token",
        component: SetPasswordPage,
        isUserAuthorizedContent: false
    },
    {
        name: "request-reset-password-page",
        title: "Request Reset Password",
        path: "/reset-password",
        component: RequestPasswordSetPage,
        isUserAuthorizedContent: false
    },
    {
        name: "block-account-page",
        title: "Block Account",
        path: "/block-account",
        component: BlockAccountPage,
        isUserAuthorizedContent: false
    },
    {
        name: "logout",
        title: "Logout",
        path: "/logout",
        component: LogoutPage,
        isUserAuthorizedContent: false
    },
    {
        name: "single-section-page",
        title: "Section",
        path: "/posts/:section_slug",
        component: SingleSectionPage,
        isUserAuthorizedContent: true
    },
    {
        name: "single-category-page",
        title: "Category",
        path: "/posts/:section_slug/:category_slug",
        component: SingleCategoryPage,
        isUserAuthorizedContent: true
    },
    {
        name: "single-post-page",
        title: "Post",
        path: "/posts/:section_slug/:category_slug/:post_slug",
        component: SinglePostPage,
        isUserAuthorizedContent: true
    },
    {
        name: "save-post-as-pdf",
        title: "Post",
        path: "/save-as-pdf/posts/:post_slug",
        component: SavePostAsPdf,
        isUserAuthorizedContent: true
    },
    {
        name: "save-post-as-pdf",
        title: "Reset Two-Factor Authentication",
        path: "/reset-two-factor-auth",
        component: ResetTwoFactorAuthPage,
        isUserAuthorizedContent: true
    },
    {
        name: "search-page",
        title: "Search",
        path: "/search/:search",
        component: Search,
        isUserAuthorizedContent: true
    },
    {
        name: "page-not-found",
        title: "404",
        path: "*",
        component: NotFoundPage,
        isUserAuthorizedContent: false
    },
    ...adminPanelRouts
]

/**
 * Router Pages
 */
export const routerPages: Pages = registeredAdminPages