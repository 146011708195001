import { Button, Grid, IconButton } from "@mui/material"
import React, { useEffect, useState } from "react"
import GenerateIcon from "../../FormElements/GenerateIcon"
import Menu from "./Menu"
import User from "../../Api/User/User"
import { IsUserLoggedInResponse } from "../../Api/Interfaces"
import SearchBar from "./SearchBar"
import UserProfileAvatar from "./UserProfileAvatar"
import Application from "../../Application"
import ChangeThemeModeButton from "./ChangeThemeModeButton"



export default function Header(){

    const application = new Application()

    const renderLogo = () => <img src={application.returnAppLogo()} style={{ cursor: "pointer" }} onClick={() => window.location.replace("/")} />
    const [mobileMenu, setMobileMenu] = useState<boolean>(false)

    let mobileMenuClassName: string = "mobile-sidebar-container"

    if (mobileMenu){
        mobileMenuClassName += " active"
    }

    const user = new User()
    
    return(
        <header className="miraj-warehouse-header">
            <div className="content-of-miraj-warehouse-header content-section-wrapper">

                <Grid container spacing={2}>

                    <Grid item xs={2}>
                        <div className="logo-box-of-miraj-warehouse-header">
                            <div className="content-of-logo-box-of-miraj-warehouse-header">
                                {renderLogo()}
                            </div>
                        </div>
                    </Grid>

                    <Grid item xs={5} className="grid-item-header-menu-section">
                        <div className="menu-box-of-miraj-warehouse-header">
                            {/* <Menu /> */}
                        </div>

                        <div className="container-of-show-mobile-menu">
                            <div className="show-mobile-menu">
                                <div className="content-of-show-mobile-menu">
                                    <IconButton onClick={() => setMobileMenu(true)}>
                                        <GenerateIcon icon="menu" />
                                    </IconButton>
                                </div>
                            </div>
                        </div>
                    </Grid>

                    <Grid item xs={5} className="grid-item-header-search-bar">
                        <div className="miraj-header-search-bar-container">
                            <div className="content-of-miraj-header-search-bar-container">
                              
                                <div className="search-bar-header-form-elem-box">
                                    <SearchBar />
                                </div>

                                <div className="user-profile-avatar-box-miraj-header-container">
                                    <UserProfileAvatar />
                                </div>

                                {/* <div className="app-mode-miraj-header-container">
                                    <ChangeThemeModeButton />
                                </div> */}

                            </div>
                        </div>
                    </Grid>

                </Grid>

                <div className={mobileMenuClassName}>
                    <div className="content-of-mobile-sidebar-container">

                        <div className="mobile-header-container">
                            <div className="content-of-mobile-header-container">

                                <div className="logo-box-of-mobile-sidebar-container">
                                    <div className="content-of-logo-box-of-mobile-sidebar-container">
                                        {renderLogo()}
                                    </div>
                                </div>

                                <div className="show-mobile-menu">
                                    <div className="content-of-show-mobile-menu">
                                        <IconButton onClick={() => setMobileMenu(false)}>
                                            <GenerateIcon icon="close" />
                                        </IconButton>
                                    </div>
                                </div>

                            </div>
                        </div>

                        <div className="menu-of-mobile-sidebar-container">
                            <Menu />
                            <ChangeThemeModeButton />
                            <div className="mobile-header-search-box-box">
                                <SearchBar />
                            </div>
                            
                        </div>

                        

                    </div>
                </div>
                <div className={`mask-of-mobile-sidebar-container ${mobileMenu ? "active" : ""}`}></div>

            </div>
        </header>
    )
}