import React from 'react'
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';

interface Props {
    title?: string
}

export default function ScrollToTop(props: Props) {
    return(
        <div className="next-post-button-container" onClick={() =>  window.scrollTo({top: 0, behavior: 'smooth'})}>
           <div className="single-category-box">
               <div className="content-of-single-category-box">

                   <div className="icon-box-of-single-category-box">
                       <div className="content-of-icon-box-of-single-category-box">
                        <ArrowUpwardIcon />
                       </div>
                   </div>

                   <div className="title-box-of-single-category-box">
                       <div className="content-of-title-box-of-single-category-box">
                           <h3>{props.title ? props.title : "Go to the top"}</h3>
                       </div>
                   </div>

                   {/* <div className="right-icon-box-of-single-category-box">
                       <div className="content-of-right-icon-box-of-single-category-box">
                           <ArrowForwardIcon />
                       </div>
                   </div> */}

               </div>
           </div>
        </div>
    )
}