import React, { useEffect } from 'react'
import SectionsApp, { SingleSectionInterface, emptySectionDetails } from '../sections/SectionsApp'
import { addManySections, createOrUpdateSection, returnListOfSOPSections, updateCategoryLoadingStatus, updateEnableBoxListPreviewType, updateEnableSelectItemsStatus, updateItemsOrderStatus, updateSectionFormModalStatus, updateSectionLoadingStatus, updateSectionPagination, updateSopActiveSection, updateSopActiveSectionDetails, updateSopModal, useReturnSopActiveSection, useReturnSopActiveSectionDetails, useReturnSopCashedSectionDetails, useReturnSopSectionsLoadingStatus, useReturnSopSectionsPagination } from './components/sopSlice'
import { useDispatch } from 'react-redux'
import SopSectionsList from './components/SopSectionsList'
import LoadMoreButton from '../../Pages/Posts/LoadMoreButton'
import AddNewItemButton from './components/AddNewItemButton'
import SectionFormModal from './components/SectionFormModal'
import { returnSingleFormElementData } from '../../FormElements/formsDataSlice'
import SopModal from './components/SopModal'
import { IconButton } from '@mui/material'
import GenerateIcon from '../../FormElements/GenerateIcon'
import SopCategoriesList from './components/SopCategoriesList'
import CategoryFormModal from './components/CategoryFormModal'
import SopPostsList from './components/SopPostsList'
import PostEditorModal from './components/PostEditorModal'
import PostDetailsModal from './components/PostDetailsModal'


/**
 * Miraj SOP
 */

export default function MirajSOP() {

    const dispatch = useDispatch()


    const activeSopSection = useReturnSopActiveSection()
    const cashedSopSectionDetails = useReturnSopCashedSectionDetails()

    const baseContainerClassName: string = "sop-page-section-container"
    let containerClassName: string = baseContainerClassName

    const activeSopSectionDetails = useReturnSopActiveSectionDetails()

    interface SopActiveSectionDetails {
        name: string,
        component: () => void
    }

    const hideSelectItemsMenu = () => dispatch(updateEnableSelectItemsStatus(false))
    
    const adminPanelSopSectionsList: Array<SopActiveSectionDetails> = [
        {
            name: "miraj-sop",
            component: () => renderSopSectionContainer()
        },
        {
            name: "sop-section",
            component: () => renderSopCategorySection()
        },
        {
            name: "sop-posts",
            component: () => renderSopPostsSection()
        }
    ]
    


    /**
     * Sop section
     */
    const renderSopSectionContainer = () => {
        return(
            <>
                <div className="admin-panel-head-section">
                    <div className="content-of-admin-panel-head-section">
                        <h1>Miraj SOP</h1>
                    </div>
                </div>

                <div className="sections-list-container-of-miraj-sop-admin-page-container">
                    <SopSectionsList />
                </div>
            </>
        )
    }

    /**
     * Category section
     */
    const renderSopCategorySection = () => {

        const goBack = () => {
            dispatch(updateSectionLoadingStatus(true))
            dispatch(updateSopActiveSection("miraj-sop"))
            dispatch(updateSopActiveSectionDetails(emptySectionDetails))
            hideSelectItemsMenu()
        }

        return(
            <>
                <div className="admin-panel-head-section" onClick={() => goBack()}>
                    <div className="content-of-admin-panel-head-section">
                        <h1>
                            <IconButton style={{ marginRight: 10, marginTop: -5 }}><GenerateIcon icon="back" /></IconButton>
                            {activeSopSectionDetails.title}
                        </h1>
                    </div>
                </div>

                <div className="sop-admin-section-categories-list-container">
                    <SopCategoriesList />
                </div>
            </>
        )
    }

    /**
     * Posts section
     */
    const renderSopPostsSection = () => {

        const goBack = () => {
            dispatch(updateCategoryLoadingStatus(true))
            dispatch(updateSopActiveSectionDetails(cashedSopSectionDetails))
            dispatch(updateSopActiveSection("miraj-sop"))
            dispatch(updateSopActiveSection("sop-section"))
            dispatch(updateItemsOrderStatus(false))
            hideSelectItemsMenu()
        }

        return(
            <>
                <div className="admin-panel-head-section" onClick={() => goBack()}>
                    <div className="content-of-admin-panel-head-section">
                        <h1>
                            <IconButton style={{ marginRight: 10, marginTop: -5 }}><GenerateIcon icon="back" /></IconButton>
                            {activeSopSectionDetails.title}
                        </h1>
                    </div>
                </div>

                <div className="sop-admin-section-categories-list-container">
                    <SopPostsList />
                </div>
            </>
        )
    }

    /**
     * update enableBoxListPreviewType
     */
    useEffect(() => {
        if (activeSopSection == "miraj-sop"){
            dispatch(updateEnableBoxListPreviewType(true))
        }else{
            dispatch(updateEnableBoxListPreviewType(false))
        }
    }, [activeSopSection])

    /**
     * Render single admin sop section
     */
    const renderSingleSopAdminSection = (details: SopActiveSectionDetails) => {
        if (activeSopSection == details.name){
            containerClassName += " active"
        }else{
            containerClassName = baseContainerClassName
        }

        return(
            <div className={containerClassName}>
                <>{details.component()}</>
                <div style={{ height: 100 }}></div>
            </div>
        )
    }

    return(
        <div className="miraj-sop-admin-page-container">
            <div className="content-of-miraj-sop-admin-page-container">

                <SectionFormModal />
                <SopModal />
                <CategoryFormModal />
                <PostEditorModal />
                <PostDetailsModal />

                {adminPanelSopSectionsList.map((section: SopActiveSectionDetails) => renderSingleSopAdminSection(section))}

            </div>
        </div>
    )
}