import React, { useEffect, useState } from 'react'
import { ItemsDisplayerInterface } from './interfaces'
import { resetSelectedItems, updateEnableSelectItemsStatus, updateItemsOrderStatus, updateSopModal, useReturnEnableBoxListPreviewType, useReturnEnableSelectItem, useReturnSelectedItems, useReturnSopActiveSection, useReturnSopActiveSectionDetails, useReturnUpdateItemsOrderStatus } from '../sopSlice'
import { useDispatch } from 'react-redux'
import AddNewItemButton from '../AddNewItemButton'
import { Button, Skeleton } from '@mui/material'
import GenerateMessageBox from '../../../../FormElements/GenerateMessageBox'
import { updatePageMainLoadingStatus } from '../../../../PagePreloader/pagePreloaderSlice'
import { DragDropContext, DragStart, Draggable, DropResult, Droppable, ResponderProvided } from 'react-beautiful-dnd'
import { ItemDisplayerItemsType, ItemDisplayerMenuOptions, ItemDisplayerSelectedItemsMenuOptions, SingleItemDataDisplayerType } from './types'
import GenerateIcon from '../../../../FormElements/GenerateIcon'
import SingleItemDisplayer from './SingleItemDisplayer'
import LoadMoreButton from '../../../../Pages/Posts/LoadMoreButton'
import { updateSelectItems } from '../../../../FormElements/formsSlice'

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import SectionsApp, { SingleSectionInterface } from '../../../sections/SectionsApp'
import { SingleCategoryInterface } from '../../../categories/CategoriesApp'
import { LoadingButton } from '@mui/lab'

export default function ItemsDisplayer(props: ItemsDisplayerInterface) {

    const sectionsApp = new SectionsApp()

    const { elemPrefix, sopSectionName, items, pagination, loadingItemsStatus, noItemsMessage, loadAllItemsMessage, itemButtons, updateItemsLoadingStatus, listOfItems, resetItems, handleAddNew, updateItemsOrder, addManyItems, createOrUpdateItem, updateItemsPagination, handleEditItem, deleteInstance, deleteItem, onTitleClick, addNewItemText, optionsMenu, selectedItemsMenuOptions, deleteMany, moveManyItems } = props

    const isUpdateOrder = useReturnUpdateItemsOrderStatus()

    const baseListedItemsContainerClassName: string = "listed-items-container-miraj-sop"
    const [listedItemsContainerClassName, setListedItemsContainerClassName] = useState<string>()

    const dispatch = useDispatch()

    const enableSelectItems = useReturnEnableSelectItem()

    const setIsUpdateOrder = (status: boolean) => dispatch(updateItemsOrderStatus(status))

    const [lastSectionSlug, setLastSectionSlug] = useState<string>("")

    const [moveModalOpenStatus, setMoveModalOpenStatus] = useState<boolean>(false)

    const activeSopSectionDetails = useReturnSopActiveSectionDetails()

    const activeSopSectionName = useReturnSopActiveSection()

    const enablePreviewBoxType = useReturnEnableBoxListPreviewType()

    const selectedItemsList = useReturnSelectedItems()

    const [sectionsListForMove, setSectionsListForMove] = useState<Array<SingleSectionInterface>>([])
    const [loadingSectionsListForMove, setLoadingSectionsListForMove] = useState<boolean>(true)

    const [categoriesListForMove, setCategoriesListForMove] = useState<Array<SingleCategoryInterface>>([])
    const [loadingCategoriesListForMove, setLoadingCategoriesListForMove] = useState<boolean>(false)

    const [loadingMoveItemsStatus, setLoadingMoveItemsStatus] = useState<boolean>(false)

    /**
     * Update enable select items
     */
    const updateSelectItemsStatus = (status: boolean) => dispatch(updateEnableSelectItemsStatus(status))

    /**
     * Update loading status
     */
    const updateLoadingStatus = (status: boolean) => {
        dispatch(updateItemsLoadingStatus(status))
    }

    /**
     * Load items
     */
    const loadItems = (page: number = 1, search: string = "", loadMoreItems: boolean = false, loadAllItems: boolean = false) => {

        updateLoadingStatus(true)

        listOfItems(activeSopSectionDetails.slug, page, search, loadAllItems)
            .then(response => {

                const { status, data } = response

                switch(status){

                    case 200:

                        if (loadAllItems){
                            dispatch(resetItems([]))
                            dispatch(addManyItems(data))
                        }else{
                            if (lastSectionSlug == activeSopSectionDetails.slug){
                                if (!loadMoreItems){
                                    dispatch(addManyItems(data.results))
                                }else{

                                    if (data.results.length > 0){
                                        data.results.map((item: SingleItemDataDisplayerType) => {
                                            dispatch(createOrUpdateItem(item))
                                        })
                                    }

                                }
                            }else{
                                dispatch(resetItems([]))
                                dispatch(addManyItems(data.results))
                            }
                        }

                        dispatch(
                            updateItemsPagination({
                                count: data.count ? data.count : 0,
                                current_page: page,
                                total_pages: data.total_pages ? data.total_pages : 0
                            })
                        )

                        if (loadAllItems){
                            dispatch(
                                updateItemsPagination({
                                    count: 0,
                                    current_page: 1,
                                    total_pages: 1
                                })
                            )
                        }

                        break

                }

                updateLoadingStatus(false)

                setLastSectionSlug(activeSopSectionDetails.slug)

            })
            .catch(error => {
                const { response } = error
                const message = response.data! ? response.data.message! ? response.data.message : "" : response.statusText

                dispatch(updateSopModal({
                    shown: true,
                    title: "Error",
                    message: message,
                    buttons: [
                        {
                            title: "Ok",
                            onClick: () => {},
                            closeModal: true
                        }
                    ]
                }))

                updateLoadingStatus(false)
            })

            setLastSectionSlug(activeSopSectionDetails.slug)

    }

    useEffect(() => {
        if (activeSopSectionDetails.id && activeSopSectionName == sopSectionName){
            if (lastSectionSlug !== activeSopSectionName){
                console.log("load posts")
                loadItems(1, "", false, false)
            }
        }else{
            if (sopSectionName == "miraj-sop"){
                loadItems(1, "", false, false)
            }
        }
    }, [activeSopSectionDetails.id])

    useEffect(() => {
        if (sopSectionName == "miraj-sop"){
            loadItems(1, "", false, false)
        }
    }, [sopSectionName])

    useEffect(() => {
        if (activeSopSectionDetails.slug !== lastSectionSlug){
            dispatch(resetItems([]))
        }
    }, [activeSopSectionDetails.slug])

    const addNewButtonContainer = () => {

        return(
            <div className={isUpdateOrder ? "add-new-button-admin-panel-container disabled-content" : "add-new-button-admin-panel-container"}>
                <AddNewItemButton show={false} loading={loadingItemsStatus} title={addNewItemText ? addNewItemText : "Add new"} onClick={() => handleAddNew(dispatch, activeSopSectionDetails)} />
            </div>  
        )
    }

    /**
     * loadMoreItems
     */
    const loadMoreItems = () => {
        loadItems(pagination.current_page + 1, "", true, false)
    }

    const renderMoreItems = () => {
        if (loadingItemsStatus){
            return(
                <>
                    <Skeleton variant="rectangular" width="100%" height={120} style={{ marginBottom: 10 }} />
                    <Skeleton variant="rectangular" width="100%" height={120} style={{ marginBottom: 10 }} />
                    <Skeleton variant="rectangular" width="100%" height={120} style={{ marginBottom: 10 }} />
                    <Skeleton variant="rectangular" width="100%" height={120} style={{ marginBottom: 10 }} />
                    <Skeleton variant="rectangular" width="100%" height={120} style={{ marginBottom: 10 }} />
                </>
            )
        }
    }

    /**
     * Update main page loading status
     */
    const updatePageLoadingStatus = (status: boolean) => dispatch(updatePageMainLoadingStatus({ loading: status }))

    /**
     * Activate update order
     */
    const activateUpdateOrder = () => {
        if (isUpdateOrder){
            
            updatePageLoadingStatus(true)
            /**
             * Update
             */
            updateItemsOrder(items)
                .then(response => {

                    const { status } = response

                    if (status == 200){
                        setIsUpdateOrder(false)
                        setListedItemsContainerClassName(baseListedItemsContainerClassName)
                    }

                    updatePageLoadingStatus(false)

                })
                .catch(error => {
                    const { response } = error
                    const message = response.data! ? response.data.message! ? response.data.message : "" : response.statusText
    
                    dispatch(updateSopModal({
                        shown: true,
                        title: "Error",
                        message: message,
                        buttons: [
                            {
                                title: "Ok",
                                onClick: () => {},
                                closeModal: true
                            }
                        ]
                    }))

                    updatePageLoadingStatus(false)
                })

        }else{
            setListedItemsContainerClassName(`${baseListedItemsContainerClassName} draggable`)
            setIsUpdateOrder(true)
            // load all posts
            loadItems(1, "", false, true)
        }
    }

    /**
     * Drag and drop
     */
    const onBeforeCapture = () => {
        
    }
    
    const onBeforeDragStart = () => {
        
    }
    
    const onDragStart = (start: DragStart, provided: ResponderProvided) => {
        
    }
    
    const onDragUpdate = () => {
    
    }

    const reorder = (list: ItemDisplayerItemsType, startIndex: number, endIndex: number) => {
        const result = Array.from(list)
        const [removed] = result.splice(startIndex, 1)
        result.splice(endIndex, 0, removed)

        let reorderedItems: ItemDisplayerItemsType = []

        let orderNum: number = 1
        result.map((item: SingleItemDataDisplayerType) => reorderedItems.push({
            ...item,
            order_num: orderNum++
        }))

        return reorderedItems
    }

    const onDragEnd = (result: DropResult) => {
        if (!result.destination) return;

        const content = reorder(items, result.source.index, result.destination.index)
        dispatch(resetItems([]))
        dispatch(addManyItems(content))
    }

    const renderAllItems = () => {
        const render = () => items.map((item: SingleItemDataDisplayerType, index: number) => <SingleItemDisplayer item={item} buttons={itemButtons} elemPrefix={elemPrefix} key={index} handleEdit={() => handleEditItem(item, dispatch)} deleteInstance={deleteInstance} deleteItem={deleteItem} onTitleClick={onTitleClick}/> )
    
        if (!enableSelectItems){
            if (enablePreviewBoxType){
                return(
                    <div className="content-of-sections-list-container">
                        {render()}
                    </div>
                )
            }
        }

        return render()
    }

    if (items.length == 0){
        if (!loadingItemsStatus){
            return (
                <>
                    <GenerateMessageBox type="info" title={noItemsMessage.title} message={noItemsMessage.message} />
                    <div style={{ height: 15 }}></div>
                    {addNewButtonContainer()}
                </>
            )
        }
    }

    /**
     * Delete items from state
     */
    const deleteItemsFromState = () => {
        selectedItemsList.map((selectedItem) => {
            dispatch(deleteItem({ id: selectedItem.id }))
        })
    }

    /**
     * Handle delete many
     */
    const handleDeleteMany = () => {

        updatePageLoadingStatus(true)

        deleteMany(selectedItemsList)
            .then(response => {

                const { status } = response

                if (status == 200){
                    deleteItemsFromState()
                    toggleSelectMenu(false)
                }

                updatePageLoadingStatus(false)

            })
            .catch(error => {
                const { response } = error
                const message = response.data! ? response.data.message! ? response.data.message : "" : response.statusText

                updatePageLoadingStatus(false)

                dispatch(updateSopModal({
                    shown: true,
                    title: "Error",
                    message: message,
                    buttons: [
                        {
                            title: "Close",
                            onClick: () => {},
                            closeModal: true
                        }
                    ]
                }))

            })

    }

    /**
     * Render select items menu
     */
    const renderSelectedItemsMenu = () => {

        const renderButtonOption = (type: ItemDisplayerSelectedItemsMenuOptions, index: number) => {
            switch(type){

                case "move":
                    return(
                        <Button onClick={() => {
                            loadSectionsForMove()
                            setMoveModalOpenStatus(true)
                        }} startIcon={<GenerateIcon icon="move" />} disabled={selectedItemsList.length > 0 ? false : true}>
                            Move
                        </Button>
                    )

                case "delete":
                    return(
                        <Button onClick={() => handleDeleteMany()} startIcon={<GenerateIcon icon="delete" />} disabled={selectedItemsList.length > 0 ? false : true}>
                            Delete
                        </Button>
                    )

                default:
                    return <div key={index} style={{ display: "none" }}></div>
            }
        }

        return(
            <div className="selected-items-menu-container">
                <div className="content-of-selected-items-menu-container">

                    <div className="text-box-of-selected-items-menu-container">
                        <div className="content-of-text-box-of-selected-items-menu-container">
                            <h3>Selected: {selectedItemsList.length}</h3>
                        </div>
                    </div>

                    <div className="buttons-of-selected-items-menu-container">
                        <div className="content-of-buttons-of-selected-items-menu-container">

                            <Button startIcon={<GenerateIcon icon="close" />} onClick={() => toggleSelectMenu(false)}>
                                Cancel
                            </Button>

                            {selectedItemsMenuOptions && selectedItemsMenuOptions.length > 0 ? selectedItemsMenuOptions.map((option, index) => renderButtonOption(option.type, index)) : ""}

                        </div>
                    </div>  

                </div>
            </div>
        )
    }

    /**
     * toggle select menu
     */
    const toggleSelectMenu = (status: boolean) => {
        if (status){
            updateSelectItemsStatus(true)
            setListedItemsContainerClassName(`${baseListedItemsContainerClassName} selectable`)
        }else{
            updateSelectItemsStatus(false)
            setListedItemsContainerClassName(baseListedItemsContainerClassName)
            dispatch(resetSelectedItems([]))
        }
    }

    /**
     * Render items options menu
     */
    const renderItemsOptionMenu = () => {

        const renderSingleButton = (type: ItemDisplayerMenuOptions, index: number) => {
            switch(type){

                case "select":
                    return(
                        <Button disabled={isUpdateOrder} startIcon={<GenerateIcon icon="checkbox-checked" />} onClick={() => toggleSelectMenu(true)}>
                            Select
                        </Button>
                    )
                
                case "load-all":
                    return(
                        <Button disabled={isUpdateOrder} startIcon={<GenerateIcon icon="refresh" />} onClick={() => loadItems(1, "", false, true)}>
                            {loadAllItemsMessage}
                        </Button>
                    )

                case "update-order":
                    return(
                        <Button startIcon={<GenerateIcon icon={isUpdateOrder ? "success" : "list"} />} onClick={() => activateUpdateOrder()}>
                            {isUpdateOrder ? "Save order" : "Update order"}
                        </Button>
                    )

                default:
                    return <div key={index} style={{ display: "none" }}></div>
            }
        }

        return(
            <div className="side-menu-for-sop-items-container">
                <div className="content-of-side-menu-for-sop-items-container">
                    {optionsMenu.length > 0 ? optionsMenu.map((option, index) => renderSingleButton(option.type, index)) : ""}
                </div>
            </div>
        )
    }

    /**
     * Handle close move modal
     */
    const handleCloseMoveModal = () => {
        setMoveModalOpenStatus(false)
        setSectionsListForMove([])
        setCategoriesListForMove([])
    }

    /**
     * Load sections for move
     */
    const loadSectionsForMove = () => {
        

        setLoadingSectionsListForMove(true)

        sectionsApp.listOfSections("", 1, "", true)
            .then(response => {
                const { data } = response
                setSectionsListForMove(data.length > 0 ? data : [])
                setLoadingSectionsListForMove(false)
            })
            .catch(error => {
                const { response } = error
                const message = response.data! ? response.data.message! ? response.data.message : "" : response.statusText

                updatePageLoadingStatus(false)

                dispatch(updateSopModal({
                    shown: true,
                    title: "Error",
                    message: message,
                    buttons: [
                        {
                            title: "Close",
                            onClick: () => {},
                            closeModal: true
                        }
                    ]
                }))

                setLoadingSectionsListForMove(false)

            })
    }

    const loadCategoriesToMove = (sectionSlug: string) => {

        setLoadingCategoriesListForMove(true)
        setCategoriesListForMove([])


        sectionsApp.listOfCategories(sectionSlug, 1, "", true)
            .then(response => {
                const { data } = response
                console.log("data", data)
                setCategoriesListForMove(data.length > 0 ? data : [])
                setLoadingCategoriesListForMove(false)
            })
            .catch(error => {
                const { response } = error
                const message = response.data! ? response.data.message! ? response.data.message : "" : response.statusText

                updatePageLoadingStatus(false)

                dispatch(updateSopModal({
                    shown: true,
                    title: "Error",
                    message: message,
                    buttons: [
                        {
                            title: "Close",
                            onClick: () => {},
                            closeModal: true
                        }
                    ]
                }))

                setLoadingCategoriesListForMove(false)

            })

    }

    /**
     * Move categories
     */
    const renderMoveCategoriesModalContent = (forPost?: boolean) => {

        if (loadingSectionsListForMove){
            return(
                <>
                    <Skeleton variant="rectangular" width="100%" height={100} style={{ marginBottom: 10 }} />
                    <Skeleton variant="rectangular" width="100%" height={100} style={{ marginBottom: 10 }} />
                    <Skeleton variant="rectangular" width="100%" height={100} style={{ marginBottom: 10 }} />
                    <Skeleton variant="rectangular" width="100%" height={100} style={{ marginBottom: 10 }} />
                    <Skeleton variant="rectangular" width="100%" height={100} style={{ marginBottom: 10 }} />
                </>
            )
        }

        return(
            <div className="loaded-sections-list-for-move-modal">
                <div className="content-of-loaded-sections-list-for-move-modal">
                    {sectionsListForMove.map((section, index) => {
                        return(
                            <div className="single-displayed-item-for-move-modal" key={index}>
                                <div className="content-of-single-displayed-item-for-move-modal">

                                    <div className="title-box-of-single-displayed-item-for-move-modal">
                                        <div className="content-of-title-box-of-single-displayed-item-for-move-modal">
                                            <h3>{section.title}</h3>
                                        </div>
                                    </div>

                                    <div className="buttons-box-of-single-displayed-item-for-move-modal">
                                        <div className="content-of-buttons-box-of-single-displayed-item-for-move-modal">
                                            {forPost ? (
                                                <LoadingButton loading={loadingCategoriesListForMove} onClick={() => loadCategoriesToMove(section.slug)} startIcon={<GenerateIcon icon="folder" />}>Open</LoadingButton>
                                            ): (
                                                <LoadingButton onClick={() => handleMoveItems(section.id, 0)} startIcon={<GenerateIcon icon="move" />}>Move to</LoadingButton>
                                            )}
                                        </div>
                                    </div>

                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>
        )
    }

    /**
     * Handle move posts
     */
    const handleMoveItems = (section_id: number, category_id: number) => {

        if (moveManyItems){

            setLoadingMoveItemsStatus(true)

            moveManyItems(selectedItemsList, section_id, category_id)
                .then(response => {
                    toggleSelectMenu(false)
                    loadItems(1, "", false, true)
                    setLoadingMoveItemsStatus(false)
                    handleCloseMoveModal()
                })
                .catch(error => {
                    const { response } = error
                    const message = response.data! ? response.data.message! ? response.data.message : "" : response.statusText
    
                    updatePageLoadingStatus(false)
    
                    dispatch(updateSopModal({
                        shown: true,
                        title: "Error",
                        message: message,
                        buttons: [
                            {
                                title: "Close",
                                onClick: () => {},
                                closeModal: true
                            }
                        ]
                    }))
    
                    setLoadingMoveItemsStatus(false)
    
                })
        }
    }

    /**
     * Render categories
     */
    const renderMovePostsModalContent = () => {

        if (loadingCategoriesListForMove){
            return(
                <>
                    <Skeleton variant="rectangular" width="100%" height={100} style={{ marginBottom: 10 }} />
                    <Skeleton variant="rectangular" width="100%" height={100} style={{ marginBottom: 10 }} />
                    <Skeleton variant="rectangular" width="100%" height={100} style={{ marginBottom: 10 }} />
                    <Skeleton variant="rectangular" width="100%" height={100} style={{ marginBottom: 10 }} />
                    <Skeleton variant="rectangular" width="100%" height={100} style={{ marginBottom: 10 }} />
                </>
            )
        }

        return(
            <div className="loaded-sections-list-for-move-modal">
                <div className="content-of-loaded-sections-list-for-move-modal">
                    {categoriesListForMove.map((category, index) => {
                        return(
                            <div className="single-displayed-item-for-move-modal" key={index}>
                                <div className="content-of-single-displayed-item-for-move-modal">

                                    <div className="title-box-of-single-displayed-item-for-move-modal">
                                        <div className="content-of-title-box-of-single-displayed-item-for-move-modal">
                                            <h3>{category.title}</h3>
                                        </div>
                                    </div>

                                    <div className="buttons-box-of-single-displayed-item-for-move-modal">
                                        <div className="content-of-buttons-box-of-single-displayed-item-for-move-modal">
                                            <LoadingButton loading={loadingMoveItemsStatus} onClick={() => handleMoveItems(category.section_id, category.id)} startIcon={<GenerateIcon icon="move" />}>Move to</LoadingButton>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>
        )

    }

    /**
     * Render content for moal
     */
    const renderContentForMoveModal = () => {

        if (sopSectionName == "sop-posts"){

            if (categoriesListForMove.length > 0){
                return renderMovePostsModalContent()
            }

            return renderMoveCategoriesModalContent(true)
        }

        if (sopSectionName == "sop-section"){
            return renderMoveCategoriesModalContent()
        }

        return <></>
    }

    /**
     * Render move modal
     */
    const renderMoveModal = () => {

        return(
            <Dialog
                open={moveModalOpenStatus}
                onClose={handleCloseMoveModal}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                maxWidth="md"
                fullWidth
            >
                <DialogTitle id="alert-dialog-title">
                    Move
                </DialogTitle>

                <DialogContent>

                    {renderContentForMoveModal()}

                </DialogContent>

                <DialogActions>
                    {categoriesListForMove.length > 0 ? <Button onClick={() => {
                        setCategoriesListForMove([])
                        setLoadingCategoriesListForMove(false)
                    }}>Go Back</Button> : ""}
                    <Button onClick={() => handleCloseMoveModal()}>Close</Button>
                </DialogActions>
            </Dialog>
        )
    }

    return(
        <>
        {enableSelectItems ? renderSelectedItemsMenu() : renderItemsOptionMenu()}

        {renderMoveModal()}

        <div className="categories-list-of-the-section-container">
            <div className="content-of-categories-list-of-the-section-container">
                
                <div className={listedItemsContainerClassName}>
                    {isUpdateOrder ? (
                        <DragDropContext
                            onBeforeCapture={onBeforeCapture}
                            onBeforeDragStart={onBeforeDragStart}
                            onDragStart={onDragStart}
                            onDragUpdate={onDragUpdate}
                            onDragEnd={onDragEnd}
                         >
                            <Droppable droppableId="links">
                                {(provided) => <ul ref={provided.innerRef} {...provided.droppableProps}>{items.map((item: SingleItemDataDisplayerType, index: number) => <Draggable key={index} draggableId={index.toString()} index={index}>
                                    {(provided) => (
                                        <li {...provided.draggableProps} {...provided.dragHandleProps} ref={provided.innerRef}>
                                            <SingleItemDisplayer item={item} elemPrefix={elemPrefix} key={index} handleEdit={() => handleEditItem(item, dispatch)} buttons={itemButtons} deleteInstance={deleteInstance} deleteItem={deleteItem} onTitleClick={onTitleClick} ignoreBoxListPreviewTipe={true} />
                                        </li>
                                    )}
                                </Draggable>)}{provided.placeholder}</ul>}
                            </Droppable>
                        </DragDropContext>
                    ): renderAllItems()}
                </div>
                {renderMoreItems()}

                <div className={isUpdateOrder ? "load-more-items-button-container disabled-content" : "load-more-items-button-container"}>
                    <LoadMoreButton title="Load more" loading={loadingItemsStatus} onClick={loadMoreItems} show={pagination.total_pages !== pagination.current_page ? false : true} />
                </div>
                {addNewButtonContainer()}
            </div>
        </div>
        </>
    )
}