// import React, { useEffect, useState } from 'react'
// import { ISopDetailsSingleItem, addManySections, createOrUpdateSection, deleteSection, resetSections, returnListOfSOPSections, returnSopSectionsLoadingStatus, updateItemsOrderStatus, updatePostDetailsModal, updateSectionFormModalStatus, updateSectionLoadingStatus, updateSectionPagination, updateSopActiveSection, updateSopActiveSectionDetails, updateSopCashedSectionDetails, updateSopModal, updateSopModalOpenStatus, useReturnListOfSOPSections, useReturnSopSectionsLoadingStatus, useReturnSopSectionsPagination, useReturnUpdateItemsOrderStatus } from './sopSlice'
// import SectionsApp, { SingleSectionInterface } from '../../sections/SectionsApp'
// import { Button, IconButton, Skeleton } from '@mui/material'
// import LoadMoreButton from '../../../Pages/Posts/LoadMoreButton'
// import GenerateIcon from '../../../FormElements/GenerateIcon'
// import { useDispatch } from 'react-redux'
// import { createOrUpdateElementData } from '../../../FormElements/formsDataSlice'
// import GenerateMessageBox from '../../../FormElements/GenerateMessageBox'
// import DateAndTime from '../../../DateAndTime/DateAndTime'
// import AddNewItemButton from './AddNewItemButton'

// import { DragDropContext, Droppable, Draggable, DropResult, DragStart, ResponderProvided } from 'react-beautiful-dnd';
// import { updatePageMainLoadingStatus } from '../../../PagePreloader/pagePreloaderSlice'

// import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

// function SopSingleSectionBox(props: SingleSectionInterface){

//     const { title, thumbnail, id, date_created, created_by_name, last_updated_on, updated_by_name } = props

//     const dateAndTime = new DateAndTime()

//     const sectionsApp = new SectionsApp()

//     const dispatch = useDispatch()

//     const elemPrefix = "section_modal_form_elem_"

//     const updateElementData = (name: string, value: any) => dispatch(createOrUpdateElementData({ name: elemPrefix + name, value }))

//     const handleEdit = () => {
        // dispatch(updateSectionFormModalStatus(true))

        // updateElementData("section_id", id)
        // updateElementData("title", title)
        // updateElementData("thumbnail", thumbnail)
        // updateElementData("update", true)
//     }

//     const handleDelete = () => {
        
//         sectionsApp.deleteInstance(id)
//             .then(response => {

//                 const { status } = response

//                 switch(status){

//                     case 200:
//                         dispatch(deleteSection({id: id}))
//                         dispatch(updateSopModalOpenStatus(false))

//                 }

//             })
//             .catch(error => {
//                 const { response } = error
//                 const message = response.data! ? response.data.message! ? response.data.message : "" : response.statusText

//                 dispatch(updateSopModal({
//                     shown: true,
//                     title: "Error",
//                     message: message,
//                     buttons: [
//                         {
//                             title: "Close",
//                             onClick: () => {},
//                             closeModal: true
//                         }
//                     ]
//                 }))

//             })

//     }


//     const handleViewDetails = () => {

//         let items: Array<ISopDetailsSingleItem> = [
//             {
//                 title: "Created by:",
//                 value: created_by_name ? created_by_name : "-"
//             },
//             {
//                 title: "Created on:",
//                 value: date_created ? dateAndTime.formatDateAndTime(date_created) : "-"
//             },
//             {
//                 title: "Updated by:",
//                 value: updated_by_name ? updated_by_name : "-"
//             },
//             {
//                 title: "Last updated on:",
//                 value: last_updated_on ? dateAndTime.formatDateAndTime(last_updated_on) : "-"
//             }
//         ]

//         dispatch(updatePostDetailsModal({
//             open: true,
//             title: title,
//             items: items
//         }))

//     }

//     const askForDelete = () => dispatch(updateSopModal({
//         shown: true,
//         title: "Are you sure?",
//         message: "Do you really want to delete this section?",
//         buttons: [
//             {
//                 title: "Delete",
//                 onClick: () => handleDelete()
//             },
//             {
//                 title: "Cancel",
//                 onClick: () => {},
//                 closeModal: true
//             }
//         ]
//     }))

//     const switchSopSection = () => {
//         dispatch(updateSopActiveSectionDetails(props))
//         dispatch(updateSopCashedSectionDetails(props))
//         dispatch(updateSopActiveSection("sop-section"))
//         window.scrollTo({top: 0, behavior: 'smooth'})
//     }

    // return(
    //     <div className="wrapper-of-single-section-box">
    //             <div className="single-section-box">
    //                 <div className="content-of-single-section-box">

    //                     <div className="mask-text-of-single-section-box">
    //                         <div className="content-of-mask-text-of-single-section-box">

    //                             <div className="buttons-box-of-mask-text-of-single-section-box">
    //                                 <div className="content-of-buttons-box-of-mask-text-of-single-section-box">
                                        
    //                                     <IconButton onClick={handleEdit}>
    //                                         <GenerateIcon icon="edit" />
    //                                     </IconButton>

    //                                     <IconButton onClick={handleViewDetails}>
    //                                         <GenerateIcon icon="view" />
    //                                     </IconButton>

    //                                     <IconButton onClick={() => askForDelete()}>
    //                                         <GenerateIcon icon="delete" />
    //                                     </IconButton>

    //                                 </div>
    //                             </div>

    //                             <div className="title-box-of-single-section-box" onClick={() => switchSopSection()}>
    //                                 <div className="content-of-title-box-of-single-section-box">
    //                                     <div className="icon-of-title-box-of-single-section-box"><GenerateIcon icon="view" /></div>
    //                                     <h3>{title}</h3>
    //                                 </div>
    //                             </div>

    //                         </div>
    //                     </div>

    //                     <div className="thumbnail-box-of-single-section-box">
    //                         <div className="thumb-of-single-section-box" style={{ backgroundImage: `url(${thumbnail})` }}></div>
    //                     </div>

    //                 </div>
    //             </div>
    //     </div>
    // )
// }

// function SingleSectionPostBoxStyle(props: SingleSectionInterface){

//     const { id, title, slug, thumbnail, created_by, created_by_name, updated_by, last_updated_on, date_created, updated_by_name } = props


//     return(
//         <div className="wrapper-of-single-category-box">
//                 <div className="single-category-box">
//                     <div className="content-of-single-category-box">

//                         <div className="icon-box-of-single-category-box">
//                             <div className="content-of-icon-box-of-single-category-box">
//                                 <ArrowForwardIcon />
//                             </div>
//                         </div>

//                         <div className="title-box-of-single-category-box">
//                             <div className="content-of-title-box-of-single-category-box">
//                                 <h3>{title}</h3>
//                             </div>
//                         </div>

//                         <div className="right-icon-box-of-single-category-box">
//                             <div className="content-of-right-icon-box-of-single-category-box buttons-type-container">
//                             </div>
//                         </div>

//                     </div>
//                 </div>
//         </div>
//     )
// }

// export default function SopSectionsList() {

//     const dispatch = useDispatch()

//     const sectionsApp = new SectionsApp()

//     const loadingSections = useReturnSopSectionsLoadingStatus()
//     const sectionsPagination = useReturnSopSectionsPagination()

//     const loading = useReturnSopSectionsLoadingStatus()
//     const sections = useReturnListOfSOPSections()

//     const isUpdateOrder = useReturnUpdateItemsOrderStatus()

//     const baseListedItemsContainerClassName: string = "listed-items-container-miraj-sop"
//     const [listedItemsContainerClassName, setListedItemsContainerClassName] = useState<string>()
//     const setIsUpdateOrder = (status: boolean) => dispatch(updateItemsOrderStatus(status))

//     const resetSection = () => dispatch(resetSections([]))

//     /**
//      * Update section loading status
//      */
//     const updateSopSectionLoadingStatus = (status: boolean) => dispatch(updateSectionLoadingStatus(status))

//     /**
//      * Load sections
//      */
//     const loadSections = (page: number = 1, search: string = "", loadMoreItems: boolean = false, loadAllItems: boolean = false) => {
//         updateSopSectionLoadingStatus(true)

//         sectionsApp.listOfInstances(page, search, loadAllItems)
//             .then(response => {

//                 const { status, data } = response

//                 switch(status){

//                     case 200:

//                         if (loadAllItems){
//                             resetSection()
//                             dispatch(addManySections(data))
//                         }else{
//                             if (!loadMoreItems){
//                                 resetSection()
//                                 dispatch(addManySections(data.results))
//                             }else{

//                                 if (data.results.length > 0){
//                                     resetSection()
//                                     data.results.map((item: SingleSectionInterface) => {
//                                         dispatch(createOrUpdateSection(item))
//                                     })
//                                 }

//                             }
//                         }

//                         dispatch(
//                             updateSectionPagination({
//                                 count: data.count ? data.count : 0,
//                                 current_page: page,
//                                 total_pages: data.total_pages ? data.total_pages : 0
//                             })
//                         )

//                         if (loadAllItems){
//                             dispatch(
//                                 updateSectionPagination({
//                                     count: 0,
//                                     current_page: 1,
//                                     total_pages: 1
//                                 })
//                             )
//                         }
//                         break

//                 }

//                 updateSopSectionLoadingStatus(false)

//             })
//             .catch(error => {
//                 const { response } = error
//                 const message = response.data! ? response.data.message! ? response.data.message : "" : response.statusText

//                 dispatch(updateSopModal({
//                     shown: true,
//                     title: "Error",
//                     message: message,
//                     buttons: [
//                         {
//                             title: "Ok",
//                             onClick: () => {},
//                             closeModal: true
//                         }
//                     ]
//                 }))
//             })

//     }


//     /**
//      * Load sections to redux
//      */
//     useEffect(() => {
//         loadSections()
//     }, [])

//     /**
//      * loadMoreItems
//      */
//     const loadMoreItems = () => {
//         loadSections(sectionsPagination.current_page + 1, "", true)
//     }

//     /**
//          * Drag and drop
//          */
//     const onBeforeCapture = () => {
            
//     }
    
//     const onBeforeDragStart = () => {
        
//     }
    
//     const onDragStart = (start: DragStart, provided: ResponderProvided) => {
        
//     }
    
//     const onDragUpdate = () => {
    
//     }

//     const reorder = (list: Array<SingleSectionInterface>, startIndex: number, endIndex: number) => {
//         const result = Array.from(list)
//         const [removed] = result.splice(startIndex, 1)
//         result.splice(endIndex, 0, removed)

//         let reorderedItems: Array<SingleSectionInterface> = []

//         let orderNum: number = 1
//         result.map((item: SingleSectionInterface) => reorderedItems.push({
//             ...item,
//             order_num: orderNum++
//         }))

//         return reorderedItems
//     }

//     const onDragEnd = (result: DropResult) => {
//         if (!result.destination) return;

//         const content = reorder(sections, result.source.index, result.destination.index)
//         resetSection()
//         dispatch(addManySections(content))

//     }

//     const updatePageLoadingStatus = (status: boolean) => dispatch(updatePageMainLoadingStatus({ loading: status }))

//     const activateUpdateOrder = () => {
//         if (isUpdateOrder){
            
//             updatePageLoadingStatus(true)
//             /**
//              * Update
//              */
//             sectionsApp.updateSectionsOrder(sections)
//                 .then(response => {

//                     const { status } = response

//                     if (status == 200){
//                         setIsUpdateOrder(false)
//                         setListedItemsContainerClassName(baseListedItemsContainerClassName)
//                     }

//                     updatePageLoadingStatus(false)

//                 })
//                 .catch(error => {
//                     const { response } = error
//                     const message = response.data! ? response.data.message! ? response.data.message : "" : response.statusText
    
//                     dispatch(updateSopModal({
//                         shown: true,
//                         title: "Error",
//                         message: message,
//                         buttons: [
//                             {
//                                 title: "Ok",
//                                 onClick: () => {},
//                                 closeModal: true
//                             }
//                         ]
//                     }))

//                     updatePageLoadingStatus(false)
//                 })

//         }else{
//             setListedItemsContainerClassName(`${baseListedItemsContainerClassName} draggable`)
//             setIsUpdateOrder(true)
//             // load all posts
//             loadSections(1, "", false, true)
//         }
//     }

//     if (loading){
//         return(
//             <div style={{ display: "flex", flexWrap: "wrap", gap: 10 }}>
//                 <Skeleton variant="rectangular" width="45%" height={500} />
//                 <Skeleton variant="rectangular" width="45%" height={500} />
//                 <Skeleton variant="rectangular" width="45%" height={500} />
//                 <Skeleton variant="rectangular" width="45%" height={500} />
//             </div>
//         )
//     }

//     if (sections.length == 0){
//         return <GenerateMessageBox type="info" title="Nothing..." message="You haven't added any section yet..." />
//     }

    

//     return(
//         <div>
//             <div className="side-menu-for-sop-items-container">
//                 <div className="content-of-side-menu-for-sop-items-container">

//                     <Button disabled={isUpdateOrder} startIcon={<GenerateIcon icon="refresh" />} onClick={() => loadSections(1, "", false, true)}>
//                         Load all sections
//                     </Button>

//                     <Button startIcon={<GenerateIcon icon={isUpdateOrder ? "success" : "list"} />} onClick={() => activateUpdateOrder()}>
//                         {isUpdateOrder ? "Save order" : "Update order"}
//                     </Button>

//                 </div>
//             </div>
//             <div className={listedItemsContainerClassName}>
//                     {isUpdateOrder ? (
//                         <DragDropContext
//                             onBeforeCapture={onBeforeCapture}
//                             onBeforeDragStart={onBeforeDragStart}
//                             onDragStart={onDragStart}
//                             onDragUpdate={onDragUpdate}
//                             onDragEnd={onDragEnd}
//                          >
//                             <Droppable droppableId="links">
//                                 {(provided) => <ul ref={provided.innerRef} {...provided.droppableProps}>{sections.map((section, index: number) =>  <Draggable key={index} draggableId={index.toString()} index={index}>
//                                     {(provided) => (
//                                         <li {...provided.draggableProps} {...provided.dragHandleProps} ref={provided.innerRef}>
//                                             <SingleSectionPostBoxStyle {...section} key={index} />
//                                         </li>
//                                     )}
//                                 </Draggable>)}{provided.placeholder}</ul>}
//                             </Droppable>
//                         </DragDropContext>
//                     ): (
//                         <div className="content-of-sections-list-container">
//                             {sections.map((section, index: number) => <SopSingleSectionBox {...section} key={index} />)}
//                         </div>
//                     )}
//                 </div>

//             <div className={isUpdateOrder ? "load-more-items-button-container content-section-wrapper content-disabled" : "load-more-items-button-container content-section-wrapper"}>
//                 <LoadMoreButton title="Load more" loading={loadingSections} onClick={loadMoreItems} show={sectionsPagination.total_pages !== sectionsPagination.current_page ? false : true} />
//             </div>

//             <div className={isUpdateOrder ? "add-new-button-admin-panel-container content-disabled" : "add-new-button-admin-panel-container"}>
//                 <AddNewItemButton show={false} loading={loadingSections} title="Add new section" onClick={() => dispatch(updateSectionFormModalStatus(true))} />
//             </div>  
//         </div>
//     )
// }

import React, { Dispatch, useEffect, useState } from 'react'
import PostsApp, { SinglePostInterface } from '../../posts/PostsApp'
import { ISopDetailsSingleItem, addManyCategories, addManyPosts, addManySections, createOrUpdateCategory, createOrUpdatePost, createOrUpdateSection, deleteCategory, deletePost, deleteSection, resetCategories, resetPosts, resetSections, updateCategoryFormModalStatus, updateCategoryLoadingStatus, updateCategoryPagination, updateItemsOrderStatus, updatePostDetailsModal, updatePostLoadingStatus, updatePostsFormModalStatus, updatePostsPagination, updateSectionFormModalStatus, updateSectionLoadingStatus, updateSectionPagination, updateSopActiveSection, updateSopActiveSectionDetails, updateSopCashedSectionDetails, updateSopModal, updateSopModalOpenStatus, useReturnCategoriesLoadingStatus, useReturnCategoriesPagination, useReturnListOfCategories, useReturnListOfPOsts, useReturnListOfSOPSections, useReturnPostPagination, useReturnPostsLoadingStatus, useReturnSopActiveSection, useReturnSopActiveSectionDetails, useReturnSopSectionsLoadingStatus, useReturnSopSectionsPagination, useReturnUpdateItemsOrderStatus } from './sopSlice'
import CategoriesApp, { SingleCategoryInterface } from '../../categories/CategoriesApp'
import { createOrUpdateElementData } from '../../../FormElements/formsDataSlice'
import ItemsDisplayer from './ItemsDisplayer/ItemsDisplayer'
import { ItemsDisplayerInterface } from './ItemsDisplayer/interfaces'
import { ItemDisplayerItemsType, SingleItemDataDisplayerType } from './ItemsDisplayer/types'
import { AnyAction } from '@reduxjs/toolkit'
import SectionsApp, { SingleSectionInterface } from '../../sections/SectionsApp'

export default function SopSectionsList() {

    const sectionsApp = new SectionsApp()

    const elemPrefix = "section_modal_form_elem_"

    const handleEditItem = (item: SingleItemDataDisplayerType, dispatch: Dispatch<AnyAction>) => {
        
        const updateElementData = (name: string, value: any) => dispatch(createOrUpdateElementData({ name: elemPrefix + name, value }))

        const post = (item as SinglePostInterface)
        const { id, title, body, thumbnail, section_slug, category_slug, section_id, category_id, slug } = post

        dispatch(updateSectionFormModalStatus(true))

        updateElementData("section_id", id)
        updateElementData("title", title)
        updateElementData("thumbnail", thumbnail)
        updateElementData("update", true)

    }

    const config: ItemsDisplayerInterface = {
        sopSectionName: "miraj-sop",
        elemPrefix: elemPrefix,
        items: useReturnListOfSOPSections(),
        pagination: useReturnSopSectionsPagination(),
        loadingItemsStatus: useReturnSopSectionsLoadingStatus(),
        noItemsMessage: {
            title: "Nothing found...",
            message: "No sections have been found :("
        },
        addNewItemText: "Add a new section",
        loadAllItemsMessage: "Load all sections",
        optionsMenu: [
            {
                type: "select"
            },
            {
                type: "load-all"
            },
            {
                type: "update-order"
            }
        ],
        selectedItemsMenuOptions: [

            {
                type: "delete"
            }
        ],
        listOfItems: sectionsApp.listOfSections,
        updateItemsLoadingStatus: updateSectionLoadingStatus,
        resetItems: (payload) => resetSections([]),
        handleAddNew: (dispatch) => {
            dispatch(updateSectionFormModalStatus(true))
        },
        updateItemsOrder: (items: ItemDisplayerItemsType) => {
            return sectionsApp.updateSectionsOrder((items as Array<SingleSectionInterface>))
        },
        addManyItems: (payload) => addManySections((payload as Array<SingleSectionInterface>)),
        createOrUpdateItem: (payload) => createOrUpdateSection((payload as SingleSectionInterface)),
        updateItemsPagination: updateSectionPagination,
        handleEditItem: handleEditItem,
        deleteInstance: sectionsApp.deleteInstance,
        deleteItem: deleteSection,
        itemButtons: [
            {
                type: "view",
            },
            {
                type: "edit",
            },
            {
                type: "delete",
            }
        ],
        
        onTitleClick: (item, dispatch) => {
            dispatch(updateCategoryLoadingStatus(true))
            dispatch(updateSopActiveSectionDetails(item))
            dispatch(updateSopCashedSectionDetails(item))
            dispatch(updateSopActiveSection("sop-section"))
            window.scrollTo({top: 0, behavior: 'smooth'})
        },
        deleteMany: (records) => {
            return sectionsApp.deleteManySections((records as Array<any>))
        }
    }

    return <ItemsDisplayer {...config} />
}