import { Block, DefaultBlockSchema } from '@blocknote/core'
import React, {CSSProperties} from 'react'
import { IBlockEditorStyles, returnStyles } from '../BlockEditor'

export default function HeadingBlock(props: Block) {

    const blockProps = props.props
    const content: any = props.content

    const level = (blockProps as any).level

    const styles = (content[0].styles as IBlockEditorStyles)

    // console.log("styles", styles)
    // console.log("block", props)


    switch(level){

        case 1:
            return <h1 className="bn-inline-content" style={returnStyles(styles, blockProps)}>{content[0].text}</h1>

        case 2:
            return <h2 className="bn-inline-content" style={returnStyles(styles, blockProps)}>{content[0].text}</h2>

        case 3:
            return <h3 className="bn-inline-content" style={returnStyles(styles, blockProps)}>{content[0].text}</h3>

    }

    return <></>

}