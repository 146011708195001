import React from 'react'
import { SingleSectionInterface } from '../../apps/sections/SectionsApp'
import GenerateIcon from '../../FormElements/GenerateIcon'
import Application from '../../Application'

interface Props extends SingleSectionInterface{

}

export default function SingleSectionBox(props: Props) {

    const { title, slug, thumbnail } = props

    const application = new Application()

    return(
        <div className="wrapper-of-single-section-box">
            <a href={`/posts/${slug}`}>
                <div className="single-section-box">
                    <div className="content-of-single-section-box">

                        <div className="mask-text-of-single-section-box">
                            <div className="content-of-mask-text-of-single-section-box">
                                    
                                <div className="title-box-of-single-section-box">
                                    <div className="content-of-title-box-of-single-section-box">
                                        <div className="icon-of-title-box-of-single-section-box"><GenerateIcon icon="view" /></div>
                                        <h3>{title}</h3>
                                    </div>
                                </div>

                            </div>
                        </div>

                        <div className="thumbnail-box-of-single-section-box">
                            <div className="thumb-of-single-section-box" style={{ backgroundImage: `url(${thumbnail ? thumbnail : application.returnNoThumbAsImage()})` }}></div>
                        </div>

                    </div>
                </div>
            </a>
        </div>
    )
}