import { Block } from '@blocknote/core'
import React from 'react'
import PostsApp from '../../../apps/posts/PostsApp'

export default function VideoBlock(props: Block) {

    const postsApp = new PostsApp()

    const blockProps = props.props
    const content: any = props.content

    let name: string = ""
    let url: string = ""

    if ("url" in blockProps){
        url = blockProps.url
    }
    if ("name" in blockProps){
        name = blockProps.name
    }

    return(
        <div className="video-iframe-block">
            <iframe src={postsApp.returnVimeoEmbededVideoLink(url)} allowFullScreen allow="autoplay; fullscreen; picture-in-picture; clipboard-write" ></iframe>
        </div>
    )
}