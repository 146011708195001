import { LabelImportant } from '@mui/icons-material'
import { Button } from '@mui/material'
import React from 'react'
import GenerateIcon from '../../FormElements/GenerateIcon'
import { ErrorPageButton, ErrorPageDetails } from './Interfaces'

export default function ErrorPage(props: ErrorPageDetails) {

    const { title, message, status, buttons } = props

    /**
     * Single button
     */
    const singleButton = (button: ErrorPageButton, index: number) => {
        const { label, link, variant } = button

        return(
            <Button variant={variant} key={index} href={link ? link : ""} onClick={() => { button.onClick ? button.onClick() : console.log("clicked") }}>
                {label}
            </Button>
        )
    }

    /**
     * Render buttons
     */
    const renderButtons = () => {
        if (buttons && buttons.length > 0){
            return buttons.map((button: ErrorPageButton, index: number) => singleButton(button, index))
        }else{
            return <React.Fragment></React.Fragment>
        }
    }

    return(
        <div className="block-account-page-container error">
            <div className="content-of-block-account-page-container">

                <div className={"info-box-of-block-account-page-container error"}>
                        <div className="content-of-info-box-of-block-account-page-container">

                            <div className="icon-box-of-info-box-of-block-account-page-container">
                                <div className="content-of-icon-box-of-info-box-of-block-account-page-container">
                                    <GenerateIcon icon="error" />
                                </div>
                            </div>

                            <div className="text-box-of-info-box-of-block-account-page-container">
                                <div className="content-of-text-box-of-info-box-of-block-account-page-container">
                                    <h2>{title}</h2>
                                    <p>{message}</p>
                                </div>
                            </div>

                            <div className="buttons-of-error-page-container">
                                <div className="content-of-buttons-of-error-page-container">
                                    {renderButtons()}
                                </div>
                            </div>

                        </div>
                </div>

            </div>
        </div>
    )

    // return(
    //     <div className="error-page-container">
    //         <div className="content-of-error-page-container">

    //             <div className="icon-box-of-error-page-container">
    //                 <div className="content-of-icon-box-of-error-page-container">
    //                     <GenerateIcon icon="error" />
    //                 </div>
    //             </div>

    //             <div className="text-box-of-error-page-container">
    //                 <div className="content-of-text-box-of-error-page-container">
    //                     <h1>{title}</h1>
    //                     <p>{message}</p>
    //                 </div>
    //             </div>

    //             <div className="buttons-of-error-page-container">
    //                 <div className="content-of-buttons-of-error-page-container">
    //                     {renderButtons()}
    //                 </div>
    //             </div>

    //         </div>
    //     </div>
    // )
}