import React, { Dispatch, useEffect, useState } from 'react'
import { ISingleItemDisplayer, ISingleItemDisplayerButton } from './interfaces'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { IconButton } from '@mui/material';
import GenerateIcon from '../../../../FormElements/GenerateIcon';
import { useDispatch } from 'react-redux';
import DateAndTime from '../../../../DateAndTime/DateAndTime';
import { SingleItemDataDisplayerType } from './types';
import { AnyAction } from '@reduxjs/toolkit';
import { ISopDetailsSingleItem, createOrUpdateSelectedItem, deleteSelectedItem, updatePostDetailsModal, updateSopModal, updateSopModalOpenStatus, useReturnEnableBoxListPreviewType, useReturnEnableSelectItem, useReturnSelectedItems } from '../sopSlice';
import { AxiosResponse } from 'axios';
import Application from '../../../../Application';

interface ButtonProps extends ISingleItemDisplayerButton {
    item: SingleItemDataDisplayerType,
    handleEdit: (item: SingleItemDataDisplayerType, dispatch: Dispatch<AnyAction>) => void,
    elemPrefix: string,
    deleteInstance: (id: number) => Promise<AxiosResponse<any, any>>,
    deleteItem: (payload: { id: number }) => AnyAction,
}

export function ButtonSingleItemDisplayer(props: ButtonProps){

    const dispatch = useDispatch()
    const dateAndTime = new DateAndTime()

    const { item, type, custom, handleEdit, elemPrefix, deleteInstance, deleteItem } = props

    const { id, title, created_by_name, date_created, updated_by_name, last_updated_on } = item

    const enableSelectItems = useReturnEnableSelectItem()

    
    const handleViewDetails = () => {

        let items: Array<ISopDetailsSingleItem> = [
            {
                title: "Created by:",
                value: created_by_name ? created_by_name : "-"
            },
            {
                title: "Created on:",
                value: date_created ? dateAndTime.formatDateAndTime(date_created) : "-"
            },
            {
                title: "Updated by:",
                value: updated_by_name ? updated_by_name : "-"
            },
            {
                title: "Last updated on:",
                value: last_updated_on ? dateAndTime.formatDateAndTime(last_updated_on) : "-"
            }
        ]

        dispatch(updatePostDetailsModal({
            open: true,
            title: title,
            items: items
        }))

    }

    const handleDelete = () => {
        
       deleteInstance(id)
            .then(response => {

                const { status } = response

                switch(status){

                    case 200:
                        dispatch(deleteItem({id: id}))
                        dispatch(updateSopModalOpenStatus(false))

                }

            })
            .catch(error => {
                const { response } = error
                const message = response.data! ? response.data.message! ? response.data.message : "" : response.statusText

                dispatch(updateSopModal({
                    shown: true,
                    title: "Error",
                    message: message,
                    buttons: [
                        {
                            title: "Close",
                            onClick: () => {},
                            closeModal: true
                        }
                    ]
                }))

            })

    }

    const askForDelete = () => dispatch(updateSopModal({
        shown: true,
        title: "Are you sure?",
        message: `Do you really want to delete "${title}"?`,
        buttons: [
            {
                title: "Delete",
                onClick: () => handleDelete()
            },
            {
                title: "Cancel",
                onClick: () => {},
                closeModal: true
            }
        ]
    }))

    switch(type){

        case "edit":
            return(
                <IconButton onClick={() => handleEdit(item, dispatch)}>
                    <GenerateIcon icon="edit" />
                </IconButton>
            )

        case "view":
            return(
                <IconButton onClick={() => handleViewDetails()}>
                    <GenerateIcon icon="view" />
                </IconButton>
            )

        case "delete":
            return(
                <IconButton onClick={() => askForDelete()}>
                    <GenerateIcon icon="delete" />
                </IconButton>
            )

        case "custom":
            if (custom){
                return <>{custom(item, elemPrefix, dispatch)}</>
            }
            return <></>

        default:
            return <></>
    }
}

export default function SingleItemDisplayer(props: ISingleItemDisplayer) {

    const application = new Application()

    const dispatch = useDispatch()
    

    const { item, buttons, onTitleClick, elemPrefix, handleEdit, deleteInstance, deleteItem, ignoreBoxListPreviewTipe } = props

    const { id, title, slug, thumbnail, created_by_name, updated_by_name, last_updated_on, date_created } = item

    const selectedItems = useReturnSelectedItems()

    const switchSopSection = () => {
        if (onTitleClick){
            onTitleClick(item, dispatch)
        }
    }

    const enableBoxListPreviewType = useReturnEnableBoxListPreviewType()
    const enableSelectItems = useReturnEnableSelectItem()

    const boxTypePreviewBox = ignoreBoxListPreviewTipe || enableSelectItems ? false : enableBoxListPreviewType

    /**
     * Wrapper class
     */
    const baseWrapperClassName: string = "wrapper-of-single-category-box"
    const [itemWrapperContainerClassName, setItemWrapperContainerClassName] = useState<string>(baseWrapperClassName)

    /**
     * Item selected
     */
    const isItemSelected = () => {
        let items = selectedItems
            
        if (items.some(e => e.id === id)){
            return true
        }
    
        return false
    }

    /**
     * Handle on click item
     */
    const handleOnClickItem = () => {
        if (enableSelectItems){
            if (isItemSelected()){
                dispatch(deleteSelectedItem({ id: id }))
            }else{
                dispatch(createOrUpdateSelectedItem(item))
            }
        }
    }

    useEffect(() => {
        if (enableSelectItems){
            if (isItemSelected()){
                setItemWrapperContainerClassName(`${baseWrapperClassName} selected`)
            }else{
                setItemWrapperContainerClassName(baseWrapperClassName)
            }
        }else{
            setItemWrapperContainerClassName(baseWrapperClassName)
        }
    }, [isItemSelected()])

    /**
     * Box type
     */
    if (boxTypePreviewBox){
        return(
            <div className="wrapper-of-single-section-box">
                    <div className="single-section-box">
                        <div className="content-of-single-section-box">
    
                            <div className="mask-text-of-single-section-box">
                                <div className="content-of-mask-text-of-single-section-box">
    
                                    <div className="buttons-box-of-mask-text-of-single-section-box">
                                        <div className="content-of-buttons-box-of-mask-text-of-single-section-box">
                                            
                                          {buttons && buttons.length > 0 ? buttons.map((button, index) => <ButtonSingleItemDisplayer elemPrefix={elemPrefix} handleEdit={handleEdit} item={item} {...button} key={index} deleteInstance={deleteInstance} deleteItem={deleteItem} />) : ""}

                                        </div>
                                    </div>
    
                                    <div className="title-box-of-single-section-box" onClick={() => switchSopSection()}>
                                        <div className="content-of-title-box-of-single-section-box">
                                            <div className="icon-of-title-box-of-single-section-box"><GenerateIcon icon="view" /></div>
                                            <h3>{title}</h3>
                                        </div>
                                    </div>
    
                                </div>
                            </div>
    
                            <div className="thumbnail-box-of-single-section-box">
                                <div className="thumb-of-single-section-box" style={{ backgroundImage: `url(${thumbnail ? thumbnail : application.returnNoThumbAsImage()})` }}></div>
                            </div>
    
                        </div>
                    </div>
            </div>
        )
    }

    /**
     * List type bpx
     */

    return(
        <div className={itemWrapperContainerClassName} onClick={() => handleOnClickItem()}>
                <div className="single-category-box">
                    <div className="content-of-single-category-box">

                        <div className="icon-box-of-single-category-box" onClick={() => switchSopSection()}>
                            <div className="content-of-icon-box-of-single-category-box">
                                <ArrowForwardIcon />
                            </div>
                        </div>

                        <div className="title-box-of-single-category-box" onClick={() => switchSopSection()}>
                            <div className="content-of-title-box-of-single-category-box">
                                <h3>{title}</h3>
                            </div>
                        </div>

                        <div className="right-icon-box-of-single-category-box">
                            <div className="content-of-right-icon-box-of-single-category-box buttons-type-container">
                                {buttons && buttons.length > 0 ? buttons.map((button, index) => <ButtonSingleItemDisplayer elemPrefix={elemPrefix} handleEdit={handleEdit} item={item} {...button} key={index} deleteInstance={deleteInstance} deleteItem={deleteItem} />) : ""}
                            </div>
                        </div>

                    </div>
                </div>
        </div>
    )
}