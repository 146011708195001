import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { addManyElementsData, createOrUpdateElementData, returnSingleFormElementData, updateElementDisabledStatus, updateElementLoadingStatus } from '../../../FormElements/formsDataSlice'
import { addNewForm } from '../../../FormElements/formsSlice'
import GenerateForm from '../../../FormElements/GenerateForm'
import SectionsApp, { SingleSectionInterface } from '../../sections/SectionsApp'
import { createOrUpdateSection } from './sopSlice'
import { Button, DialogContent, DialogTitle } from '@mui/material'

interface Props {
    handleClose: () => void
}

export default function SectionForm(props: Props) {

    const dispatch = useDispatch()

    const sectionsApp = new SectionsApp()


    const formDetails = {
        name: "section_modal_form",
        elemPrefix: "section_modal_form_elem_"
    }

    const elemPrefix = formDetails.elemPrefix

    const updateElementData = (name: string, value: any) => dispatch(createOrUpdateElementData({ name: elemPrefix + name, value }))

    /**
     * Update button loading
     */
    const updateButtonLoadingStatus = (loading: boolean) => {
        dispatch(
            updateElementLoadingStatus({
                name: elemPrefix + "submit",
                loading
            })
        )
    }

    /**
     * Update button loading
     */
    const updateButtonDisabledStatus = (status: boolean) => {
        dispatch(
            updateElementDisabledStatus({
                name: elemPrefix + "submit",
                disabled: status
            })
        )
    }

    /**
     * Get single form element data value
     */
    const getSingleFormElementValue = (name: string) => returnSingleFormElementData(elemPrefix + name).value

    const isUpdate = getSingleFormElementValue("update")

    /**
     * Reset form
     */
    const resetForm = () => {
        updateElementData("id", "")
        updateElementData("title", "")
        updateElementData("thumbnail", "")
        updateElementData("update", false)
    }

    /**
     * Close model
     */
    const handleClose = () => {
        resetForm()
        props.handleClose()
    }

    /**
     * Submit
     */
    const handleSubmit = () => {

        let data = {
            section_id: getSingleFormElementValue("section_id"),
            title: getSingleFormElementValue("title"),
            thumbnail: getSingleFormElementValue("thumbnail"),
            update: getSingleFormElementValue("update"),
            category_id: getSingleFormElementValue("category_id")
        }
       
        sectionsApp.createNewInstance(data)
            .then(response => {
                const { status, statusText, data } = response

                switch(status){

                    case 200:

                        const section: SingleSectionInterface = data

                        if (data){
                            dispatch(createOrUpdateSection(section))
                        }

                        dispatch(
                            createOrUpdateElementData({
                                name: elemPrefix + "alert",
                                value: `${data.title} - has been ${isUpdate ? "updated" : "created"}!`,
                                severity: "success"
                            })
                        )

                        handleClose()
                        break

                }

                updateButtonLoadingStatus(false)
                updateButtonDisabledStatus(true)
            })
            .catch(error => {
                const response = error.response
                const message = response.data! ? response.data.message! ? response.data.message : "" : response.statusText
                
                dispatch(
                    createOrUpdateElementData({
                        name: elemPrefix + "alert",
                        value: message,
                        severity: "error"
                    })
                )
            })

    }

    /**
     * Use Effect
     */
    useEffect(() => {

        /**
         * Add new form
         */
        dispatch(
            addNewForm({
                name: formDetails.name,
                elements: [
                    {
                        type: "TextField",
                        fieldType: "text",
                        xs: 12,
                        id: elemPrefix + "title",
                        label: "Title",
                        fullWidth: true
                    },
                    {
                        type: "ImageUploader",
                        xs: 12,
                        id: elemPrefix + "thumbnail",
                        label: "Thumbnail",
                        description: "Here you can upload the section image"
                    },
                    {
                        type: "Alert",
                        xs: 12,
                        label: "",
                        id: elemPrefix + "alert"
                    },
                    {
                        type: "Button",
                        xs: 12,
                        id: elemPrefix + "submit",
                        label: "Submit",
                        fullWidth: true,
                        onClick: () => {},
                        isSubmit: true,
                    },
                ]
            })
        )

        /**
         * Add new form elements data
         */
        dispatch(
            addManyElementsData([
                {
                    name: elemPrefix + "section_id",
                    value: getSingleFormElementValue("section_id") ? getSingleFormElementValue("section_id") : ""
                },
                {
                    name: elemPrefix + "update",
                    value: getSingleFormElementValue("update") ? getSingleFormElementValue("update") : false
                },
                {
                    name: elemPrefix + "title",
                    value:  getSingleFormElementValue("title") ? getSingleFormElementValue("title") : ""
                },
                {
                    name: elemPrefix + "thumbnail",
                    value: getSingleFormElementValue("thumbnail") ? getSingleFormElementValue("thumbnail") : ""
                },
                {
                    name: elemPrefix + "alert",
                    value: "Test",
                    severity: "info",
                    hidden: true
                },
                {
                    name: elemPrefix + "submit",
                    value: "",
                    hidden: false
                }
            ])
        )
    }, [])



    return(
        <>
            <DialogTitle id="alert-dialog-title">
                {isUpdate ? "Update section" : "Create a new section"}
            </DialogTitle>
            <DialogContent>
                <div className="section-form-container-in-modal">
                    <div className="content-of-section-form-container-in-modal">
                        <GenerateForm name={formDetails.name} handleSubmitForm={handleSubmit} />
                        <Button style={{ position: "absolute", top: -100000 }} id="reset_sop_section_modal_form" onClick={() => resetForm()}>Reset</Button>
                    </div>
                </div>
            </DialogContent>
        </>

    )
}