import React from 'react'
import { SinglePostInterface } from '../../apps/posts/PostsApp'
import Application from '../../Application'
import RenderPostContent from './RenderPostContent'
import DateAndTime from '../../DateAndTime/DateAndTime'

export default function PrintedPost(props: SinglePostInterface) {

    const application = new Application()
    const dateAndTime = new DateAndTime()

    const { id, slug, title, body } = props

    return(
        <div className="printed-post-container" id={`printed-post-page_post-id-${id}`}>
            <div className="content-of-printed-post-container">

                <div className="header-of-printed-post-container">
                    <div className="content-of-header-of-printed-post-container">

                        <div className="logo-of-header-of-printed-post-container">
                            <div className="content-of-logo-of-header-of-printed-post-container">
                                {application.returnAppLogoAsSVG()}
                            </div>
                        </div>

                        <div className="title-box-of-header-of-printed-post-container">
                            <div className="content-of-title-box-of-header-of-printed-post-container">
                                <h2>Standard Operating Procedure</h2>
                            </div>
                        </div>

                    </div>
                </div>

                <div className="post-body-of-printed-post-container">
                    <div className="content-of-post-body-of-printed-post-container">

                        <div className="header-of-post-body-of-printed-post-container">
                            <div className="content-of-header-of-post-body-of-printed-post-container">
                                <h1>{title}</h1>
                                <p>Document saved on: {dateAndTime.formatDate(dateAndTime.getCurrentDate())}</p>
                            </div>
                        </div>
                        
                        <RenderPostContent body={body} />
                    </div>
                </div>

            </div>
        </div>
    )
}