import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import DateAndTime from "../../DateAndTime/DateAndTime";
import { User, UserState } from "../Interfaces";
import { RootState, store } from "../../../app/store"
import { useSelector } from "react-redux";

const initialState: UserState = {
    currentUser: {
        id: 0,
        first_name: "",
        last_name: "",
        email: "",
        is_active: true,
        is_admin: false,
        is_superuser: false,
        is_password_set: false,
        events_access: [],
        thumbnail: "",
        date_joined: "",
        isDetailsLoaded: false,
        company_id: 0,
        company_name: "",
        phone: "",
        enable_monthly_password_update: false
    }
}
const userSlice = createSlice({
    name: "userSlice",
    initialState,
    reducers: {
        updateUser: (state: UserState, action: PayloadAction<User>) => {
            state.currentUser = action.payload
        },
    }
})

/**
 * Return single item request details
 */
 export const returnCurrentUser = () => {
    const state = store.getState()
    return state.user.currentUser
}

export const useReturnCurrentUser = () => useSelector((state: RootState) => state.user.currentUser)

export const { updateUser } = userSlice.actions
export default userSlice.reducer