import React, { useEffect } from 'react'
import BaseStructuredPage from '../components/BaseStructuredPage'
import { useDispatch } from 'react-redux'
import { addManyElementsData, createOrUpdateElementData, returnSingleFormElementData, updateElementDisabledStatus, updateElementLoadingStatus } from '../../FormElements/formsDataSlice'
import { addNewForm } from '../../FormElements/formsSlice'
import Application from '../../Application'
import GenerateForm from '../../FormElements/GenerateForm'
import UsersApp from '../../apps/users/UsersApp'
import FormValidator from '../../FormElements/FormValidator'

export default function ResetTwoFactorAuthPage() {

    const dispatch = useDispatch()

    const formDetails = {
        name: "reset_two_factor_auth_form",
        elemPrefix: "reset_two_factor_auth_form_elem_"
    }

    const elemPrefix = formDetails.elemPrefix

    const application = new Application()

    const usersApp = new UsersApp()

    /**
     * Update button loading
     */
    const updateButtonLoadingStatus = (loading: boolean) => {
        dispatch(
            updateElementLoadingStatus({
                name: elemPrefix + "submit",
                loading
            })
        )
    }

    /**
     * Update button loading
     */
    const updateButtonDisabledStatus = (status: boolean) => {
        dispatch(
            updateElementDisabledStatus({
                name: elemPrefix + "submit",
                disabled: status
            })
        )
    }

    /**
     * Get single form element data value
     */
    const getSingleFormElementValue = (name: string) => returnSingleFormElementData(elemPrefix + name).value

    /**
     * Submit
     */
    const handleSubmit = () => {

        updateButtonLoadingStatus(true)

        const password: string = getSingleFormElementValue("password")
        const formValidator = new FormValidator([elemPrefix + "password"])

        if (formValidator.isValid()){
            usersApp.resetTwoFactorAuth(password)
                .then(response => {

                    const { status } = response

                    if (status == 200){
                        window.location.replace("/")
                    }

                })
                .catch(error => {
                    const response = error.response
                    const message = response.data! ? response.data.message! ? response.data.message : "" : response.statusText

                    updateButtonLoadingStatus(false)

                    dispatch(
                        createOrUpdateElementData({
                            name: elemPrefix + "alert",
                            value: message,
                            severity: "error"
                        })
                    )
                })
        }else{
            updateButtonLoadingStatus(false)
            formValidator.showRequiredFields(dispatch)
        }

    }

    /**
     * Use Effect
     */
    useEffect(() => {

        /**
         * Add new form
         */
        dispatch(
            addNewForm({
                name: formDetails.name,
                elements: [
                    {
                        type: "TextField",
                        fieldType: "password",
                        xs: 12,
                        id: elemPrefix + "password",
                        label: "Password",
                        submitFormOnPressingEnter: true,
                        fullWidth: true
                    },
                    {
                        type: "Alert",
                        xs: 12,
                        label: "",
                        id: elemPrefix + "alert"
                    },
                    {
                        type: "Button",
                        xs: 12,
                        id: elemPrefix + "submit",
                        label: "Reset Two-Factor Authentication",
                        fullWidth: true,
                        onClick: () => {},
                        isSubmit: true
                    },
                ]
            })
        )

        /**
         * Add new form elements data
         */
        dispatch(
            addManyElementsData([
                {
                    name: elemPrefix + "password",
                    value: ""
                },
                {
                    name: elemPrefix + "alert",
                    value: "Test",
                    severity: "info",
                    hidden: true
                },
                {
                    name: elemPrefix + "submit",
                    value: ""
                }
            ])
        )
    }, [])

    return(
        <div className="reset-two-factor-auth-form-container">
            <div className="sign-in-page-container">
                <div className="content-of-sign-in-page-container">
                
                    <div className="login-form-container">
                        <div className="content-of-login-form-container">

                            <div className="app-logo-of-login-form-container">
                                <div className="content-of-app-logo-of-login-form-container">
                                    <a href="/">
                                        <img src={application.returnAppLogo()} />
                                    </a>
                                </div>
                            </div>

                            <div className="actual-form-of-login-form-container">
                                <GenerateForm name={formDetails.name} handleSubmitForm={handleSubmit} />
                            </div>

                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}