import { IconButton, Skeleton, Tooltip } from '@mui/material'
import React from 'react'
import Breadcrumbs, { BreadcrumbInterface } from '../../Pages/components/Breadcrumbs'
import GenerateIcon from '../../FormElements/GenerateIcon'
import ItemButtons from '../../DataDisplayer/ItemButtons'

interface TitleButtonInterface {
    title: string,
    icon: string,
    onClick: () => void,
    customButton?: () => void
}

interface IAdditionalInfoLink {
    title: string,
    icon: string,
    tooltip: string
}

interface Props {
    title: string,
    loading?: boolean,
    breadcrumbs?: Array<BreadcrumbInterface>,
    titleButtons?: Array<TitleButtonInterface>,
    additionalInfo?: Array<IAdditionalInfoLink>
}

function SingleTitleButton(props: TitleButtonInterface){

    const { title, icon, onClick } = props

    if (props.customButton){
        return <>{props.customButton()}</>
    }

    return(
        <Tooltip title={title}>
            <IconButton className="section-title-button" onClick={() => onClick()}>
                <GenerateIcon icon={icon} />
            </IconButton>
        </Tooltip>
    )
}

function SingleAdditionalInfoBox(props: IAdditionalInfoLink){

    const { title, icon, tooltip } = props

    return(
        <Tooltip title={tooltip}>
            <div className="additional-post-info-title-box">
                <div className="content-of-additional-post-info-title-box">

                    <div className="icon-of-additional-post-info-title-box">
                        <div className="content-of-icon-of-additional-post-info-title-box">
                            <GenerateIcon icon={icon} />
                        </div>
                    </div>

                    <div className="text-of-additional-post-info-title-box">
                        <div className="content-of-text-of-additional-post-info-title-box">
                            <p>{title}</p>
                        </div>
                    </div>

                </div>
            </div>
        </Tooltip>
    )
}

export default function SectionDetailsBox(props: Props) {

    const { title, loading, breadcrumbs, titleButtons, additionalInfo } = props

    const loadingBreadCrumbs = () => {
        return(
            <div style={{ display: "flex" }}>
                <Skeleton variant="text" width={100} style={{ marginRight: 15 }} />
                <Skeleton variant="text" width={100} style={{ marginRight: 15 }} />
                <Skeleton variant="text" width={100} style={{ marginRight: 15 }} />
            </div>
        )
    }

    const renderButtons = () => {
        if (titleButtons && titleButtons.length >0){
            return titleButtons.map((button, index) => <SingleTitleButton {...button} key={index} />)
        }

        return <></>
    }

    const renderAdditioanlInfo = () => {

        if (loading){
            return <></>
        }

        if (additionalInfo && additionalInfo.length > 0){
            return(
                <div className="post-additional-info-boxes-container">
                    <div className="content-of-post-additional-info-boxes-container">
                        {additionalInfo.map((info, index) => <SingleAdditionalInfoBox {...info} key={index} />)}
                    </div>
                </div>
            )
        }

        return <></>
    }

    return(
        <div className="single-section-details-box">
            <div className="content-of-single-section-details-box">

                <div className="title-of-single-section-details-box">
                    <div className="content-of-title-of-single-section-details-box">
                        <h1>{loading ? <Skeleton variant="text" width={150} /> : title}</h1>
                        {renderButtons()}
                    </div>
                </div>

                {renderAdditioanlInfo()}

                {loading ? loadingBreadCrumbs() : breadcrumbs ? <div className="breadcrumbs-of-single-section-details-box"><Breadcrumbs breadcrumbs={breadcrumbs} /></div> : ""}
            </div>
        </div>  
    )
}