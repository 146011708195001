import React, { useEffect, useMemo, useState } from 'react'
import PostsApp, { SinglePostInterface } from '../../posts/PostsApp'
import { createOrUpdatePost, useReturnPostFormModalStatus, useReturnSopActiveSectionDetails } from './sopSlice'
import { useDispatch } from 'react-redux'
import { addManyElementsData, createOrUpdateElementData, returnSingleFormElementData, updateElementDisabledStatus, updateElementHiddenStatus, updateElementLoadingStatus, useReturnFormElementData } from '../../../FormElements/formsDataSlice'
import { addNewForm } from '../../../FormElements/formsSlice'
import { SingleCategoryInterface } from '../../categories/CategoriesApp'
import { Button, IconButton } from '@mui/material'
import GenerateIcon from '../../../FormElements/GenerateIcon'
import GenerateForm from '../../../FormElements/GenerateForm'
import RenderSingleFormElement from '../../../FormElements/RenderSingleFormElement'

import { Block, BlockNoteEditor, BlockNoteSchema, PartialBlock, defaultBlockSpecs, insertOrUpdateBlock, filterSuggestionItems } from "@blocknote/core";
import {
    SuggestionMenuController,
    getDefaultReactSlashMenuItems,
    useCreateBlockNote,
  } from "@blocknote/react";

import { BlockNoteView } from "@blocknote/mantine";
import UploadImage from '../../../FormElements/UploadImage'
import FormElementAlert from '../../../FormElements/Elements/FormElementAlert'
import { title } from 'process'
import { RiAlertFill } from "react-icons/ri";
import { useReturnApplicationMode } from '../../../../app/applicationSettingsSlice'


interface Props {
    handleClose: () => void
}

export default function PostForm(props: Props) {

    const dispatch = useDispatch()

    const appThemeMode = useReturnApplicationMode()

    const open = useReturnPostFormModalStatus()

    const [postBodyContent, setPostBodyContent] = useState<string>("")

    const postsApp = new PostsApp()

    const activeSopSectionDetails = useReturnSopActiveSectionDetails()

    const formDetails = {
        name: "posts_form_modal",
        elemPrefix: "posts_form_modal_elem_"
    }

    const elemPrefix = formDetails.elemPrefix

    const updateElementData = (name: string, value: any) => dispatch(createOrUpdateElementData({ name: elemPrefix + name, value }))

    /**
     * Update button loading
     */
    const updateButtonLoadingStatus = (loading: boolean) => {
        dispatch(
            updateElementLoadingStatus({
                name: elemPrefix + "submit",
                loading
            })
        )
    }

    /**
     * Update button loading
     */
    const updateButtonDisabledStatus = (status: boolean) => {
        dispatch(
            updateElementDisabledStatus({
                name: elemPrefix + "submit",
                disabled: status
            })
        )
    }

    /**
     * Get single form element data value
     */
    const getSingleFormElementValue = (name: string) => returnSingleFormElementData(elemPrefix + name).value

    const alertData = useReturnFormElementData()[elemPrefix + "alert"]

    const body = getSingleFormElementValue("body")
    const isUpdate = getSingleFormElementValue("update")

    /**
     * Reset form
     */
    const resetForm = () => {
        updateElementData("id", "")
        updateElementData("post_id", "")
        updateElementData("title", "")
        updateElementData("thumbnail", "")
        updateElementData("body", [{}])
        updateElementData("update", false)
        updateElementData("section_id", "")
        updateElementData("category_id", "")
        setInitialContent([{}])
        dispatch(updateElementHiddenStatus({ name: elemPrefix + "alert", hidden: true }))
    }

    /**
     * Close model
     */
    const handleClose = () => {
        resetForm()
        props.handleClose()
    }

    /**
     * Submit
     */
    const handleSubmit = () => {


        let data = {
            section_id: getSingleFormElementValue("section_id"),
            category_id: getSingleFormElementValue("category_id"),
            // body: getSingleFormElementValue("body"),
            body: postBodyContent,
            title: getSingleFormElementValue("title"),
            thumbnail: getSingleFormElementValue("thumbnail"),
            update: getSingleFormElementValue("update"),
            post_id: getSingleFormElementValue("post_id")
        }

        let errorMessage: string = ""

        if (data.body === JSON.stringify([{}])){
            errorMessage = "Post Content is required!"
        }

        if (!title){
            errorMessage = "Title is required!"
        }
       
        if (!errorMessage){
            postsApp.createNewInstance(data)
                .then(response => {
                    const { status, statusText, data } = response

                    switch(status){

                        case 200:

                            const post: SinglePostInterface = data

                            if (data){
                                dispatch(createOrUpdatePost(post))
                            }

                            dispatch(
                                createOrUpdateElementData({
                                    name: elemPrefix + "alert",
                                    value: `${data.title} - has been ${isUpdate ? "updated" : "created"}!`,
                                    severity: "success"
                                })
                            )

                            handleClose()
                            resetForm()
                            break

                    }

                    updateButtonLoadingStatus(false)
                    updateButtonDisabledStatus(true)
                })
                .catch(error => {
                    const response = error.response
                    const message = response.data! ? response.data.message! ? response.data.message : "" : response.statusText
                    
                    dispatch(
                        createOrUpdateElementData({
                            name: elemPrefix + "alert",
                            value: message,
                            severity: "error"
                        })
                    )
                })
        }else{
            dispatch(createOrUpdateElementData({
                name: elemPrefix + "alert",
                value: errorMessage,
                severity: "error"
            }))
        }


    }

     /**
     * Use Effect
     */
    useEffect(() => {

        /**
         * Add new form
         */
        dispatch(
            addNewForm({
                name: formDetails.name,
                elements: [
                    {
                        type: "TextField",
                        fieldType: "text",
                        xs: 12,
                        id: elemPrefix + "title",
                        label: "Title",
                        fullWidth: true
                    },
                    // {
                    //     type: "ImageUploader",
                    //     xs: 12,
                    //     id: elemPrefix + "thumbnail",
                    //     label: "Thumbnail",
                    //     description: "Here you can upload the section image"
                    // },
                    {
                        type: "CustomElement",
                        fieldType: "text",
                        xs: 12,
                        id: elemPrefix + "body",
                        label: "Title",
                        fullWidth: true
                    },
                    {
                        type: "Alert",
                        xs: 12,
                        label: "",
                        id: elemPrefix + "alert"
                    },
                    {
                        type: "Button",
                        xs: 12,
                        id: elemPrefix + "submit",
                        label: "Submit",
                        fullWidth: true,
                        onClick: () => {},
                        isSubmit: true,
                    },
                ]
            })
        )

        /**
         * Add new form elements data
         */
        dispatch(
            addManyElementsData([
                {
                    name: elemPrefix + "post_id",
                    value: getSingleFormElementValue("post_id") ? getSingleFormElementValue("post_id") : ""
                },
                {
                    name: elemPrefix + "body",
                    value: body ? body : ""
                },
                {
                    name: elemPrefix + "update",
                    value: getSingleFormElementValue("update") ? getSingleFormElementValue("update") : false
                },
                {
                    name: elemPrefix + "title",
                    value:  getSingleFormElementValue("title") ? getSingleFormElementValue("title") : ""
                },
                {
                    name: elemPrefix + "thumbnail",
                    value: getSingleFormElementValue("thumbnail") ? getSingleFormElementValue("thumbnail") : ""
                },
                {
                    name: elemPrefix + "section_id",
                    value: getSingleFormElementValue("section_id") ? getSingleFormElementValue("section_id") : (activeSopSectionDetails as SingleCategoryInterface).section_id
                },
                {
                    name: elemPrefix + "category_id",
                    value: getSingleFormElementValue("category_id") ? getSingleFormElementValue("category_id") : activeSopSectionDetails.id
                },
                {
                    name: elemPrefix + "alert",
                    value: "Test",
                    severity: "info",
                    hidden: true
                },
                {
                    name: elemPrefix + "submit",
                    value: "",
                    hidden: false
                }
            ])
        )
    }, [])



    const [initialContent, setInitialContent] = useState<PartialBlock[]>([{}])

    useEffect(() => {
        if (isUpdate){
            if (initialContent !== body){
                if(body){
                    try{
                        setInitialContent((JSON.parse(body) as PartialBlock[]))
                    }catch (error){
                        console.log(error)
                    }
                }
            }
        }
    }, [body])

    useEffect(() => {
        if (!isUpdate){
            setInitialContent([{}])
            replaceVideoToIframe()
        }
    }, [isUpdate])

    const saveEditor = (jsonBlocks: Block[]) => {
        const content = JSON.stringify(jsonBlocks)
        // updateElementData("body", content)
        setPostBodyContent(content)
        replaceVideoToIframe()
    }

    async function uploadFile(file: File) {
        const imageUploader = new UploadImage()

        const ext = file.name.split('.').pop()

        let fileType = "image"

        const body = new FormData();
        

        let url = imageUploader.imageUploaderUrl

        /**
         * pdf
         */
        if (ext === "pdf"){
            url = imageUploader.pdfUploaderUrl
            fileType = "file"
        }

        /**
         * Upload file
         */

        body.append(fileType, file);
       
        const ret = await fetch(url, {
          method: "POST",
          body: body,
          credentials: "include"
        });
        return (await ret.json()).url.replace(
          "tmpfiles.org/",
          "tmpfiles.org/dl/"
        );

    }


    const schema = BlockNoteSchema.create({
        blockSpecs: {
          // Adds all default blocks.
            ...defaultBlockSpecs,
        },
      });

    const editor = useMemo(() => {
        return BlockNoteEditor.create({ initialContent, uploadFile, schema: schema });
    }, [initialContent]);

    /**
     * Replace video to iframe
     */
    const replaceVideoToIframe = () => {
        const video = Array.from(document.getElementsByClassName("bn-visual-media"))

        // console.log("video", video)
        if (video && video.length > 0){
            video.map((elem) => {
                if (elem.localName == "video"){

                    let url: string = ""

                    Array.from(elem.attributes).map((attr) => {
                        if (attr.name == "src"){
                            url = postsApp.returnVimeoEmbededVideoLink(attr.value)
                        }
                    })

                    const newElem = document.createElement("div")
                    newElem.className = "video-iframe-block in-block-iframe"

                    const iframe = document.createElement("iframe")

                    newElem.appendChild(iframe)
                    iframe.setAttribute('src', url)

                    elem.replaceWith(newElem)
                }
            })
        }
    }

    useEffect(() => {
        replaceVideoToIframe()
    }, [postBodyContent])

    useEffect(() => {
        setTimeout(() => {
            replaceVideoToIframe()
        }, 500)
    }, [initialContent])

    return(
        <>
            <div className="post-modal-sop-container-content">
                <div className="content-of-post-modal-sop-container-content">
                    <div className="head-box-of-post-modal-sop-container-content">
                        <div className="content-of-head-box-of-post-modal-sop-container-content">

                            <div className="text-of-head-box-of-post-modal-sop-container-content">
                                <div className="content-of-text-of-head-box-of-post-modal-sop-container-content">
                                    <h3>{isUpdate ? "Edit: " + getSingleFormElementValue("title") : "Create a new post"}</h3>
                                </div>
                            </div>

                            <div className="button-of-head-box-of-post-modal-sop-container-content">
                                <div className="content-of-button-of-head-box-of-post-modal-sop-container-content">
                                    <IconButton onClick={() => handleClose()}>
                                        <GenerateIcon icon="close" />
                                    </IconButton>
                                </div>
                            </div>

                        </div>
                    </div>

                    <div className="form-box-of-post-modal-sop-container-content">
                        <div className="content-of-form-box-of-post-modal-sop-container-content">
                            {/* <GenerateForm name={formDetails.name} handleSubmitForm={handleSubmit} /> */}
                            <RenderSingleFormElement type="TextField" label="Title" id={elemPrefix + "title"} fullWidth />
                        
                            <div className="post-form-block-editor-container block-editor-form-container">
                                {open ? (
                                     <BlockNoteView 
                                        editor={editor} 
                                        theme={appThemeMode}
                                        onChange={() => saveEditor(editor.document)}
                                    >
                                    </BlockNoteView>
                                ): ""}
                            </div>

                            
                            <div style={{ paddingTop: 10 }}>
                                {alertData ? alertData.hidden ? "" : <FormElementAlert data={alertData} id="post" label="" /> : ""}
                            </div>

                            <div className="buttom-of-post-modal-sop-container-content">
                                <div className="content-of-buttom-of-post-modal-sop-container-content">

                                    {isUpdate ? (
                                         <Button onClick={() => window.open(`/posts/${getSingleFormElementValue("section_slug")}/${getSingleFormElementValue("category_slug")}/${getSingleFormElementValue("slug")}`, '_blank')?.focus()}>
                                            View
                                        </Button>
                                    ): ""}

                                    <Button onClick={() => handleSubmit()}>
                                        {isUpdate ? "Save changes" : "Create"}
                                    </Button>
                                
                                </div>
                            </div>

                            <div style={{ height: 100 }}></div>

                            <Button style={{ position: "absolute", top: -100000 }} id="reset_sop_post_modal_form" onClick={() => resetForm()}>Reset</Button>
                        </div>
                    </div>

                </div>
            </div>
        </>
    )
}