import React, { Dispatch, useEffect, useState } from 'react'
import PostsApp, { SinglePostInterface } from '../../posts/PostsApp'
import { ISopDetailsSingleItem, addManyCategories, addManyPosts, createOrUpdateCategory, createOrUpdatePost, deleteCategory, deletePost, resetCategories, resetPosts, updateCategoryFormModalStatus, updateCategoryLoadingStatus, updateCategoryPagination, updateItemsOrderStatus, updatePostDetailsModal, updatePostLoadingStatus, updatePostsFormModalStatus, updatePostsPagination, updateSopActiveSection, updateSopActiveSectionDetails, updateSopModal, updateSopModalOpenStatus, useReturnCategoriesLoadingStatus, useReturnCategoriesPagination, useReturnListOfCategories, useReturnListOfPOsts, useReturnPostPagination, useReturnPostsLoadingStatus, useReturnSopActiveSection, useReturnSopActiveSectionDetails, useReturnUpdateItemsOrderStatus } from './sopSlice'
import CategoriesApp, { SingleCategoryInterface } from '../../categories/CategoriesApp'
import { createOrUpdateElementData } from '../../../FormElements/formsDataSlice'
import ItemsDisplayer from './ItemsDisplayer/ItemsDisplayer'
import { ItemsDisplayerInterface } from './ItemsDisplayer/interfaces'
import { ItemDisplayerItemsType, SingleItemDataDisplayerType } from './ItemsDisplayer/types'
import { AnyAction } from '@reduxjs/toolkit'
import SectionsApp from '../../sections/SectionsApp'

export default function SopCategoriesList() {

    const categoriesApp = new CategoriesApp()
    const sectionsApp = new SectionsApp()
    const postsApp = new PostsApp()

    const elemPrefix = "categoreis_form_modal_elem_"

    const handleEditItem = (item: SingleItemDataDisplayerType, dispatch: Dispatch<AnyAction>) => {
        
        const updateElementData = (name: string, value: any) => dispatch(createOrUpdateElementData({ name: elemPrefix + name, value }))

        const post = (item as SinglePostInterface)
        const { id, title, body, thumbnail, section_slug, category_slug, section_id, category_id, slug } = post

        dispatch(updateCategoryFormModalStatus(true))

        updateElementData("category_id", id)
        updateElementData("title", title)
        updateElementData("thumbnail", thumbnail)
        updateElementData("update", true)

    }

    const config: ItemsDisplayerInterface = {
        sopSectionName: "sop-section",
        elemPrefix: elemPrefix,
        items: useReturnListOfCategories(),
        pagination: useReturnCategoriesPagination(),
        loadingItemsStatus: useReturnCategoriesLoadingStatus(),
        noItemsMessage: {
            title: "Nothing found...",
            message: "No categories have been found :("
        },
        addNewItemText: "Add a new category",
        loadAllItemsMessage: "Load all categories",
        optionsMenu: [
            {
                type: "select"
            },
            {
                type: "load-all"
            },
            {
                type: "update-order"
            }
        ],
        selectedItemsMenuOptions: [
            {
                type: "move"
            },
            {
                type: "delete"
            }
        ],
        listOfItems: sectionsApp.listOfCategories,
        updateItemsLoadingStatus: updateCategoryLoadingStatus,
        resetItems: (payload) => resetCategories([]),
        handleAddNew: (dispatch) => {
            dispatch(updateCategoryFormModalStatus(true))
        },
        updateItemsOrder: (items: ItemDisplayerItemsType) => {
            return categoriesApp.updatePostsOrder((items as Array<SingleCategoryInterface>))
        },
        addManyItems: (payload) => addManyCategories((payload as Array<SingleCategoryInterface>)),
        createOrUpdateItem: (payload) => createOrUpdateCategory((payload as SingleCategoryInterface)),
        updateItemsPagination: updateCategoryPagination,
        handleEditItem: handleEditItem,
        deleteInstance: categoriesApp.deleteInstance,
        deleteItem: deleteCategory,
        itemButtons: [
            {
                type: "view",
            },
            {
                type: "edit",
            },
            {
                type: "delete",
            }
        ],
        onTitleClick: (item, dispatch) => {
            dispatch(updateSopActiveSectionDetails(item))
            dispatch(updateSopActiveSection("sop-posts"))
            dispatch(updatePostLoadingStatus(true))
            window.scrollTo({top: 0, behavior: 'smooth'})
        },
        deleteMany: (records) => {
            return categoriesApp.deleteManyCategories((records as Array<any>))
        },
        moveManyItems: (records, section_id, category_id) =>{
            return categoriesApp.moveManyCategories((records as any), section_id, category_id)
        },
    }

    return <ItemsDisplayer {...config} />
}