import React, { useEffect, useState } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import BaseStructuredPage from '../components/BaseStructuredPage'
import SectionDetailsBox from '../../apps/sections/SectionDetailsBox'
import GenerateMessageBox, { MessageBoxPropsInterface, MessageBoxTypes } from '../../FormElements/GenerateMessageBox'
import { BreadcrumbInterface } from '../components/Breadcrumbs'
import CategoriesApp, { SingleCategoryInterface, emptySingleCategory } from '../../apps/categories/CategoriesApp'
import PostsList from './PostsList'
import updatePageTitle from '../../Router/components/updatePageTitle'
import { useDispatch } from 'react-redux'
import { useReturnCategoriesList, useReturnCategoriesLoadingStatus, updateCategoriesLoadingStatus, ICategoryWithPosts, returnSingleLoadedCategoryDetails, addManyCategories } from '../../apps/sop/components/postsSlice'

export default function SingleCategoryPage() {

    const categoryApp = new CategoriesApp()

    const dispatch = useDispatch()
    const loadingCategories = useReturnCategoriesLoadingStatus()

    const categoriesList = useReturnCategoriesList()

    const params = useParams()
    const categorySlug = params.category_slug ? params.category_slug : ""

    const [loading, setLoading] = useState<boolean>(true)
    // const [categoryDetails, setCategoryDetails] = useState<SingleCategoryInterface>(emptySingleCategory)
    const categoryDetails = returnSingleLoadedCategoryDetails(categoriesList, categorySlug)

    interface Message extends MessageBoxPropsInterface {
        shown?: boolean
    }
    const [message, setMessage] = useState<Message>({
        shown: false,
        title: "",
        message: "",
        type: "info"
    })

    const updateCategoriesListLoadingStatus = (status: boolean) => dispatch(updateCategoriesLoadingStatus(status))

    const resetMessage = () => setMessage({ shown: false, title: "", message: "", type: "info" })

    /**
     * Load category details
     */
    const loadCategoryDetails = () => {
        // setLoading(true)
        updateCategoriesListLoadingStatus(true)
        resetMessage()

        categoryApp.getSingleInstanceDetailsBySlug(categorySlug)
            .then(response => {

                const { data } = response

                const category: SingleCategoryInterface = data
                // setCategoryDetails(section)
                
                let categoires: Array<ICategoryWithPosts> = []
                categoires.push({
                    ...category,
                    posts: []
                })

                dispatch(addManyCategories(categoires))

                // setLoading(false)
                updateCategoriesListLoadingStatus(false)
                resetMessage()

            })
            .catch(error => {
                const { response } = error
                setMessage({
                    shown: true,
                    title: "Error",
                    message: response.data! ? response.data.message! ? response.data.message : "" : response.statusText,
                    type: "error"
                })
                updateCategoriesListLoadingStatus(false)
            })
    }

    /**
     * load category details 
     */
    useEffect(() => {
        loadCategoryDetails()
    }, [categorySlug])

    /**
     * Breadcrumbs
     */
    const breadcrumbs: Array<BreadcrumbInterface> = [
        {
            title: "Miraj SOP",
            link: "/",
            index: 0,
            total: 0
        },
        {
            title: categoryDetails.section_name,
            link: `/posts/${categoryDetails.section_slug}`,
            index: 0,
            total: 0
        },
        {
            title: categoryDetails.title,
            index: 0,
            total: 0
        },
    ]


    /**
     * Update title
     */
    useEffect(() => {
        updatePageTitle(categoryDetails.title ? categoryDetails.title : "Loading...")
    }, [categoryDetails.title])

    return(
        <BaseStructuredPage>
            <div className="single-section-page-wrapper content-section-wrapper">
                <SectionDetailsBox loading={loadingCategories} title={categoryDetails.title} breadcrumbs={breadcrumbs} />
                {message.shown ? <GenerateMessageBox {...message} /> : ""}
                {categorySlug ? <PostsList categorySlug={categorySlug} /> : ""}
            </div>
        </BaseStructuredPage>
    )
}