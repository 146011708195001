import { CircularProgress } from '@mui/material'
import React from 'react'
import { useSelector } from 'react-redux'
import { RootState, store } from '../../app/store'

interface Props {
    forceLoadingStatus?: boolean
}

export default function PagePreloader(props: Props) {

    let containerClassName: string = "page-preloader-container"
    const loadingStatus = useSelector((state: RootState) => state.pagePreloader).loading

    if (loadingStatus){
        containerClassName += " active"
    }

    if (props.forceLoadingStatus){
        containerClassName += " active"
    }

    return(
        <div className={containerClassName}>
            <div className="content-of-page-preloader-container">
                <CircularProgress />
            </div>
        </div>
    )
}