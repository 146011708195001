import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import FormElementAlert from './Elements/FormElementAlert'
import FormElementSwitch from "./Elements/FormElementSwitch"
import FormElementButton from './Elements/FormElementButton'
import FormElementTextField from './Elements/FormElementTextField'
import { createOrUpdateElementData, useReturnSingleFormElementData } from './formsDataSlice'
import { BaseSingleFormElement, FormElementsFormElement, FormTextFieldElement } from './Interfaces'
import FormElementImageUploader from './Elements/FormElementImageUploader'
import FormElementSelect from './Elements/FormElementSelect'
import FormElementDynamicSelect from './Elements/FormElementDynamicSelect'
import { useLocation } from 'react-router-dom'
import { FormControl } from '@mui/material'
import TwoFactorAuthCodeField from "./Elements/TwoFactorAuthCodeField"
import Recaptcha from "./Elements/Recaptcha"
import TextEditor from './Elements/TextEditor'
import BlockEditor from './Elements/BlockEditor'

interface Props extends BaseSingleFormElement{
    isUpdateForm?: boolean
}

export default function RenderSingleFormElement(props: Props) {

    const type = props.type
    const data = useReturnSingleFormElementData(props.id)

    const { search } = useLocation()
    const parameters = new URLSearchParams(search)

    const dispatch = useDispatch()

    useEffect(() => {

        if (parameters.get(props.id)){
            const dataValue = parameters.get(props.id)

            if (dataValue){
                dispatch(createOrUpdateElementData({
                    name: props.id,
                    value: dataValue,
                }))
            }

        }

    }, [])

    /**
     * Handle on change
     */
    const handleOnChange = (name: string, value: any) => {
        console.log("name", name, "value", value)
        dispatch(
            createOrUpdateElementData({
                name,
                value
            })
        )
    }

    const renderElement = () => {
        switch(type){

            /**
             * Text Field
             */
            case "TextField":
                return <FormElementTextField {...props} data={data} handleOnChange={handleOnChange} handleSubmitForm={props.handleSubmitForm} />
                break

            /**
             * Block Editor
             */
            case "BlockEditor":
                return <BlockEditor {...props} data={data} handleOnChange={handleOnChange} />
                break

            /**
             * TwoFactorAuth
             */
            case "TwoFactorAuth":
                return <TwoFactorAuthCodeField {...props} data={data} handleOnChange={handleOnChange} handleSubmitForm={props.handleSubmitForm} />

            /**
             * Text Field
             */
            case "Switch":
                return <FormElementSwitch {...props} data={data} handleOnChange={handleOnChange} />
                break

            /**
             * Link
             */
            case "Link":
                return(
                    <FormControl fullWidth={props.fullWidth} onClick={() => props.onClick ? props.onClick() : console.log("clicked")}>
                        <a href={props.link}>
                            {props.label}
                        </a>
                    </FormControl>
                )
                break

            /**
             * Button
             */
            case "Button":
                return <FormElementButton {...props} data={data} handleSubmitForm={props.handleSubmitForm} />
                break

            /**
             * Alert
             */
            case "Alert":
                return <FormElementAlert {...props} data={data} />
                break

            /**
             * Image Uploader
             */
            case "ImageUploader":
                return <FormElementImageUploader {...props} data={data} handleOnChange={handleOnChange} />
                break

            /**
             * Select
             */
            case "Select":
                return <FormElementSelect {...props} data={data} handleOnChange={handleOnChange} />
                break

            /**
             * Dynamic Select
             */
            case "DynamicSelect":
                console.log("props", props)
                return <FormElementDynamicSelect {...props} data={data} handleOnChange={handleOnChange} />
                break

            /**
             * ReCaptcha
             */
            case "ReCaptcha":
                return <Recaptcha id={props.id} handleOnChange={handleOnChange} />

            /**
             * Editor
             */
            case "Editor":
                return  <TextEditor value={data.value} onChange={(e) => handleOnChange(props.id, e)} placeholder={props.label} />

            /**
             * Custom Element
             */
            case "CustomElement":
                if (props.custom){
                    return <props.custom {...props} data={data} handleOnChange={handleOnChange} />
                }else{
                    return
                }
                break
    
            default:
                return(<React.Fragment></React.Fragment>)
        }
    }

    let containerClassName: string = "single-form-element-container"

    console.log("props.isUpdateForm", props.isUpdateForm)

    if (props.isUpdateForm){
        if (props.disableInEditForm){
            containerClassName += " disabled"
        }
    }

    if (props.disabled){
        containerClassName += " disabled"
    }

    return(
        <div className={containerClassName}>
            <div className="content-of-single-form-element-container">
                {!data.hidden && renderElement()}
            </div>
        </div>
    )
    
}