import { AxiosRequestConfig } from "../../Api/Interfaces";
import { DataDisplayerSingleSearchParam } from "../../DataDisplayer/Interfaces";
import BaseApp from "../BaseApp";

type searchParams = Array<DataDisplayerSingleSearchParam>

export interface SingleCategoryInterface {
    id: number,
    title: string,
    slug: string,
    thumbnail: string,
    is_active: boolean,
    date_created: string,
    created_by: number,
    section_slug: string,
    section_name: string
    section_id: number,
    last_updated_on: string,
    updated_by: number,
    updated_by_name: string,
    created_by_name: string,
    order_num: number,
}

export const emptySingleCategory: SingleCategoryInterface = {
    id: 0,
    title: "",
    slug: "",
    thumbnail: "",
    is_active: false,
    date_created: "",
    created_by: 0,
    section_slug: "",
    section_name: "",
    section_id: 0,
    last_updated_on: "",
    updated_by: 0,
    updated_by_name: "",
    created_by_name: "",
    order_num: 0,
}

/**
 * Companies App
 */
export default class CategoriesApp extends BaseApp {

    /**
     * App Name
     */
    appName: string = "categories"

    /**
     * App base end point path
     */
    appBaseEndPointPath: string = "categories"

    /**
     * Required fields
     */
    requiredFields = [
        "title"
    ]

    /**
     * Get single instance details by Slug
     */
    getSingleInstanceDetailsBySlug = (slug: string) => this.sendRequest({
        method: "GET",
        url: this.appBaseEndPointPath + `/category-by-slug/${slug}/details`,
        withCredentials: true
    })

    /**
     * List of posts
     */
    listOfPosts = async(slug: string, page: number, search: string | null, disablePagination: boolean, searchParams?: searchParams) => {

        let path: string = this.appBaseEndPointPath + `/category-by-slug/${slug}/posts/list`

        path += `?page=${page ? page : 1}`

        if (search !== null){
            path += "&search=" + search
        }

        if (searchParams && searchParams.length > 0){
            searchParams.map((searchParam: DataDisplayerSingleSearchParam) => {
                path += `&${searchParam.name}=${searchParam.value}`
            })
        }

        if (disablePagination){
            path += "&disable_pagination=1"
        }

        const config: AxiosRequestConfig = {
            method: "GET",
            url: path,
            withCredentials: true
        }

        return this.sendRequest(config)
    }

    /**
     * order
     */
    updatePostsOrder = (categories: Array<SingleCategoryInterface>) => this.sendRequest({
        method: "PUT",
        data: {
            posts: categories
        },
        url: this.appBaseEndPointPath + `/order/all-categories`,
        withCredentials: true
    })

    /**
     * Delete Many
     */
    deleteManyCategories = (records: Array<SingleCategoryInterface>) => this.sendRequest({
        method: "DELETE",
        data: {
            records: records
        },
        url: this.appBaseEndPointPath + `/delete-many`,
        withCredentials: true
    })

    /**
     * Move many categories
     */
    moveManyCategories = (records: Array<SingleCategoryInterface>, section_id: number, category_id: number) => this.sendRequest({
        method: "POST",
        data: {
            records: records,
            section_id,
        },
        url: this.appBaseEndPointPath + `/move-many`,
        withCredentials: true
    })

}