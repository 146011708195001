import React, { useEffect, useState } from 'react'
import { SingleCategoryInterface } from '../../apps/categories/CategoriesApp'
import SectionsApp from '../../apps/sections/SectionsApp'
import GenerateMessageBox, { MessageBoxPropsInterface } from '../../FormElements/GenerateMessageBox'
import { DataDisplayerPagination } from '../../DataDisplayer/Interfaces'
import SingleCategoryBox from './SingleCategoryBox'
import LoadMoreButton from './LoadMoreButton'
import { Skeleton } from '@mui/material'

interface Props {
    sectionSlug: string
}

export default function CategoriesList(props: Props) {

    const { sectionSlug } = props

    const [categories, setCategories] = useState<Array<SingleCategoryInterface>>([])
    const [loading, setLoading] = useState<boolean>(true)

    const [pagination, setPagination] = useState<DataDisplayerPagination>({
        count: 0,
        current_page: 0,
        total_pages: 0
    })

    const sectionsApp = new SectionsApp()

    interface Message extends MessageBoxPropsInterface {
        shown?: boolean
    }
    const [message, setMessage] = useState<Message>({
        shown: false,
        title: "",
        message: "",
        type: "info"
    })

    const resetMessage = () => setMessage({ shown: false, title: "", message: "", type: "info" })

    /**
     * Load categories
     */
    const loadCategories = (page: number = 1, search: string = "", loadMoreItems: boolean = false) => {
        setLoading(true)
        resetMessage()

        sectionsApp.listOfCategories(sectionSlug, page, search, false)
            .then(response => {

                const { status, data } = response

                switch(status){

                    case 200:

                        if (!loadMoreItems){
                            setCategories(data.results)
                        }else{

                            let items = categories

                            if (data.results.length > 0){
                                data.results.map((item: SingleCategoryInterface) => {
                                    items.push(item)
                                })
                            }

                            setCategories(items)

                        }

                        setPagination({
                            count: data.count ? data.count : 0,
                            current_page: page,
                            total_pages: data.total_pages ? data.total_pages : 0
                        })
                        break

                }

                setLoading(false)

                if (categories.length == 0){
                    setMessage({
                        shown: true,
                        title: "Nothing...",
                        message: "No categories have been found :(",
                        type: "info"
                    })
                }

            })
            .catch(error => {
                const { response } = error
                setMessage({
                    shown: true,
                    title: "Error",
                    message: response.data! ? response.data.message! ? response.data.message : "" : response.statusText,
                    type: "error"
                })
            })
    }

    /**
     * Load
     */
    useEffect(() => {
        loadCategories()
    }, [])

    /**
     * render categories
     */
    const renderCategories = () => {
        if (loading){
            return(
                <>
                    <Skeleton variant="rectangular" width="100%" height={100} style={{ marginBottom: 10 }} />
                    <Skeleton variant="rectangular" width="100%" height={100} style={{ marginBottom: 10 }} />
                    <Skeleton variant="rectangular" width="100%" height={100} style={{ marginBottom: 10 }} />
                    <Skeleton variant="rectangular" width="100%" height={100} style={{ marginBottom: 10 }} />
                    <Skeleton variant="rectangular" width="100%" height={100} style={{ marginBottom: 10 }} />
                </>
            )
        }else{
            if (categories.length > 0){
                return categories.map((category: SingleCategoryInterface, index: number) => <SingleCategoryBox {...category} key={index} />)
            }else{
                return <GenerateMessageBox type="info" title="Nothing found..." message="No categories have been added yet..." />
            }
        }

        return <></>
    }

    /**
     * Load more items
     */
    const loadMoreItems = () => {
        loadCategories(pagination.current_page + 1, "", true)
    }

    return(
        <div className="section-categories-list-container">
            <div className="content-of-section-categories-list-container">
                {renderCategories()}
                <LoadMoreButton title="Load more" loading={loading} onClick={loadMoreItems} show={pagination.total_pages !== pagination.current_page ? false : true} />
            </div>
        </div>
    )
}