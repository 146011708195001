import React, { useEffect, useState } from 'react'

import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Search from '@mui/icons-material/Search';
import { FormControl, IconButton } from '@mui/material';

import { useLocation, useParams } from 'react-router-dom'

export default function SearchBar() {

    const params = useParams()
    const search = params.search ? params.search : ""

    const [value, setValue] = useState<string>("")

    useEffect(() => {
        if (search){
            setValue(search)
        }
    }, [search])

    return(
        <FormControl fullWidth>
            {/* <InputLabel htmlFor="outlined-adornment-password">Search</InputLabel> */}
            <OutlinedInput
                id="outlined-adornment-password"
                type="text"
                fullWidth
                value={value}
                onChange={(e) => setValue(e.target.value)}
                placeholder="Search"
                onKeyUp={(e) => {
                    if (e.key == "Enter"){
                        if(value){
                            window.location.replace("/search/" + value)
                        }
                    }
                }}
                startAdornment={
                <InputAdornment position="start">
                    <IconButton
                    aria-label="toggle password visibility"
                    edge="start"
                    >
                        <Search />
                    </IconButton>
                </InputAdornment>
                }
            />
        </FormControl>
    )
}
