import React, { LegacyRef } from 'react'
import ReCAPTCHA from 'react-google-recaptcha'
import { AppDispatch } from '../../../app/store'
import { createOrUpdateElementData } from '../formsDataSlice'
import { useReturnApplicationMode } from '../../../app/applicationSettingsSlice'

interface Props {
    id: string,
    handleOnChange: (name: string, value: any) => void,
}

const recaptchaRef: LegacyRef<ReCAPTCHA> = React.createRef()

/**
 * Reset recaptcha
 */
export const resetReCaptcha = (dispatch: AppDispatch, name: string) => {
    dispatch(createOrUpdateElementData({
        name,
        value: ""
    }))
    
    if (recaptchaRef){
        recaptchaRef.current?.reset()
    }
}

export default function Recaptcha(props: Props) {

    const mode = useReturnApplicationMode()

    const handleOnChange = (key: string | null) => {
        if (key){
            props.handleOnChange(props.id, key)
        }
    }

    return(
        <div className="google-recaptcha-box">
            <ReCAPTCHA 
                onChange={handleOnChange}
                ref={recaptchaRef}
                sitekey="6LcdS9opAAAAAKr0KxfQzl0kqJS9MOPT4lkDFb_e"
                size="normal"
                theme={mode}
            />
        </div>
    )
}