import React from 'react'
import { applicationModes, updateAppMode, useReturnApplicationMode } from '../../../app/applicationSettingsSlice'
import { IconButton, Tooltip } from '@mui/material'

import DarkModeIcon from '@mui/icons-material/DarkMode';
import LightModeIcon from '@mui/icons-material/LightMode';
import { useDispatch } from 'react-redux';

export default function ChangeThemeModeButton() {

    const mode = useReturnApplicationMode()
    const dispatch = useDispatch()

    return(
        <div className="change-mode-fixed-button-container">
            <div className="content-of-change-mode-fixed-button-container">
                <Tooltip title={mode == "dark" ? "Light Mode" : "Dark Mode"}>
                    <IconButton size='large' onClick={() => {
                        let switchMode: applicationModes = mode == "dark" ? "light" : "dark"
                        dispatch(updateAppMode({ mode: switchMode }))
                        window.localStorage.setItem("mode", switchMode)
                    }}>
                        {mode == "dark" ? <LightModeIcon /> : <DarkModeIcon />}
                    </IconButton>
                </Tooltip>
            </div>
        </div>
    )
}