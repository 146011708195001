import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { useSelector } from "react-redux"
import { RootState, store } from "../../../../app/store"
import { SinglePostInterface } from "../../posts/PostsApp"
import { emptySingleCategory, SingleCategoryInterface } from "../../categories/CategoriesApp"

export interface ICategoryWithPosts extends SingleCategoryInterface {
    posts: Array<SinglePostInterface>
}

interface InitialPostsStateInterface {
    posts: Array<SinglePostInterface>,
    loadingPostsStatus: boolean,
    categories: Array<ICategoryWithPosts>,
    loadingCategories: boolean
}

const initialState: InitialPostsStateInterface = {
    posts: [],
    loadingPostsStatus: false,
    categories: [],
    loadingCategories: false
}

const postsSlice = createSlice({
    name: "postsSlice",
    initialState,
    reducers: {
        addManyCategories: (state: InitialPostsStateInterface, action: PayloadAction<Array<ICategoryWithPosts>>) => {
            const payload = action.payload
            
            let categories = state.categories
           
            if (payload.length > 0){
                payload.map((item: ICategoryWithPosts) => {
                    if (!categories.some(e => e.id === item.id)){
                        categories.push(item)
                    }
                })
            }

            state.categories = categories
        },
        addPostsToCategory: (state: InitialPostsStateInterface, action: PayloadAction<{ categorySlug: string, posts: Array<SinglePostInterface> }>) => {
            const payload = action.payload
            const categorySlug = payload.categorySlug
            const posts = payload.posts

            let categories = state.categories

            if (categories.some(e => e.slug === categorySlug)){
                const findIndex = categories.map((x) => x.slug ).indexOf(categorySlug)

                if (findIndex === 0 || findIndex > 0){
                    if (categories[findIndex]){
                        categories[findIndex] = {
                            ...categories[findIndex],
                            posts: posts
                        }
                    }
                }
            }

            state.categories = categories

        },
        updateCategoriesLoadingStatus: (state: InitialPostsStateInterface, action: PayloadAction<boolean>) => {
            state.loadingCategories = action.payload
        },
    }
})

/**
 * Return categories list
 */
export const useReturnCategoriesList = () => useSelector((state: RootState) => {
    return state.posts.categories
})

export const useReturnCategoriesLoadingStatus = () => useSelector((state: RootState) => {
    return state.posts.loadingCategories
})

export const returnSingleLoadedCategoryDetails = (categories: Array<ICategoryWithPosts>, categorySlug: string) => {

    let category: ICategoryWithPosts = {
        ...emptySingleCategory,
        posts: []
    }
    
    if (categories.some(e => e.slug === categorySlug)){
        const findIndex = categories.map((x) => x.slug ).indexOf(categorySlug)

        if (findIndex === 0 || findIndex > 0){
            if (categories[findIndex]){
                category = categories[findIndex]
            }
        }
    }
    
    return category
}

export const returnSingleLoadedPostDetails = (posts: Array<SinglePostInterface>, postSlug: string) => {
    
    if (posts.some(e => e.slug === postSlug)){
        const findIndex = posts.map((x) => x.slug ).indexOf(postSlug)

        if (findIndex === 0 || findIndex > 0){
            if (posts[findIndex]){
                return posts[findIndex]
            }
        }
    }
    
    return false
}


export const { addManyCategories, addPostsToCategory, updateCategoriesLoadingStatus } = postsSlice.actions
export default postsSlice.reducer