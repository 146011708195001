import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { useSelector } from "react-redux"
import { RootState, store } from "./store"

export type applicationModes = "light" | "dark"

interface IApplicationSettingsInitialState {
    mode: applicationModes
}

const initialState: IApplicationSettingsInitialState = {
    mode: "light"
}

const applicationSettingsSlice = createSlice({
    name: "applicationSettingsSlice",
    initialState,
    reducers: {
        updateAppMode: (state: IApplicationSettingsInitialState, action: PayloadAction<{ mode: applicationModes }>) => {
            state.mode = action.payload.mode
        },
    }
})

export const useReturnApplicationMode = () => useSelector((state: RootState) => {
    return state.applicationSettings.mode
})


export default applicationSettingsSlice.reducer
export const { updateAppMode } = applicationSettingsSlice.actions