import React from 'react'
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import { Skeleton } from '@mui/material';
import GenerateIcon from '../../../FormElements/GenerateIcon';

interface Props {
    onClick: () => void,
    loading?: boolean,
    show: boolean,
    title: string
}

export default function AddNewItemButton(props: Props) {

    const { loading, show, onClick, title } = props

    if (show){
        return <></>
    }

    if (loading){
        return(
            <Skeleton variant="rectangular" width="100%" height={100} />
        )
    }

    return(
        <div className="load-more-button-container" onClick={onClick}>
            <div className="content-of-load-more-button-container">

                <div className="load-icon-of-load-more-button-container">
                    <div className="content-of-load-icon-of-load-more-button-container">
                        <GenerateIcon icon="add" />
                    </div>
                </div>

                <div className="text-of-load-more-button-container">
                    <div className="content-of-text-of-load-more-button-container">
                        <h3>{title}</h3>
                    </div>
                </div>  

            </div>
        </div>
    )
}