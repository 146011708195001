import React, { useEffect } from 'react'
import { returnCurrentUser } from '../../Api/User/userSlice'

export default function DashboardPage() {

    const currentUser = returnCurrentUser()

    /**
     * Return greeting message
     */
    const returnGreetingMessage = () => {

        const time = new Date().getHours()

        let message: string = "Hello"

        if (time >= 3){
            message = "Good morning"
        }

        if (time >= 12 ){
            message = "Good afternoon"
        }

        if (time >= 18){
            message = "Good evening"
        }

        return message + ", " + currentUser.first_name + "!"

    }


    return(
        <div className="dashboard-page-container">
            <div className="content-of-dashboard-page-container">

                <div className="admin-panel-head-section">
                    <div className="content-of-admin-panel-head-section">
                        <h1>{returnGreetingMessage()}</h1>
                    </div>
                </div>

            </div>
        </div>
    )
}