import React, { useEffect } from 'react'
import { PageComponentProps } from '../../Router/Interfaces'
import BaseStructuredPage from '../components/BaseStructuredPage'
import HomeHeadBox from './HomeHeadBox'
import SectionsList from '../Posts/SectionsList'

export default function HomePage(props: PageComponentProps) {    
    return (
        <BaseStructuredPage>
            
            <HomeHeadBox />
            <SectionsList />

        </BaseStructuredPage>
    )
}