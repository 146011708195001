import React, { useState, useMemo, useEffect } from 'react'

import { Block, BlockNoteEditor, PartialBlock } from "@blocknote/core";
import { useCreateBlockNote } from "@blocknote/react";
import { BlockNoteView } from "@blocknote/mantine";

import { FormElementData, FormTextFieldElement } from '../Interfaces'
import { returnSingleFormElementData } from '../formsDataSlice';
import UploadImage from '../UploadImage';
import { error } from 'console';

interface Props extends FormTextFieldElement{
    data: FormElementData,
    handleOnChange: (name: string, value: any) => void,
}

export default function BlockEditor(props: Props) {

    const [initialContent, setInitialContent] = useState<
    PartialBlock[] | undefined | "loading"
  >("loading");

    const [image, setImage] = useState<string>("")

    const [isContentSet, setIsContentSet] = useState<boolean>(false)
    const [cachedValue, setCachedValue] = useState<any>()

    const data = props.data

    useEffect(() => {
        if (!isContentSet){
            if (data.value){
                setInitialContent((JSON.parse(data.value) as PartialBlock[]))
                setIsContentSet(true)
            }else{
                setInitialContent([{}])
                // setIsContentSet(true)
            }
        }
    }, [data.value])


    // useEffect(() => {
    //     if (cachedValue !== data.value){
    //         if (!isContentSet){
    //             try{
    //                 setInitialContent((JSON.parse(data.value) as PartialBlock[]))
    //                 setIsContentSet(true)
    //             }catch{
    //                 setInitialContent(([{}] as PartialBlock[]))
    //                 setIsContentSet(true)
    //             }
    //         }
    //     }else{
    //         setInitialContent([{}])
    //         setIsContentSet(false)
    //     }
    // }, [data.value])

    // useEffect(() => {
    //     if (cachedValue !== data.value){
    //         if (!isContentSet){
    //             try{
    //                 setInitialContent((JSON.parse(data.value) as PartialBlock[]))
    //                 setIsContentSet(true)
    //             }catch{
    //                 setInitialContent(([{}] as PartialBlock[]))
    //                 setIsContentSet(true)
    //             }
    //         }
    //     }else{
    //         setInitialContent([{}])
    //         setIsContentSet(false)
    //     }
    // }, [data.value])
    

    const saveEditor = (jsonBlocks: Block[]) => {
        const content = JSON.stringify(jsonBlocks)
        props.handleOnChange(props.id, content)
    }

    async function uploadFile(file: File) {
        const imageUploader = new UploadImage()

        const body = new FormData();
        body.append("image", file);

        const url = imageUploader.imageUploaderUrl
       
        const ret = await fetch(url, {
          method: "POST",
          body: body,
          credentials: "include"
        });
        return (await ret.json()).url.replace(
          "tmpfiles.org/",
          "tmpfiles.org/dl/"
        );

    }

    const editor = useMemo(() => {
        if (initialContent === "loading") {
          return undefined;
        }
        return BlockNoteEditor.create({ initialContent, uploadFile });
      }, [initialContent]);
    
      if (editor === undefined) {
        return <>{"Loading content..."}</>;
    }


    return(
        <div className="admin-block-editor-container">
            <div className="content-of-admin-block-editor-container">
                {props.label ? <h3 className="title-of-admin-block-editor-container">{props.label}</h3> : ""}
                <BlockNoteView 
                    editor={editor} 
                    theme="light"
                    onChange={() => saveEditor(editor.document)}
                />
            </div>
        </div>
    )
}