import React, { useEffect, useState } from 'react';
import './App.css';
import './components/DataDisplayer/DataDisplayerStyles.css';
import Router from './components/Router/Router';
import { createTheme, ThemeProvider } from '@mui/material/styles';

import "@blocknote/core/fonts/inter.css";
import "@blocknote/mantine/style.css";
import "./components/FormElements/BlockEditor/block-editor.css"
import { useReturnApplicationMode, updateAppMode, applicationModes } from './app/applicationSettingsSlice';
import { useDispatch } from 'react-redux';
import ChangeThemeModeButton from './components/Pages/components/ChangeThemeModeButton';

function App() {

  const mode = useReturnApplicationMode()

  const dispatch = useDispatch()

  const [currentLocalStorageMode, setCurrentLocalStorageMode] = useState<string>("")

  const updateMode = (mode: applicationModes) => {
    if (mode == "dark"){
      document.body.classList.add('dark-mode')
    }else{
      document.body.classList.remove('dark-mode')
    }
  }

  const localStorageMode = window.localStorage.getItem("mode")

  useEffect(() => {
    if (localStorageMode !== currentLocalStorageMode){
      setCurrentLocalStorageMode((localStorageMode as any))
    }
  }, [localStorageMode])

  useEffect(() => {
    if (currentLocalStorageMode !== mode){

      let isModeValid: boolean = false

      if (currentLocalStorageMode == "light"){
        isModeValid = true
      }
      if (currentLocalStorageMode == "dark"){
        isModeValid = true
      }

      if (isModeValid){
        dispatch(updateAppMode({ mode: (currentLocalStorageMode as any) }))
        console.log(`mode is updated to ${currentLocalStorageMode}`)
      }
    }
  }, [currentLocalStorageMode])

  useEffect(() => {
    if (!currentLocalStorageMode){
      setCurrentLocalStorageMode((localStorageMode as any))
    }

    if (!localStorageMode){
        if (!currentLocalStorageMode){
          if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
            dispatch(updateAppMode({ mode: "dark" }))
            setCurrentLocalStorageMode("dark")
            window.localStorage.setItem("mode", "dark")
          }
        }
    }
  }, [])

  useEffect(() => {
    // if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
    //   if (!localStorageMode){
    //     dispatch(updateAppMode({ mode: "dark" }))
    //   }
    // }

    // if (currentLocalStorageMode !== mode){
    //   window.localStorage.setItem("mode", mode)
    // }
    updateMode(mode)
  }, [mode])

  const theme = createTheme({
    palette: {
      primary: {
        light: '#f05f2a',
        main: '#f05f2a',
        dark: '#f05f2a',
        contrastText: '#fff',
      },
      secondary: {
        light: '#828387',
        main: '#454649',
        dark: '#636569',
        // contrastText: '#000',
      },
      mode: mode
    },
  });

  return(
    <ThemeProvider theme={theme}>
      {/* <ChangeThemeModeButton /> */}
      <Router />
    </ThemeProvider>
  )
}

export default App;
