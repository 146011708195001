import React, { useEffect, useState } from 'react'
import { updatePostsFormModalStatus, useReturnPostFormModalStatus } from './sopSlice'
import PostForm from './PostForm'
import { useDispatch } from 'react-redux'

export default function PostEditorModal() {

    const open = useReturnPostFormModalStatus()

    const dispatch = useDispatch()

    const baseContainerClassName: string = "post-editor-modal-container-wrapper"
    const [containerClassName, setContainerClassName] = useState<string>(baseContainerClassName)

    /**
     * Update open status
     */
    useEffect(() => {
        setContainerClassName(open ? baseContainerClassName + " active" : baseContainerClassName)
    }, [open])

    /**
     * Close modal
     */
    const handleClose = () => {
        dispatch(updatePostsFormModalStatus(false))

        const elem = document.getElementById("reset_sop_post_modal_form")

        if (elem){
            elem.click()
        }
    }

    return(
        <div className={containerClassName}>
            
            <div className="post-editor-sop-modal">
                <div className="content-of-post-editor-sop-modal">
                    <PostForm handleClose={handleClose} />
                </div>
            </div>
            <div className="mask-of-post-editor-sop-modal" onClick={() => handleClose()}></div>

        </div>
    )
}