import { Block } from '@blocknote/core'
import { IconButton } from '@mui/material'
import React, { useState } from 'react'
import GenerateIcon from '../../GenerateIcon'
import axios from 'axios'
import { error } from 'console'

export default function FileBlock(props: Block) {

    const [isPreview, setIsPreview] = useState<boolean>(false)

    const blockProps = props.props
    const content: any = props.content

    let name: string = ""
    let url: string = ""

    if ("url" in blockProps){
        url = blockProps.url
    }
    if ("name" in blockProps){
        name = blockProps.name
    }

    let preview: string = ""

    if (url){
        preview = url.replace(".pdf", "_preview.jpg")
    }


    /**
     * Check if preview exists
     */
    if (preview){
        axios({
            method: "GET",
            url: preview,
            withCredentials: true
        })
            .then(response => {

                const { status } = response

                if (status == 200){
                    setIsPreview(true)
                }

            })
            .catch(error => {
                console.log(error.response)
                setIsPreview(false)
            })
    }

    /**
     * Render with preview
     */
    if (isPreview){
        return(
            <div className="file-block-box with-preview">
                <a href={url} target='_blank'>
                    <div className="content-of-file-block-box">

                        <div className="thumbnail-box-of-file-block-box">
                            <div className="content-of-thumbnail-box-of-file-block-box">
                                <div className="preview-of-file-block-box" style={{ backgroundImage: `url(${preview})` }}></div>
                            </div>
                        </div>
                        
                        <div className="details-box-of-file-block-box">
                            <div className="content-of-details-box-of-file-block-box">
                                <div className="icon-of-file-block-box"><GenerateIcon icon="file" /></div>
                                <p>{name}</p>
                            </div>
                        </div>

                    </div>
                </a>
            </div>
        )
    }

    /**
     * Render without preview
     */

    return(
        <div className="file-block-box">
            <a href={url} target='_blank'>
                <div className="content-of-file-block-box">
                    <div className="icon-of-file-block-box"><GenerateIcon icon="file" /></div>
                    <p>{name}</p>
                </div>
            </a>
        </div>
    )
}