import React, { useEffect, useState } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import BaseStructuredPage from '../components/BaseStructuredPage'
import SectionDetailsBox from '../../apps/sections/SectionDetailsBox'
import SectionsApp, { SingleSectionInterface, emptySectionDetails } from '../../apps/sections/SectionsApp'
import GenerateMessageBox, { MessageBoxPropsInterface, MessageBoxTypes } from '../../FormElements/GenerateMessageBox'
import CategoriesList from './CategoriesList'
import { BreadcrumbInterface } from '../components/Breadcrumbs'

import { Helmet } from "react-helmet";
import updatePageTitle from '../../Router/components/updatePageTitle'

export default function SingleSectionPage() {

    const sectionsApp = new SectionsApp()

    const params = useParams()
    const sectionSlug = params.section_slug ? params.section_slug : ""

    const [loading, setLoading] = useState<boolean>(true)
    const [sectionDetails, setSectionDetails] = useState<SingleSectionInterface>(emptySectionDetails)

    interface Message extends MessageBoxPropsInterface {
        shown?: boolean
    }
    const [message, setMessage] = useState<Message>({
        shown: false,
        title: "",
        message: "",
        type: "info"
    })

    const resetMessage = () => setMessage({ shown: false, title: "", message: "", type: "info" })

    /**
     * Load section details
     */
    const loadSectionDetails = () => {
        setLoading(true)
        resetMessage()

        sectionsApp.getSingleInstanceDetailsBySlug(sectionSlug)
            .then(response => {

                const { data } = response

                const section: SingleSectionInterface = data
                setSectionDetails(section)

                setLoading(false)
                resetMessage()

            })
            .catch(error => {
                const { response } = error
                setMessage({
                    shown: true,
                    title: "Error",
                    message: response.data! ? response.data.message! ? response.data.message : "" : response.statusText,
                    type: "error"
                })
            })
    }

    /**
     * load section details 
     */
    useEffect(() => {
        loadSectionDetails()
    }, [])

    /**
     * Breadcrumbs
     */
    const breadcrumbs: Array<BreadcrumbInterface> = [
        {
            title: "Miraj SOP",
            link: "/",
            index: 0,
            total: 0
        },
        {
            title: sectionDetails.title,
            index: 0,
            total: 0
        },
    ]

    /**
     * Update title
     */
    useEffect(() => {
        updatePageTitle(sectionDetails.title ? sectionDetails.title : "Loading...")
    }, [sectionDetails.title])

    return(
        <BaseStructuredPage>
            <div className="single-section-page-wrapper content-section-wrapper">
                <SectionDetailsBox loading={loading} title={sectionDetails.title} breadcrumbs={breadcrumbs} />
                {message.shown ? <GenerateMessageBox {...message} /> : ""}
                {sectionSlug ? <CategoriesList sectionSlug={sectionSlug} /> : ""}
            </div>
        </BaseStructuredPage>
    )
}