import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { returnCurrentUser } from '../../../Api/User/userSlice'
import GenerateIcon from '../../../FormElements/GenerateIcon'
import { Button, FormControlLabel, Switch } from '@mui/material'
import Application from '../../../Application'
import UsersApp from '../../../apps/users/UsersApp'

export default function ProfileSecuritySection() {

    const dispatch = useDispatch()

    const usersApp = new UsersApp()

    const currentUser = returnCurrentUser()

    const application = new Application()

    const [updateMonthlyPassword, setUpdateMonthlyPassword] = useState<boolean>(false)
    const [loadingUpdateMonthlyPasswordStatus, setLoadingUpdateMonthlyPasswordStatus] = useState<boolean>(false)

    /**
     * Update monthly update password status
     */
    const handleUpdateMonthlyPasswordUpdateStatus = () => {

        setLoadingUpdateMonthlyPasswordStatus(true)

        usersApp.resetMonthlyPasswordUpdateStatus()
            .then(response => {

                const { data } = response

                setUpdateMonthlyPassword(data.status ? true : false)

                setLoadingUpdateMonthlyPasswordStatus(false)

            })
            .catch(error => {
                const response = error.response
                const message = response.data! ? response.data.message! ? response.data.message : "" : response.statusText

                alert(message)
                setLoadingUpdateMonthlyPasswordStatus(false)
            })

    }

    useEffect(() => {
        setUpdateMonthlyPassword(currentUser.enable_monthly_password_update ? currentUser.enable_monthly_password_update : false)
    }, [])

    return(
        <div className="security-section-details-container">
            <div className="content-of-security-section-details-container">

                {/* Two-Factor Auth */}
                <div className="single-security-section-box">
                    <div className="content-of-single-security-section-box">

                        <div className="title-box-of-single-security-section-box">
                            <div className="content-of-title-box-of-single-security-section-box">

                                <div className="title-of-title-box-of-single-security-section-box">
                                    <div className="content-of-title-of-title-box-of-single-security-section-box">

                                        <div className="icon-of-title-of-title-box-of-single-security-section-box">
                                            <div className="content-of-icon-of-title-of-title-box-of-single-security-section-box">
                                                <GenerateIcon icon="password" />
                                            </div>
                                        </div>

                                        <div className="text-of-title-of-title-box-of-single-security-section-box">
                                            <h3>Two-Factor Authentication</h3>
                                            <p>Here you can manage your two-factor authentication settings</p>
                                        </div>

                                    </div>
                                </div>

                            </div>
                        </div>

                        <div className="items-of-single-security-section-box">
                            <div className="content-of-items-of-single-security-section-box">

                                <div className="buttons-of-items-of-single-security-section-box">

                                    <Button variant='outlined' href={application.returnAppLink("reset-two-factor-auth")}>Reset Two-Factor Authentication</Button>
                                    <Button variant='outlined' disabled>Disable Two-Factor Authentication</Button>

                                </div>

                            </div>
                        </div>

                    </div>
                </div>

                {/* Monthly update password status */}
                <div className="single-security-section-box">
                    <div className="content-of-single-security-section-box">

                        <div className="title-box-of-single-security-section-box">
                            <div className="content-of-title-box-of-single-security-section-box">

                                <div className="title-of-title-box-of-single-security-section-box">
                                    <div className="content-of-title-of-title-box-of-single-security-section-box">

                                        <div className="icon-of-title-of-title-box-of-single-security-section-box">
                                            <div className="content-of-icon-of-title-of-title-box-of-single-security-section-box">
                                                <GenerateIcon icon="security" />
                                            </div>
                                        </div>

                                        <div className="text-of-title-of-title-box-of-single-security-section-box">
                                            <h3>Monthly Update Password</h3>
                                            <p>Here you can enable or disable monthly update password</p>
                                        </div>

                                    </div>
                                </div>

                            </div>
                        </div>

                        <div className="items-of-single-security-section-box">
                            <div className="content-of-items-of-single-security-section-box">

                                <FormControlLabel disabled={loadingUpdateMonthlyPasswordStatus} control={<Switch checked={updateMonthlyPassword} onClick={() => handleUpdateMonthlyPasswordUpdateStatus()} />} label={updateMonthlyPassword ? "Disable" : "Enable"} />

                            </div>
                        </div>

                    </div>
                </div>

            </div>
        </div>
    )
}