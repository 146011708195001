import { Block } from '@blocknote/core'
import React from 'react'
import { IBlockContentItem } from '../BlockEditor'

type Cell = Array<IBlockContentItem>
interface IRow {
    cells: Array<Cell>
}

export default function TableBlock(props: Block) {

    const blockProps = props.props
    const content: any = props.content

    let rows: Array<IRow> = []

    if ("rows" in content){
        rows = content["rows"]
    }

    // console.log("table block", props)
    // console.log("rows", rows)

    const renderRows = () => {
        if (rows.length > 0){
            return rows.map((row) => {
                return(
                    <tr>
                        {"cells" in row ? row.cells.length > 0 ? row.cells.map((cell, index) => <td colSpan={1} rowSpan={1}>{cell[0] ? "text" in cell[0] ? <p>{cell[0].text}</p> : "" : ""}</td>) : "" : ""}
                    </tr>
                )
            })
        }

        return <></>
    }

    return(
        <table className="bn-inline-content">
            <tbody>
                {renderRows()}
            </tbody>
        </table>
    )
}