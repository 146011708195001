import React from 'react'

import IconButton from '@mui/material/IconButton';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Search from '@mui/icons-material/Search';
import { FormControl } from '@mui/material';

export default function HomeHeadBox() {
    return(
        <div className="home-headbox-container">
            <div className="content-of-home-headbox-container content-section-wrapper">

                <h1>Standard Operating Procedure</h1>

                {/* <div className="search-home-headbox-container">
                    <div className="content-of-search-home-headbox-container">
                        <FormControl fullWidth>
                            <InputLabel htmlFor="outlined-adornment-password">Search</InputLabel>
                            <OutlinedInput
                                id="outlined-adornment-password"
                                type="text"
                                fullWidth
                                startAdornment={
                                <InputAdornment position="start">
                                    <IconButton
                                    aria-label="toggle password visibility"
                                    edge="start"
                                    >
                                        <Search />
                                    </IconButton>
                                </InputAdornment>
                                }
                                label="Password"
                            />
                        </FormControl>
                    </div>
                </div> */}

            </div>
        </div>
    )
}