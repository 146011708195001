import React from 'react'

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useDispatch } from 'react-redux';
import { updateCategoryFormModalStatus, useReturnCategoryFormModalStatus } from './sopSlice';
import CategoryForm from './CategoryForm';

export default function CategoryFormModal() {

    const dispatch = useDispatch()

    const open = useReturnCategoryFormModalStatus()

    const handleClose = () => {
        dispatch(updateCategoryFormModalStatus(false))

        const elem = document.getElementById("reset_sop_category_modal_form")
        if (elem){
            elem.click()
        }
    }

    return(
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            maxWidth="sm"
            fullWidth
      >
            <CategoryForm handleClose={handleClose} />
        <DialogActions>
            <Button onClick={handleClose}>
                Cancel
            </Button>
            <Button onClick={() => {
                const elem = document.getElementById("categoreis_form_modal_elem_submit")

                if (elem){
                    elem.click()
                }
            }} autoFocus>
                Submit
            </Button>
        </DialogActions>
      </Dialog>
    )
}