import React, { useEffect, useState } from 'react'
import SectionsApp, { SingleSectionInterface } from '../../apps/sections/SectionsApp'
import { DataDisplayerPagination } from '../../DataDisplayer/Interfaces'
import { Grid, Skeleton } from '@mui/material'
import SingleSectionBox from './SingleSectionBox'
import GenerateMessageBox from '../../FormElements/GenerateMessageBox'
import LoadMoreButton from './LoadMoreButton'

export default function SectionsList() {

    const [loading, setLoading] = useState<boolean>(true)
    const [sections, setSecions] = useState<Array<SingleSectionInterface>>([])
    const [pagination, setPagination] = useState<DataDisplayerPagination>({
        count: 0,
        current_page: 0,
        total_pages: 0
    })

    /**
     * Load sections
     */
    const loadSections = (page: number = 1, search: string = "", loadMoreItems: boolean = false) => {
        const sectionsApp = new SectionsApp()
        sectionsApp.listOfInstances(page, search, false)
            .then(response => {
                
                const { status, data } = response

                switch(status){

                    case 200:
                        
                        if (!loadMoreItems){
                            setSecions(data.results)
                        }else{

                            let items = sections

                            if (data.results.length > 0){
                                data.results.map((item: SingleSectionInterface) => {
                                    items.push(item)
                                })
                            }

                            setSecions(items)

                        }

                        setPagination({
                            count: data.count ? data.count : 0,
                            current_page: page,
                            total_pages: data.total_pages ? data.total_pages : 0
                        })
                        break

                }

                setLoading(false)

            })
    }

    /**
     * Load sections
     */
    useEffect(() => {
        loadSections()
    }, [])

    /**
     * Generate preloader
     */
    const generatePreloader = () => {
        return(
            <Grid container spacing={2}>
                <Grid item xs={6}><Skeleton variant="rectangular" width={"100%"} height={400} /></Grid>
                <Grid item xs={6}><Skeleton variant="rectangular" width={"100%"} height={400} /></Grid>
                <Grid item xs={6}><Skeleton variant="rectangular" width={"100%"} height={400} /></Grid>
                <Grid item xs={6}><Skeleton variant="rectangular" width={"100%"} height={400} /></Grid>
            </Grid>
        )
    }

    /**
     * Generate sections
     */
    const generateSections = () => {
        if (sections.length > 0){
            return sections.map((section, index) => <SingleSectionBox {...section} key={index} />)
        }else{
            return <GenerateMessageBox type="info" title="Nothing :(" message="No sections have been added yet" />
        }
    }

    /**
     * Load more items
     */
    const loadMoreItems = () => {
        loadSections(pagination.current_page + 1, "", true)
    }

    return(
        <div className="sections-list-container">
            <div className="content-of-sections-list-container content-section-wrapper">
                {loading ? generatePreloader() : generateSections()}
            </div>
            <div className="load-more-items-button-container content-section-wrapper">
                <LoadMoreButton title="Load more" loading={loading} onClick={loadMoreItems} show={pagination.total_pages !== pagination.current_page ? false : true} />
            </div>
        </div>
    )
}