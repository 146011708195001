import { AdminPanelSection } from "./Interfaces";
import RegisterAdminPage from "./components/RegisterAdminPage"
import DashboardPage from "../apps/dashboard/DashboardPage";


import { ApiRequestDataInterface } from "../DataDisplayer/Interfaces";
import { Avatar, IconButton } from "@mui/material";
import GenerateIcon from "../FormElements/GenerateIcon";
import User from "../Api/User/User";
import DateAndTime from "../DateAndTime/DateAndTime";

import UsersApp from "../apps/users/UsersApp";
import SectionsApp from "../apps/sections/SectionsApp";
import PostsApp from "../apps/posts/PostsApp";
import CategoriesApp from "../apps/categories/CategoriesApp";
import MirajSOP from "../apps/sop/MirajSOP";
import UploadedPdfsApp from "../apps/uploaded_pdfs/UploadedPdfsApp";
import { returnSingleDataDisplayerItem } from "../DataDisplayer/dataDisplayerItemsSlice";
import Application from "../Application";

let registeredAdminPages: Array<AdminPanelSection> = []

const application = new Application()

/**
 * Register Dashboard Page
 */
 registeredAdminPages.push(RegisterAdminPage({
    type: "page",
    name: "dashboard",
    title: "Dashboard",
    icon: "home",
    usersAllowed: {
        onlyAdmin: true,
        onlySuperUser: false,
        anyUser: false
    },
    displayInMenu: true,
    optionalPages: {
        addNewPage: false,
        editPage: false,
        viewPage: false
    },
    pageComponent: DashboardPage,
    disableSectionTitleBox: true
}))

/**
 * Register SOP Page
 */
registeredAdminPages.push(RegisterAdminPage({
    type: "page",
    name: "sop",
    title: "Miraj SOP",
    icon: "article",
    usersAllowed: {
        onlyAdmin: true,
        onlySuperUser: false,
        anyUser: false
    },
    displayInMenu: true,
    optionalPages: {
        addNewPage: false,
        editPage: false,
        viewPage: false
    },
    pageComponent: MirajSOP,
    disableSectionTitleBox: true
}))

// /**
//  * Sections
//  */
// const helpSectionsPrefix = "sections_"
// registeredAdminPages.push(RegisterAdminPage({
//     type: "section",
//     name: "sections",
//     title: "Sections",
//     icon: "quiz",
//     usersAllowed: {
//         onlyAdmin: false,
//         onlySuperUser: true,
//         anyUser: false
//     },
//     displayInMenu: true,
//     optionalPages: {
//         addNewPage: true,
//         editPage: false,
//         viewPage: false
//     },
//     forms: {
//         defaultForm: {
//             name: helpSectionsPrefix + "default_form",
//             elements: [
//                 {
//                     type: "TextField",
//                     fieldType: "text",
//                     xs: 12,
//                     id: helpSectionsPrefix + "title",
//                     label: "Name",
//                     fullWidth: true
//                 },
//                 {
//                     type: "BlockEditor",
//                     fieldType: "text",
//                     xs: 12,
//                     id: helpSectionsPrefix + "editor",
//                     label: "Editor",
//                     fullWidth: true
//                 },
//                  {
//                      type: "ImageUploader",
//                      xs: 12,
//                      id: helpSectionsPrefix + "thumbnail",
//                      label: "Thumbnail",
//                      description: "Here you can upload the section image"
//                   },
//                 {
//                     type: "Button",
//                     xs: 12,
//                     id: helpSectionsPrefix + "submit",
//                     label: "Submit",
//                     fullWidth: false,
//                     onClick: () => {},
//                     isSubmit: true
//                 },
//             ]
//         }
//     },
//     formsData: [
//         {
//             name: helpSectionsPrefix + "title",
//             value: ""
//         },
//         {
//             name: helpSectionsPrefix + "description",
//             value: ""
//         },
//         {
//             name: helpSectionsPrefix + "thumbnail",
//             value: ""
//         },
//         {
//             name: helpSectionsPrefix + "submit",
//             value: ""
//         }
//     ],
//     appClass: new SectionsApp(),
//     dataDisplayerDefaultMessages: {
//        notFound: "No sections have been found",
//        sureToDelete: "Are you sure you want to delete this section?",
//        addNewText: "Add new section"
//     },
//     dataDisplayerFields: [
//         {
//             name: "thumbnail",
//             title: "Thumbnail",
//             type: "thumbnail",
//         },
//        {
//            name: "title",
//            title: "Title",
//            type: "text",
//            maxWidth: 300,
//            minWidth: 300
//        },
//         {
//             name: "date_created",
//             title: "Date",
//             type: "text",
//             maxWidth: 300,
//             minWidth: 300,
//             customContent: (data) => {
//                 return <h3>{data.date_created ? new DateAndTime().formatDateAndTime(data.date_created) : "-"}</h3>
//             }
//         },
//     ],
//     enableSearch: true,
//     customDataDisplayerButtons: [
//         {
//             type: "edit",
//             label: "Edit",
//             icon: ""
//         },
//        {
//            type: "delete",
//            label: "Delete",
//            icon: ""
//        }
//    ]
// }))

// /**
//  * Posts
//  */
// const postsPrefix = "posts_"
// registeredAdminPages.push(RegisterAdminPage({
//     type: "section",
//     name: "posts",
//     title: "Posts",
//     icon: "quiz",
//     usersAllowed: {
//         onlyAdmin: false,
//         onlySuperUser: true,
//         anyUser: false
//     },
//     displayInMenu: true,
//     optionalPages: {
//         addNewPage: true,
//         editPage: false,
//         viewPage: false
//     },
//     forms: {
//         defaultForm: {
//             name: postsPrefix + "default_form",
//             elements: [
//                 {
//                     type: "TextField",
//                     fieldType: "text",
//                     xs: 12,
//                     id: postsPrefix + "title",
//                     label: "Name",
//                     fullWidth: true
//                 },
//                 {
//                     type: "DynamicSelect",
//                     xs: 6,
//                     id: postsPrefix + "section_id",
//                     label: "Section",
//                     fullWidth: true,
//                     idFieldName: "id",
//                     titleFieldName: "title",
//                     appClass: new SectionsApp(),
//                     addNoneItem: false,
//                     formName: postsPrefix + "default_form"
//                 },
//                 {
//                     type: "DynamicSelect",
//                     xs: 6,
//                     id: postsPrefix + "category_id",
//                     label: "Category",
//                     fullWidth: true,
//                     idFieldName: "id",
//                     titleFieldName: "title",
//                     appClass: new CategoriesApp(),
//                     addNoneItem: true,
//                     formName: postsPrefix + "default_form"
//                 },
//                 {
//                     type: "BlockEditor",
//                     fieldType: "text",
//                     xs: 12,
//                     id: postsPrefix + "body",
//                     label: "Editor",
//                     fullWidth: true
//                 },
//                  {
//                      type: "ImageUploader",
//                      xs: 12,
//                      id: postsPrefix + "thumbnail",
//                      label: "Thumbnail",
//                      description: "Here you can upload the section image"
//                   },
//                 {
//                     type: "Button",
//                     xs: 12,
//                     id: postsPrefix + "submit",
//                     label: "Submit",
//                     fullWidth: false,
//                     onClick: () => {},
//                     isSubmit: true
//                 },
//             ]
//         }
//     },
//     formsData: [
//         {
//             name: postsPrefix + "title",
//             value: ""
//         },
//         {
//             name: postsPrefix + "section_id",
//             value: ""
//         },
//         {
//             name: postsPrefix + "category_id",
//             value: ""
//         },
//         {
//             name: postsPrefix + "body",
//             value: ""
//         },
//         {
//             name: postsPrefix + "thumbnail",
//             value: ""
//         },
//         {
//             name: postsPrefix + "submit",
//             value: ""
//         }
//     ],
//     appClass: new PostsApp(),
//     dataDisplayerDefaultMessages: {
//        notFound: "No posts have been found",
//        sureToDelete: "Are you sure you want to delete this post?",
//        addNewText: "Add new post"
//     },
//     dataDisplayerFields: [
//         {
//             name: "thumbnail",
//             title: "Thumbnail",
//             type: "thumbnail",
//         },
//        {
//            name: "title",
//            title: "Title",
//            type: "text",
//            maxWidth: 300,
//            minWidth: 300
//        },
//         {
//             name: "date_created",
//             title: "Date",
//             type: "text",
//             maxWidth: 300,
//             minWidth: 300,
//             customContent: (data) => {
//                 return <h3>{data.date_created ? new DateAndTime().formatDateAndTime(data.date_created) : "-"}</h3>
//             }
//         },
//     ],
//     enableSearch: true,
//     customDataDisplayerButtons: [
//         {
//             type: "edit",
//             label: "Edit",
//             icon: ""
//         },
//        {
//            type: "delete",
//            label: "Delete",
//            icon: ""
//        }
//    ]
// }))

// /**
//  * Categories
//  */
// const categoriesPrefix = "categories_"
// registeredAdminPages.push(RegisterAdminPage({
//     type: "section",
//     name: "categories",
//     title: "Categories",
//     icon: "quiz",
//     usersAllowed: {
//         onlyAdmin: false,
//         onlySuperUser: true,
//         anyUser: false
//     },
//     displayInMenu: true,
//     optionalPages: {
//         addNewPage: true,
//         editPage: false,
//         viewPage: false
//     },
//     forms: {
//         defaultForm: {
//             name: categoriesPrefix + "default_form",
//             elements: [
//                 {
//                     type: "TextField",
//                     fieldType: "text",
//                     xs: 6,
//                     id: categoriesPrefix + "title",
//                     label: "Name",
//                     fullWidth: true
//                 },
//                 {
//                     type: "DynamicSelect",
//                     xs: 6,
//                     id: categoriesPrefix + "section_id",
//                     label: "Section",
//                     fullWidth: true,
//                     idFieldName: "id",
//                     titleFieldName: "title",
//                     appClass: new SectionsApp(),
//                     addNoneItem: false,
//                     formName: categoriesPrefix + "default_form"
//                 },
//                  {
//                      type: "ImageUploader",
//                      xs: 12,
//                      id: categoriesPrefix + "thumbnail",
//                      label: "Thumbnail",
//                      description: "Here you can upload the section image"
//                   },
//                 {
//                     type: "Button",
//                     xs: 12,
//                     id: categoriesPrefix + "submit",
//                     label: "Submit",
//                     fullWidth: false,
//                     onClick: () => {},
//                     isSubmit: true
//                 },
//             ]
//         }
//     },
//     formsData: [
//         {
//             name: categoriesPrefix + "title",
//             value: ""
//         },
//         {
//             name: categoriesPrefix + "section_id",
//             value: ""
//         },
//         {
//             name: categoriesPrefix + "thumbnail",
//             value: ""
//         },
//         {
//             name: categoriesPrefix + "submit",
//             value: ""
//         }
//     ],
//     appClass: new CategoriesApp(),
//     dataDisplayerDefaultMessages: {
//        notFound: "No categories have been found",
//        sureToDelete: "Are you sure you want to delete this category?",
//        addNewText: "Add new category"
//     },
//     dataDisplayerFields: [
//         {
//             name: "thumbnail",
//             title: "Thumbnail",
//             type: "thumbnail",
//         },
//        {
//            name: "title",
//            title: "Title",
//            type: "text",
//            maxWidth: 300,
//            minWidth: 300
//        },
//         {
//             name: "date_created",
//             title: "Date",
//             type: "text",
//             maxWidth: 300,
//             minWidth: 300,
//             customContent: (data) => {
//                 return <h3>{data.date_created ? new DateAndTime().formatDateAndTime(data.date_created) : "-"}</h3>
//             }
//         },
//     ],
//     enableSearch: true,
//     customDataDisplayerButtons: [
//         {
//             type: "edit",
//             label: "Edit",
//             icon: ""
//         },
//        {
//            type: "delete",
//            label: "Delete",
//            icon: ""
//        }
//    ]
// }))


/**
 * Pdfs
 */
const pdfsPrefix = "pdfs_"
registeredAdminPages.push(RegisterAdminPage({
    type: "section",
    name: "pdfs",
    title: "PDFs",
    icon: "pdf",
    usersAllowed: {
        onlyAdmin: false,
        onlySuperUser: true,
        anyUser: false
    },
    displayInMenu: true,
    optionalPages: {
        addNewPage: false,
        editPage: false,
        viewPage: false
    },
    forms: {
        defaultForm: {
            name: pdfsPrefix + "default_form",
            elements: [
                {
                    type: "TextField",
                    fieldType: "text",
                    xs: 6,
                    id: pdfsPrefix + "title",
                    label: "Name",
                    fullWidth: true
                },
                {
                    type: "DynamicSelect",
                    xs: 6,
                    id: pdfsPrefix + "section_id",
                    label: "Section",
                    fullWidth: true,
                    idFieldName: "id",
                    titleFieldName: "title",
                    appClass: new SectionsApp(),
                    addNoneItem: false,
                    formName: pdfsPrefix + "default_form"
                },
                 {
                     type: "ImageUploader",
                     xs: 12,
                     id: pdfsPrefix + "thumbnail",
                     label: "Thumbnail",
                     description: "Here you can upload the section image"
                  },
                {
                    type: "Button",
                    xs: 12,
                    id: pdfsPrefix + "submit",
                    label: "Submit",
                    fullWidth: false,
                    onClick: () => {},
                    isSubmit: true
                },
            ]
        }
    },
    formsData: [
        // {
        //     name: categoriesPrefix + "title",
        //     value: ""
        // },
        // {
        //     name: categoriesPrefix + "section_id",
        //     value: ""
        // },
        // {
        //     name: categoriesPrefix + "thumbnail",
        //     value: ""
        // },
        // {
        //     name: categoriesPrefix + "submit",
        //     value: ""
        // }
    ],
    appClass: new UploadedPdfsApp(),
    dataDisplayerDefaultMessages: {
       notFound: "No PDFs have been found",
       sureToDelete: "Are you sure you want to delete this pdf file?",
       addNewText: "Add new pdf"
    },
    dataDisplayerFields: [
        {
            name: "thumbnail",
            title: "Thumbnail",
            type: "thumbnail",
        },
       {
           name: "original_name",
           title: "File Name",
           type: "text",
           maxWidth: 300,
           minWidth: 300
       },
       {
            name: "user_name",
            title: "Uploaded by",
            type: "text",
            maxWidth: 200,
            minWidth: 200
        },
        {
            name: "date_created",
            title: "Uploaded on",
            type: "text",
            maxWidth: 300,
            minWidth: 300,
            customContent: (data) => {
                return <h3>{data.date_created ? new DateAndTime().formatDateAndTime(data.date_created) : "-"}</h3>
            }
        },
    ],
    enableSearch: true,
    customDataDisplayerButtons: [
        {
            type: "custom",
            label: "View",
            icon: "view",
            // onClick: (itemId) => {
            //     const data = returnSingleDataDisplayerItem("pdfs", itemId)
            //     console.log("data", data)
            // }
            customContent: (details) => {
                return <IconButton onClick={() =>  window.open(details.link, '_blank')}><GenerateIcon icon="view" /></IconButton>
            }
        },
       {
           type: "delete",
           label: "Delete",
           icon: ""
       }
   ]
}))


/**
 * Register users
 */
const usersPrefix = "users_"
registeredAdminPages.push(RegisterAdminPage({
    type: "section",
    name: "users",
    title: "Users",
    icon: "users",
    usersAllowed: {
        onlyAdmin: false,
        onlySuperUser: true,
        anyUser: false
    },
    displayInMenu: true,
    optionalPages: {
        addNewPage: true,
        editPage: false,
        viewPage: false
    },
    forms: {
        defaultForm: {
            name: usersPrefix + "default_form",
            elements: [
                {
                    type: "TextField",
                    fieldType: "text",
                    xs: 6,
                    id: usersPrefix + "first_name",
                    label: "First Name",
                    fullWidth: true
                },
                {
                   type: "TextField",
                   fieldType: "text",
                   xs: 6,
                   id: usersPrefix + "last_name",
                   label: "Last Name",
                   fullWidth: true
               },
               {
                   type: "TextField",
                   fieldType: "email",
                   xs: 6,
                   id: usersPrefix + "email",
                   label: "Email",
                   fullWidth: true
               },
               {
                   type: "Select",
                   xs: 6,
                   id: usersPrefix + "type",
                   label: "Type",
                   fullWidth: true,
                   items: [
                       {
                           id: "admin",
                           title: "Admin"
                       },
                       {
                        id: "superuser",
                        title: "Superuser"
                        },
                       {
                           id: "staff",
                           title: "Staff"
                       },
                   ]
               },
               {
                   type: "ImageUploader",
                   xs: 12,
                   id: usersPrefix + "thumbnail",
                   label: "Thumbnail",
                   description: "Here you can upload the user picture"
                },
               {
                    type: "Button",
                    xs: 12,
                    id: usersPrefix + "submit",
                    label: "Submit",
                    fullWidth: false,
                    onClick: () => {},
                    isSubmit: true
                }
            ]
        }
    },
    formsData: [
        {
            name: usersPrefix + "first_name",
            value: ""
        },
        {
           name: usersPrefix + "last_name",
           value: ""
       },
       {
           name: usersPrefix + "email",
           value: ""
       },
       {
           name: usersPrefix + "type",
           value: ""
       },
       {
           name: usersPrefix + "thumbnail",
           value: ""
       },
       {
           name: usersPrefix + "submit",
           value: ""
       },
    ],
    appClass: new UsersApp(),
    dataDisplayerDefaultMessages: {
       notFound: "No users have been found",
       sureToDelete: "Are you sure you want to delete this user?",
       addNewText: "Add new user"
    },
    dataDisplayerFields: [
       {
           name: "thumbnail",
           title: "Thumbnail",
           type: "thumbnail"
       },
       {
           name: "first_name",
           title: "Name",
           type: "text",
           minWidth: 200,
           maxWidth: 200,
           customContent: (details) => {
               return details.first_name && details.last_name ? <h3>{details.first_name} {details.last_name}</h3>: ""
           }
       },
       {
           name: "last_name",
           title: "Type",
           type: "text",
           minWidth: 200,
           maxWidth: 200,
           customContent: (details) => {
               return details.type ? <h3>{new User().returnUserTypeTitle(details.type)}</h3> : <h3>Guest</h3>
           }
       },
       {
           name: "email",
           title: "Email",
           type: "text"
       },
    ],
    enableSearch: true,
    customDataDisplayerButtons: [
       {
           type: "delete",
           label: "Delete",
           icon: ""
       }
   ]
}))

/**
 * Export admin pages
 */
export const adminPages = registeredAdminPages